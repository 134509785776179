import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import logo from "../assets/XR_R3.png";
import { FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import "./register.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { redirect } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { BeatLoader, PuffLoader } from "react-spinners";
function RegisterBrand() {
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = React.useState(false);
  const CLIENT_ID =
    "369046900295-j3ntqemlshdnmpqaa8mveo8qimd8vh6f.apps.googleusercontent.com";

  const onSuccess = (res) => {
    console.log("Register successfulyyy", res);
  };
  const onFailure = (res) => {
    console.log("Register failed", res);
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Please Enter Your Valid Email"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please Enter your confirm password"),
  });
  const [signup, setSignup] = useState(false);

  return (
    <div className="main-signup">
      <img
        src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/wrbl.png"
        alt=""
        className="logo"
      />
      <Row className="-hv cont-row">
        <Col className="col-md-12 cont -hv">
          <div className="box">
            <div className="box-header">
              <h1 className="box-header-text mt-2 mb-4">Sign Up for wrbl</h1>
              <hr></hr>
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
                confirmpassword: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={async (values, actions) => {
                setLoading(true);
                await axios
                  .post("https://api.xrcouture.com/auth/signup", {
                    ...values,
                    role: "brands",
                  })
                  .then((res) => {
                    console.log(res);
                    if (res.status == 201) {
                      toast.success(res.data.msg, {
                        position: toast.POSITION.TOP_CENTER,
                        toastId: "passerror",
                      });
                    } else {
                      toast.error(res.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: "passerror",
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error(err.response.data.msg, {
                      position: toast.POSITION.TOP_RIGHT,
                      toastId: "passerror",
                    });
                  });
                setLoading(false);
                actions.resetForm();
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  {/* <Label for="email">Email</Label> */}
                  <Field type="email" name="email" placeholder="Enter Email" />
                  <ErrorMessage
                    name="email"
                    className="error"
                    component="div"
                  />
                  {/* <Label for="password">Password</Label> */}
                  <Field
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                  />
                  <ErrorMessage
                    name="password"
                    className="error"
                    component="div"
                  />
                  {/* <Label for="confirmpassword">Confirm Password</Label> */}
                  <Field
                    type="password"
                    name="confirmpassword"
                    placeholder="Enter Confirm Password"
                  />
                  <ErrorMessage
                    name="confirmpassword"
                    className="error"
                    component="div"
                  />
                  {/* {errors.password && errorPass} */}
                  <div className="button-submit mb-2">
                    <button type="submit" className="button" disabled={loading}>
                      {!loading ? (
                        "Sign Up"
                      ) : (
                        <BeatLoader size={10} color="white" />
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <GoogleLogin
                // type="icon"
                size="large"
                theme="filled_blue"
                text="signup_with"
                logo_alignment="left"
                width="100%"
                onSuccess={async (credentialResponse) => {
                  setLoading(true);
                  const { credential } = credentialResponse;
                  await axios
                    .post(
                      "https://api.xrcouture.com/auth/googleauth",
                      { token: credential },
                      { withCredentials: true }
                    )
                    .then((res) => {
                      if (res.data.msg === "Email already exists") {
                        setMsg(
                          "Email id for this user is already exists, please login using gmail"
                        );
                      }
                      setLoading(false);
                      console.log(res);
                      localStorage.setItem("email", res.data.mail);
                      localStorage.setItem("role", res.data.role);
                      localStorage.setItem("brand", res.data.brand);
                      localStorage.setItem("logintype", "google");
                      setSignup(res.data.signUpCompleted);
                      console.log(res.data.brand);
                      if (res.data.role === "admin") {
                        setTimeout(function () {
                          window.location.replace("/admin/brands");
                        }, 1000);
                      }

                      if (res.data.signUpCompleted === true) {
                        if (res.data.role === "admin") {
                          setTimeout(function () {
                            window.location.replace("/admin/brands");
                          }, 500);
                        } else {
                          setTimeout(function () {
                            window.location.replace(
                              `/brands/${res.data.brand}`
                            );
                          }, 500);
                        }
                      } else {
                        setTimeout(function () {
                          window.location.replace("/brand-data");
                        }, 500);
                      }
                    })
                    .catch((e) =>
                      setMsg(
                        "Oops!, something went wrong, please try again later"
                      )
                    );
                  setTimeout(() => {
                    setMsg("");
                  }, 3000);
                  setLoading(false);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>

            <ToastContainer />
            <p className="signup">
              I already have an account? <Link to="/signin"> Sign In</Link> here{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default RegisterBrand;
