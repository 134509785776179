import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import DataTable from "../../src/DataTable/DataTable";
import axios from "axios";
import { Context } from "../../Context";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { SlOptionsVertical } from "react-icons/sl";

const SupportTickets = () => {
  const { brandx } = useParams();
  const [data, setData] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <Spinner />
    </div>
  );
  const { update, setUpdate } = useContext(Context);

  const columns = [
    {
      name: "#",
      selector: "id",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.3,
      cell: (row) => (
        <>
          <p className="text-white">{row.ticketId.split("ID")[1]}</p>
        </>
      ),
    },
    {
      name: "Subject",
      selector: "assetName",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.8,
      cell: (row) => (
        <>
          <Link to={`/brands/${brandx}/support/${row.ticketId}`}>
            {row.title}
          </Link>
        </>
      ),
    },
    {
      name: "Last updated at",
      selector: "approximateTime",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          <p className="text-white">
            {new Date(row.updatedAt).toLocaleString()}
          </p>
        </>
      ),
    },
    {
      name: "Linked asset",
      selector: "approximateTime",
      sortable: false,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          <p className="text-white">{row.assetName}</p>
        </>
      ),
    },
    {
      name: "Status",
      selector: "approximateTime",
      sortable: false,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.3,
      cell: (row) => (
        <>
          <p className="text-white">
            {row.status === "close" ? "Closed" : "Open"}
          </p>
        </>
      ),
    },
    // {
    //   name: '',
    //   // selector: "",
    //   grow: .1,
    //   style: {
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center"
    //   },
    //   cell: (row) => (
    //     <UncontrolledDropdown direction="end">
    //       <DropdownToggle
    //         color="transparent"
    //         className="action-toggle"
    //         direction="end"
    //       >
    //         <SlOptionsVertical className='action-container-icon ' />
    //       </DropdownToggle>
    //       <DropdownMenu dark>

    //         {
    //           row.status === 'Pending payment' &&
    //           <>
    //             <DropdownItem onClick={() => (alert(`Are you ready to pay: ${row.budget}`))}>
    //                Pay Now
    //             </DropdownItem>
    //             <DropdownItem divider />
    //           </>
    //         }

    //         {row.status === "Action Required" &&
    //           <>
    //             <DropdownItem>
    //               Edit
    //             </DropdownItem>
    //             <DropdownItem divider />
    //           </>
    //         }

    //         {row.status === "Action Required" &&
    //           <>
    //             <DropdownItem >
    //               Edit
    //             </DropdownItem>
    //             <DropdownItem divider />
    //           </>
    //         }

    //         {row.status === "Completed" &&
    //           <>
    //             <DropdownItem >
    //               Download
    //             </DropdownItem>
    //             <DropdownItem divider />
    //           </>
    //         }

    //         {
    //           (row.status === "Under Review" || row.status === "Draft" || row.status === "Pending payment" || row.status === "Action Required") &&
    //           <>
    //             <DropdownItem>
    //               Delete
    //             </DropdownItem>
    //           </>
    //         }

    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   )
    // },
  ];

  const tableCustomStyles = {
    headCells: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
    },
    headRow: {
      style: {
        minHeight: "52px",
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
        opacity: 0.7,
        paddingRight: 0,
        padding: "2rem",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    pagination: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        color: "white",
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Clash Display Medium",
        justifyContent: "center",
        color: "white",
        backgroundColor: "#1E1D46",
        minHeight: "100px",
      },
    },
    rows: {
      style: {
        padding: "0rem 2rem",
        fontSize: "13px",
        fontWeight: 400,
        color: "white",
        minHeight: "80px !important",
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        borderBottomWidth: ".5px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "gray",
        },
      },
      progress: {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#1E1D46",
        },
      },
    },
  };

  useEffect(() => {
    axios
      .post("https://api.xrcouture.com/support/get", {
        brandName: brandx,
      })
      .then((res) => {
        console.log(res);
        setData(res.data.tickets);
      });
  }, [update]);

  useEffect(() => {
    const filteredItems = data.filter(
      (item) => item.assetName && item.assetName.toLowerCase().includes("")
    );
    setAssetList(filteredItems);
  }, [data]);

  return (
    <>
    <div className="m-4" style={{marginBottom:"5em !important"}}>
      <div class="container-line" style={{height:"1px"}}>
    <div style={{background:"linear-gradient(0deg,rgba(71, 118, 240, 0.06) 0%,#0057ff 50%,rgba(74, 71, 240, 0.06) 99.48%)"}}></div>
    </div>
    <div className="position-relative" style={{borderRadius:"5px"}}>
    <div style={{background:"linear-gradient(152.97deg, #1E1D47 18.75%, rgba(0, 0, 0, 0.58) 100%)",borderRadius:"5px"}} className="table-container">
      <div className="tables" style={{ position: "relative",marginLeft:"15px" }}>
        <DataTable
          columns={columns}
          className="data-table"
          data={assetList}
          responsive={true}
          highlightOnHover={true}
          customStyles={tableCustomStyles}
          progressComponent={<CustomLoader />}
          // conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default SupportTickets;
