import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import logo from "../assets/XR_R3.png";
import { FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import "./register.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router";

function Updatepassword() {
  const navigate = useNavigate();
  const [isLoading, isSetLoading] = useState(false);
  const SignupSchema = Yup.object().shape({
    password: Yup.string().required("Please Enter your password"),
    confirmpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const urlToken = params.get("token");
  const urlEmail = params.get("email");

  //   const errorPass = toast("Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case")

  return (
    <div className="main-signup">
      <img
        src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/wrbl.png"
        alt=""
        className="logo"
      />
      <Row className="-hv cont-row">
        <Col className="col-md-12 cont -hv">
          <div className="box">
            <div className="box-header">
              <h1 className="box-header-text">Update Password</h1>
              <hr></hr>
            </div>
            <Formik
              initialValues={{
                password: "",
                confirmpassword: "",
                token: urlToken,
                email: urlEmail,
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                console.log(values);
                isSetLoading(true);
                if (values.password !== values.confirmpassword) {
                  toast.error("Password and confirm password must match", {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "passerror",
                  });
                  return false;
                }
                if (
                  values.password.match(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
                  ) === null
                ) {
                  toast.error(
                    "Password Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case",
                    {
                      position: toast.POSITION.TOP_RIGHT,
                      toastId: "passerror",
                    }
                  );
                }

                // values.token = token

                console.log(values);

                axios
                  .post(
                    "https://api.xrcouture.com/auth/reset-password",
                    values,
                    {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                      },
                    }
                  )
                  .then((response) => {
                    console.log(response.data);
                    toast.success(
                      "Password Updated Successfully! You will be redirected to the login page in 5 seconds.",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: "passerror",
                      }
                    );

                    setTimeout(() => {
                      navigate("/signin");
                    }, 5000);
                    isSetLoading(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error("Something went wrong! Please try again.", {
                      position: toast.POSITION.TOP_RIGHT,
                      toastId: "passerror",
                    });
                    isSetLoading(false);
                  });
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <Label for="password">Password</Label>
                  <Field type="password" name="password" />
                  <ErrorMessage
                    name="password"
                    className="error"
                    component="div"
                  />
                  <Label for="confirmpassword">Confirm Password</Label>
                  <Field type="password" name="confirmpassword" />
                  <ErrorMessage
                    name="password"
                    className="error"
                    component="div"
                  />
                  {/* {errors.password && errorPass} */}
                  <div className="button-submit">
                    <button
                      type="submit"
                      className="button"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <ToastContainer />
            {/* <p className="signup">I already have an account? <a href='/signin' > Sign In</a> here </p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Updatepassword;
