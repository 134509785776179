import React from "react";
const Left = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    aria-hidden="true"
    role="presentation"
    fill="white !important"
  >
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="white" />
    <path d="M0 0h24v24H0z" fill="transparent" />
  </svg>
);
export default Left;
