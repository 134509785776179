import React, { useState, useEffect, useContext } from "react";
import "./Recent.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { IoCheckmarkDone } from "react-icons/io5";
import { CiSquarePlus } from "react-icons/ci";
import MyComponent from "../../../Asset_components/basic";
import axios from "axios";
import "./swiper.min.css";
import { useNavigate, useParams } from "react-router";
import differenceBy from "lodash/differenceBy";
import Swal from "sweetalert2";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Context1 } from "../../../Context";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Loading from "../../../Loading";
import { Button } from "react-bootstrap";
import { BeatLoader, PuffLoader } from "react-spinners";

// import Payment from '../../components/Payment/Payment'
import Payment from "../../../components/Payment/Payment";
import PaypalCheckoutButton from "../../../pages/PaypalCheckoutButton";
import { MdCancel, MdDeleteOutline } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";

function Recent(props) {
  const navigate = useNavigate();
  // const [pending, setPending] = useState(false);
  const {pending,setPending} = useContext(Context1)
  const [data, setData] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [arr1, setArr1] = useState([]);

  const { updateAssets, setUpdateAssets, payForAssets, setPayForAssets } =
    useContext(Context1);
  const { brandx } = useParams();

  const [slidesPerViewState, setSlidesPerViewState] = useState(3);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setSlidesPerViewState(assetList.length > 0 ? 3 : 4);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [assetList]);

  const fetchAssetData = async () => {
    await axios
      .post("https://api.xrcouture.com/brands/assets", {
        brand: brandx,
      })
      .then((res) => {
        setArr1(res.data.assets);
      });
  };
  useEffect(() => {
    var arr = arr1.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
    setAssetList(arr);
  }, [arr1]);
  const downloadFilesAsZip = async (filesArray) => {
    const s3Links = Object.values(filesArray);

    const zip = new JSZip();
    const files = await Promise.all(
      s3Links.map((link) =>
        fetch(link).then((response) => response.arrayBuffer())
      )
    );
    files.forEach((file, index) => {
      const fileName = s3Links[index].split("/").pop();
      zip.file(fileName, file);
    });
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "Digital Wearables.zip");
  };

  const handleDelete = (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `You want to delete this `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .delete("https://api.xrcouture.com/brands/delete", {
              data: {
                brand: brandx,
                name: [row.assetName],
              },
            })
            .then((res) => {
              window.location.reload();
              setPending(true);
              fetchAssetData();
              // setAssetList()
              const timeout = setTimeout(() => {
                setPending(false);
              }, 1000);
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
              return () => clearTimeout(timeout);
            })
            .catch(() => alert("Something Went Wrong"));

          setData(differenceBy(data, [row], "id"));
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          this.successalt("error", "Cancelled");
        }
      });
  };
  const handleEditRecents = (a) => {
    if (a.status == "Action Required") {
      navigate(`/brands/${a.brandName}/${a.assetName}/update`);
    } else {
      navigate(`/brands/${a.brandName}/${a.assetName}/drafts`);
      // /brands/:brandx/:assetx/drafts
    }
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [amount, setAmount] = useState(0);
  const [rowsAllowed, setRowsAllowed] = useState([]);
  const pay = (a) => {
    setModalShow(true);
    var s = [a];
    setPayForAssets(s);
    var payable = [];
    var payableAmount = 0;
    var payableRows = [];
    s.forEach((item) => {
      if (item.status === "Pending payment") {
        payable.push(item.assetName);
        payableAmount += item.actualPrice;
        payableRows.push(item);
      }
    });
    console.log(payableRows, payableAmount);
    setAmount(payableAmount);
    setRowsAllowed(payableRows);
  };

  // const [paymentModal, setPaymentModal] = useState(false)

  const deleteItemFromCart = (name) => {
    console.log(name);
    setPayForAssets((prev) => prev.filter((item) => item.assetName !== name));
  };

  const [appliedDiscount, setAppliedDiscount] = useState({
    discount: null,
    code: null,
  });

  function calculateTotalCost() {
    let totalPrice = 0;
    for (let i = 0; i < payForAssets.length; i++) {
      totalPrice += payForAssets[i].actualPrice;
    }
    return promoCodeApplied !== null && promoCodeApplied === true
      ? totalPrice - appliedDiscount.discount
      : totalPrice;
  }

  function calculateTotalCostWithoutDiscount() {
    let totalPrice = 0;
    for (let i = 0; i < payForAssets.length; i++) {
      totalPrice += payForAssets[i].actualPrice;
    }
    return totalPrice;
  }

  const [asset, setAsset] = useState({
    "Under Review": 0,
    "Pending payment": 0,
    "In Progress": 0,
    Completed: 0,
    Draft: 0,
    totalCount: 0,
  });
  const [msg, setMsg] = useState("")
  const fetchAsset = async () => {
    await axios
      .get("http://api.xrcouture.com/admin/getAssets")
      .then((res) => {
        setData(res.data.assets);
        res.data.assets.filter((i) => {
          if (i.brand === brandx) {
            setAsset({
              ...asset,
              "Under Review": i["Under Review"],
              "Pending payment": i["Pending payment"],
              totalCount: i["totalCount"],
              "In Progress": i["In Progress"],
              Completed: i["Completed"],
              Draft: i["Draft"],
            });
          }
        });
      })
      .catch((e) => console.log(e));
      if(asset['totalCount']<2){
        if(asset['Completed']>0){
          setMsg("You first asset is ready to download. Please download your 3D wearables.")
        }else if(asset['In Progress']>0){
          setMsg("You first asset is in progress. Please wait for the 3D wearables to be ready.")
        }else if(asset['Pending payment']>0){
          setMsg("The payment for your first asset is pending. Please complete the payment to initiate the preparing process.")
        }else if(asset['Under Review']>0){
          setMsg("Your first asset is under review. Please wait for the review to be completed.")
        }else if(asset['Draft']>0){
          setMsg("You first asset is in draft. Please complete the process and publish to get the 3D wearables.")
        }
        console.warn(msg)
      }
      else{
        setMsg("You have more than one asset")
        console.warn(msg)
      }
  };
console.warn(msg)
console.log(msg)
  const sortData = () => {
    var arr = assetList.sort((a, b) => (a.updatedAt > b.updatedAt ? 1 : -1));
    console.log(arr);
  };
  useEffect(() => {
    setPending(true);
    fetchAsset();
    fetchAssetData();
    sortData();

    console.log(assetList);
    setPending(false);
    console.log(asset);
  }, [updateAssets]);

  const closePaymentModal = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you really want to exit the payment process?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(78, 75, 222)",
      cancelButtonColor: "rgb(40 39 106)",
      confirmButtonText: "Proceed with payment",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      allowOutsideClick: false,
      customClass: {
        title: "text-white",
        container: "my-swal-container",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else {
        Swal.close();
        setModalShow(false);
      }
    });
  };

  const [promoInput, setPromoInput] = useState(false);

  const [paymentModal, setPaymentModal] = useState(false);

  const [promoCodeText, setPromoCodeText] = useState("");

  const [validatingPromoCode, setValidatingPromoCode] = useState(false);

  const [promoCodeApplied, setPromoCodeApplied] = useState(null);

  const [promoCodeError, setPromoCodeError] = useState("");

  useEffect(() => {
    calculateTotalCost();
  }, [appliedDiscount]);

  const applyPromoCode = async (e) => {
    e.preventDefault();

    setPromoCodeApplied(null);
    setPromoCodeError("");

    if (promoCodeApplied === true) {
      setPromoCodeText("");
      setPromoCodeApplied(null);
      setAppliedDiscount({
        discount: null,
        code: null,
      });
      return;
    }

    setValidatingPromoCode(true);

    await axios
      .post(
        "https://api.xrcouture.com/promocode/validatepromocode",
        {
          code: promoCodeText,
          email: localStorage.getItem("email"),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setPromoCodeApplied(true);
        setAppliedDiscount({
          discount: response.data.updatedPromoCode.discount,
          code: response.data.updatedPromoCode.code,
        });
        setValidatingPromoCode(false);
      })
      .catch((err) => {
        console.log(err);
        setPromoCodeApplied(false);
        setPromoCodeError(
          err.response.data.msg === "Invalid user"
            ? "Invalid promo code"
            : err.response.data.msg
        );
        setValidatingPromoCode(false);
      });
  };

  return (
    <div >
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: "15rem" }}
        >
          <PuffLoader color={"white"} size={80} />
        </div>
      ) : (
        <div style={{ position: "relative" }} className="recents-container">
          {/* <h1 className='text-white mt-4 ml-4'>Recents</h1> */}
          {pending ? (
            <Loading />
          ) : (
            <div
              className="row align-items-center"
              style={{ position: "relative" }}
            ></div>
          )}

          <div
            className="row align-items-center"
            style={{ position: "relative" }}
          >
            <div
              className={`${
                assetList.length > 0 ? "col-lg-12" : "col-lg-12"
              } p-4 swiper-container-all`}
            >
              {console.warn(assetList.length)}
              <Swiper
                data-tour='recent-assets'
                modules={[Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={30}
                navigation={true}
                className="mySwiper"
                scrollbar={{ draggable: true }}
                freeMode={true}
                breakpoints={{
                  350: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  550: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  850: {
                    slidesPerView: slidesPerViewState,
                    spaceBetween: 30,
                  },
                  1200:{
                    slidesPerView: 4,
                    spaceBetween: 40,
                  }
                }}
              >
                {assetList.map((a) => (
                  <SwiperSlide className="d-flex align-items-center justify-content-center">
                    <div
                      className="card-recents position-relative"
                      style={{
                        borderColor: `${
                          a.status === "Under Review"
                            ? "#568B7F"
                            : a.status === "Draft"
                            ? "gray"
                            : a.status === "Action Required"
                            ? "#8B565E"
                            : a.status === "Pending payment"
                            ? "#8B565E"
                            : a.status === "In Progress"
                            ? "blue"
                            : "#538260"
                        }`,
                      }}
                    >
                      <div className="image-container">
                        <div
                          className={`action-container`}
                          style={{
                            borderColor: `${
                              a.status === "Under Review"
                                ? "#568B7F"
                                : a.status === "Draft"
                                ? "#CACACD"
                                : a.status === "Action Required"
                                ? "#8B565E"
                                : a.status === "Pending payment"
                                ? "#A68567"
                                : a.status === "In Progress"
                                ? "#5B568B"
                                : "#538260"
                            }`,
                            background: `${
                              a.status === "Under Review"
                                ? "rgba(0, 138, 122, 0.6)"
                                : a.status === "Draft"
                                ? "rgb(177 173 173 / 46%)"
                                : a.status === "Action Required"
                                ? "#662626"
                                : a.status === "Pending payment"
                                ? "#665026"
                                : a.status === "In Progress"
                                ? "#272666"
                                : "#276626"
                            }`,
                          }}
                        >
                          <div
                            className="action-icon-container "
                            style={{ color: "white" }}
                          >
                            {a.status === "Under Review" ? (
                              <img
                                src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/review.png"
                                alt="draft"
                                style={{ width: "15px" }}
                              />
                            ) : //  <GrUploadOption className='under_review'/>
                            a.status === "Draft" ? (
                              <img
                                src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/draft.png"
                                alt="draft"
                                style={{ width: "15px" }}
                              />
                            ) : // <RiDraftLine className='draft' />
                            a.status === "Pending payment" ? (
                              // <MdOutlinePendingActions />
                              <img
                                src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/action.png"
                                alt="draft"
                                style={{ width: "15px" }}
                              />
                            ) : a.status === "In Progress" ? (
                              // <GrInProgress size={13} fill="blue" className='in_progress' />
                              <img
                                src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/inProgress.png"
                                alt="draft"
                                style={{ width: "15px" }}
                              />
                            ) : a.status === "Action Required" ? (
                              // <MdPendingActions />
                              <img
                                src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/action.png"
                                alt="draft"
                                style={{ width: "15px" }}
                              />
                            ) : (
                              //  <IoCheckmarkDone />
                              <img
                                src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/completed.png"
                                alt="draft"
                                style={{ width: "15px" }}
                              />
                            )}
                          </div>
                          <span
                            style={{
                              fontFamily: "Clash Display Medium",
                              color: `white`,
                              marginRight: "5px",
                            }}
                          >
                            {a.status}
                          </span>
                        </div>
                        <div className="image-card">
                          {a.thumbnail && a.thumbnail != "" ? (
                            <img
                              src={a.thumbnail}
                              style={{ borderRadius: "12px" }}
                              className="card-image"
                            />
                          ) : (
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "black",
                                alignSelf: "center",
                                justifySelf: "center",
                                fontSize: "140px",
                                borderRadius: "12px",
                                fontWeight: "bolder",
                                color: "gray",
                                textAlign: "center",
                                fontFamily: "Clash Display Medium",
                              }}
                            >
                              {a.assetName
                                .split(" ")
                                .slice(0, 1)
                                .map((n) => n[0])
                                .join("")
                                .toUpperCase()}
                            </div>
                          )}
                          {/* <img src={a.thumbnail} alt='image'  /> */}
                        </div>
                      </div>
                      <h6
                        className="text-white card-title"
                        style={{
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        {a.assetName.charAt(0).toUpperCase() +
                          a.assetName.slice(1)}
                      </h6>
                      <div className="progress-container">
                        <div className="asset-progress">
                          {/* <div className='progress-bar1' style={{width:`${a.status === 'Under Review' ? '17%' : a.status === 'Draft' ? "7%" : a.status === "Action Required" ? `${a.progress+7}%` : a.status === "Pending payment" ? '32%' : `${a.progress+7}%`}`}}> */}
                          <div
                            className="progress-bar1"
                            style={{ width: `${a.progress + 7}%` }}
                          >
                            <div className="progress-bar-ball"></div>
                          </div>
                        </div>
                        <span
                          className="text-white"
                          style={{
                            fontSize: "10px",
                            fontWeight: "bolder",
                            marginBottom: "5%",
                            fontFamily: "Clash Display Bold",
                          }}
                        >
                          {a.progress}%
                        </span>
                      </div>
                      <div
                        className="button-card"
                        style={{
                          fontFamily: "Clash Display Medium",
                          fontSize: "12px",
                        }}
                      >
                        {["Action Required", "Draft"].includes(a.status) ? (
                          <button
                            className="button-card-cancel"
                            style={{ backgroundColor: "#4E4BDE" }}
                            onClick={() => handleEditRecents(a)}
                          >
                            Update
                          </button>
                        ) : ["Under Review"].includes(a.status) ? (
                          <button
                            className="button-card-cancel btn btn-secondary"
                            disabled
                            style={{
                              fontFamily: "Clash Display Medium",
                              fontSize: "10px",
                            }}
                          >
                            Pay
                          </button>
                        ) : ["Completed"].includes(a.status) ? (
                          <button
                            className="button-card-cancel"
                            style={{ backgroundColor: "#4E4BDE" }}
                            onClick={() =>
                              downloadFilesAsZip(a.digitalWearables)
                            }
                          >
                            Download
                          </button>
                        ) : ["Pending payment"].includes(a.status) ? (
                          <button
                            className="button-card-cancel"
                            style={{ backgroundColor: "#4E4BDE" }}
                            onClick={() => pay(a)}
                          >
                            Pay
                          </button>
                        ) : (
                          <button
                            className="button-card-cancel btn btn-secondary"
                            disabled
                            style={{
                              fontFamily: "Clash Display Medium",
                              fontSize: "12px",
                            }}
                          >
                            Download
                          </button>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                {assetList.length == 0 ? (
                  [1, 2, 3, 6, 5].map((j) => (
                    <SwiperSlide className="d-flex align-items-center justify-content-center">
                      <div className="card-recents tam">
                        <div
                          className="image-container"
                          style={{ position: "relative", height: "100%" }}
                        >
                          <div
                            className="image-card"
                            onClick={() =>
                              navigate(
                                `/brands/${localStorage.getItem(
                                  "brand"
                                )}/create`
                              )
                            }
                          >
                            <CiSquarePlus size={150} color="#f3f6fa59" />
                            <span
                              style={{
                                color: "white",
                                opacity: 0.7,
                                fontFamily: "Clash Display Bold",
                              }}
                            >
                              Create Asset
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                ) : assetList.length == 1 ? (
                  [1, 2, 3,4].map((j) => (
                    <SwiperSlide className="d-flex align-items-center justify-content-center">
                      <div className="card-recents tam">
                        <div
                          className="image-container"
                          style={{ position: "relative", height: "100%" }}
                        >
                          <div
                            className="image-card"
                            onClick={() =>
                              navigate(
                                `/brands/${localStorage.getItem(
                                  "brand"
                                )}/create`
                              )
                            }
                          >
                            <CiSquarePlus size={150} color="#f3f6fa59" />
                            <span
                              style={{
                                color: "white",
                                opacity: 0.7,
                                fontFamily: "Clash Display Bold",
                              }}
                            >
                              Create Asset
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                ) : assetList.length == 2 ? (
                  [1, 2,3].map((j) => (
                    <SwiperSlide className="d-flex align-items-center justify-content-center">
                      <div className="card-recents tam">
                        <div
                          className="image-container"
                          style={{ position: "relative", height: "100%" }}
                        >
                          <div
                            className="image-card"
                            onClick={() =>
                              navigate(
                                `/brands/${localStorage.getItem(
                                  "brand"
                                )}/create`
                              )
                            }
                          >
                            <CiSquarePlus size={150} color="#f3f6fa59" />
                            <span
                              style={{
                                color: "white",
                                opacity: 0.7,
                                fontFamily: "Clash Display Bold",
                              }}
                            >
                              Create Asset
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  [1, 2].map((j) => (
                    <SwiperSlide className="d-flex align-items-center justify-content-center">
                      <div className="card-recents tam">
                        <div
                          className="image-container"
                          style={{ position: "relative", height: "100%" }}
                        >
                          <div
                            className="image-card"
                            onClick={() =>
                              navigate(
                                `/brands/${localStorage.getItem(
                                  "brand"
                                )}/create`
                              )
                            }
                          >
                            <CiSquarePlus size={150} color="#f3f6fa59" />
                            <span
                              style={{
                                color: "white",
                                opacity: 0.7,
                                fontFamily: "Clash Display Bold",
                              }}
                            >
                              Create Asset
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                )}
              </Swiper>
            </div>
            {/* {assetList.length > 0 &&
              (pending ? (
                "Loading"
              ) : (
                <div className="col-lg-3 p-0 d-flex justify-content-center align-items-center position-relative">
                  <div className="overview-container">
                    <h3
                      className="text-white"
                      style={{
                        opacity: 0.7,
                        fontFamily: "Clash Display Bold",
                        marginBottom: "0px",
                      }}
                    >
                      Overview
                    </h3>
                    <div className="under-review av pt-4">
                      <div className="under-review-icon ">

                        <img
                          src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/draft.png"
                          alt="draft"
                          style={{ width: "13px" }}
                        />
                      </div>
                      <p
                        className="under-review-text text-white"
                        style={{
                          opacity: 0.5,
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        Draft
                      </p>
                      <span
                        className="under-review-percentage"
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                          opacity: 0.7,
                        }}
                      >
                        {asset["Draft"] ? asset["Draft"] : 0}
                      </span>
                    </div>
                    <div className="under-review  av pt-4">
                      <div className="under-review-icon ">

                        <img
                          src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/review.png"
                          alt="draft"
                          style={{ width: "13px" }}
                        />
                      </div>
                      <p
                        className="under-review-text text-white"
                        style={{
                          opacity: 0.5,
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        Under Review
                      </p>
                      <span
                        className="under-review-percentage"
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                          opacity: 0.7,
                        }}
                      >
                        {asset["Under Review"] ? asset["Under Review"] : 0}
                      </span>
                    </div>
                    <div className="under-review pt-4">
                      <div className="under-review-icon action-needed-icon">

                        <img
                          src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/action.png"
                          alt="draft"
                          style={{ width: "13px" }}
                        />
                      </div>
                      <p
                        className="under-review-text  text-white"
                        style={{
                          opacity: 0.5,
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        Action Required
                      </p>
                      <span
                        className="under-review-percentage"
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                          opacity: 0.7,
                        }}
                      >
                        {asset["Action Needed"] ? asset["Action Needed"] : 0}
                      </span>
                    </div>
                    <div className="under-review pt-4">
                      <div className="under-review-icon pending-icon">

                        <img
                          src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/action.png"
                          alt="draft"
                          style={{ width: "13px" }}
                        />
                      </div>
                      <p
                        className="under-review-text  text-white"
                        style={{
                          opacity: 0.5,
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        Payment Pending
                      </p>
                      <span
                        className="under-review-percentage"
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                          opacity: 0.7,
                        }}
                      >
                        {asset["Pending payment"]
                          ? asset["Pending payment"]
                          : 0}
                      </span>
                    </div>
                    <div className="under-review pt-4">
                      <div className="under-review-icon pending-icon">

                        <img
                          src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/action.png"
                          alt="draft"
                          style={{ width: "13px" }}
                        />
                      </div>
                      <p
                        className="under-review-text  text-white"
                        style={{
                          opacity: 0.5,
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        In Progress
                      </p>
                      <span
                        className="under-review-percentage"
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                          opacity: 0.7,
                        }}
                      >
                        {asset["In Progress"] ? asset["In Progress"] : 0}
                      </span>
                    </div>
                    <div className="under-review pt-4">
                      <div className="under-review-icon completed-icon">

                        <img
                          src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/completed.png"
                          alt="draft"
                          style={{ width: "13px" }}
                        />
                      </div>
                      <p
                        className="under-review-text text-white"
                        style={{
                          opacity: 0.5,
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        Completed
                      </p>
                      <span
                        className="under-review-percentage"
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                          opacity: 0.7,
                        }}
                      >
                        {asset["Completed"] ? asset["Completed"] : 0}
                      </span>
                    </div>
                    <div className="under-review pt-4">
                      <div className="under-review-icon completed-icon">
                        <IoCheckmarkDone size={10} />
                      </div>
                      <p
                        className="under-review-text  text-white"
                        style={{
                          opacity: 0.5,
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                        }}
                      >
                        Total Assets
                      </p>
                      <span
                        className="under-review-percentage"
                        style={{
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          fontSize: "16px",
                          opacity: 0.7,
                        }}
                      >
                        {asset["totalCount"] ? asset["totalCount"] : 0}
                      </span>
                    </div>
                    <div className="div-empty"></div>
                  </div>
                </div>
              ))} */}
          </div>
          <MyComponent filterText={props.filterText} />
          {/* <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow}
              onHide={() => setModalShow(false)}
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontFamily: "Clash Display Bold" }}>
                  Review Selections
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped hover style={{ color: 'white', fontFamily: "Clash Display Medium" }}>
                  <thead>
                    <tr>
                      <th>Thumbnail</th>
                      <th>Asset Name</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody >
                    {rowsAllowed.map((row, i) => (
                      <tr style={{ color: 'white', fontFamily: "Clash Display Medium" }}>
                        <td >
                          {row.thumbnail && (row.thumbnail != "") ? <img src={row.thumbnail} style={{ width: "50px", height: "50px" }} /> :
                            <div style={{ height: "50px", width: "50px", backgroundColor: "black", alignContent: "center", justifyContent: "center", fontSize: "40px", fontWeight: "bolder", color: "gray", textAlign: "center", fontFamily: "Clash Display Medium" }}>{row.assetName.split(" ").slice(0, 1).map((n) => n[0]).join("").toUpperCase()}</div>
                          }
                        </td>
                        <td style={{ color: 'white', fontFamily: "Clash Display Medium" }}>{row.assetName}</td>
                        <td style={{ color: 'white', fontFamily: "Clash Display Medium" }}>{row.actualPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <div style={{ color: 'white', fontFamily: "Clash Display Medium" }}>
                  <h5>Total Amount: {amount}</h5>
                  <Button className='btn btn-primary' style={{ width: "100%", borderRadius: "5px" }} onClick={() => { setPaymentModal(true); setModalShow(false) }}>Proceed to pay</Button>
                </div>
              </Modal.Footer>
            </Modal> */}

          {/* PAYMENT */}
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={() => {
              payForAssets.length ? closePaymentModal() : setModalShow(false);
              setPromoCodeApplied(null);
              setValidatingPromoCode(false);
              setPromoCodeText("");
              setPromoInput(false);
            }}
          >
            <Modal.Header
              className="text-white"
              closeButton
              style={{ padding: "1rem 2rem" }}
              closeVariant="white"
            >
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontFamily: "Clash Display Bold" }}
              >
                Review Selection
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div
                className="cart-body text-white d-flex align-items-start"
                style={{ width: "100%", padding: "1.5rem 1rem" }}
              >
                {payForAssets.length ? (
                  <>
                    {/* Items */}
                    <div
                      className="cart-item"
                      style={{
                        border: "1px solid rgb(196 196 196 / 36%)",
                        borderRadius: "0.6rem",
                        width: "60%",
                        height: "fit-content",
                        background: "rgb(30 29 71 / 64%)",
                      }}
                    >
                      {payForAssets.map((row, i) => (
                        <>
                          {i !== 0 && (
                            <hr style={{ margin: "0", padding: "0" }} />
                          )}
                          <div
                            className="cart-item-data d-flex justify-content-between align-items-center m-2"
                            style={{ padding: "1rem 0.5rem" }}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="cart-item-data-thumbnail">
                                <img
                                  alt=""
                                  src={row.thumbnail}
                                  style={{ width: "40px", height: "40px" }}
                                />
                              </div>
                              <div
                                className="cart-item-data-info d-flex flex-column"
                                style={{ marginLeft: "1.5rem" }}
                              >
                                <div
                                  className="cart-item-title"
                                  style={{
                                    fontFamily: "Clash Display SemiBold",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {row.assetName}
                                </div>
                                <div
                                  className="cart-item-id"
                                  style={{
                                    fontFamily: "Clash Display Medium",
                                    fontSize: "0.85rem",
                                    opacity: "0.8",
                                  }}
                                >
                                  #{row.assetId}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="cart-item-cost"
                                style={{
                                  fontFamily: "Clash Display SemiBold",
                                  fontSize: "1.2rem",
                                }}
                              >
                                $ {row.actualPrice}
                              </div>
                              <div
                                className="cart-item-delete"
                                onClick={() =>
                                  deleteItemFromCart(row.assetName)
                                }
                                style={{
                                  fontFamily: "Clash Display SemiBold",
                                  fontSize: "1rem",
                                  cursor: "pointer",
                                  marginTop: "-0.25rem",
                                  marginLeft: "4rem",
                                }}
                              >
                                <MdDeleteOutline />
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                    {/* Summary */}
                    <div
                      className="d-flex flex-column justify-content-end align-items-end"
                      style={{ width: "40%" }}
                    >
                      <div
                        className=""
                        style={{
                          whiteSpace: "nowrap",
                          fontFamily: "Clash Display SemiBold",
                          fontSize: "1.1rem",
                          textAlign: "right",
                          padding: "1rem 1.5rem 1rem 1.5rem",
                          background: "#1e1d47",
                          borderRadius: "0.6rem",
                          width: "90%",
                        }}
                      >
                        <div
                          className={promoInput ? "mb-2" : "mb-4"}
                          onClick={() => setPromoInput(true)}
                          style={{
                            fontFamily: "Clash Display Light",
                            fontSize: "0.75rem",
                            textAlign: "right",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          Have a promo code ?
                        </div>
                        <form
                          onSubmit={applyPromoCode}
                          className={
                            promoCodeApplied !== null
                              ? "promo-code-input"
                              : "mb-4 promo-code-input"
                          }
                          style={{
                            position: "relative",
                            display: promoInput ? "block" : "none",
                            width: "100%",
                          }}
                        >
                          <input
                            required
                            disabled={promoCodeApplied}
                            type="text"
                            value={promoCodeText}
                            onChange={(event) => {
                              setPromoCodeText(event.target.value);
                              setPromoCodeApplied(null);
                            }}
                            placeholder="Enter promo code"
                            style={{
                              background: "transparent",
                              border: "2px dotted #6a6a6a",
                              borderRadius: "0.6rem",
                              color: "white",
                              fontFamily: "Clash Display Bold",
                              outline: "none",
                              width: "100%",
                              fontSize: "0.9rem",
                              padding: "0.5rem 4rem 0.5rem 0.5rem",
                            }}
                          />
                          <button
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              height: "100%",
                              background: "transparent",
                              border: "none",
                              color: "white",
                              fontFamily: "Clash Display SemiBold",
                              fontSize: "0.8rem",
                              padding: "0.5rem 0.8rem",
                            }}
                          >
                            {validatingPromoCode ? (
                              <BeatLoader size={5} color="white" />
                            ) : promoCodeApplied !== null &&
                              promoCodeApplied ? (
                              "x"
                            ) : (
                              ((promoCodeApplied !== null &&
                                promoCodeApplied === false) ||
                                promoCodeApplied === null) &&
                              "Apply"
                            )}
                          </button>
                        </form>
                        <div
                          style={{
                            fontSize: "0.8rem",
                            fontFamily: "Clash Display Medium",
                            textAlign: "left",
                            marginLeft: "0.3rem",
                            marginTop: "0.3rem",
                          }}
                          className={promoCodeApplied !== null ? "mb-3" : ""}
                        >
                          {promoCodeApplied === true ? (
                            <div className="d-flex align-items-center text-success">
                              <BsCheckCircleFill
                                style={{ marginRight: "0.3rem" }}
                              />{" "}
                              Promo code applied
                            </div>
                          ) : promoCodeApplied === false ? (
                            <div className="d-flex align-items-center text-danger">
                              <MdCancel style={{ marginRight: "0.3rem" }} />{" "}
                              {promoCodeError}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <span
                            style={{
                              fontFamily: "Clash Display Medium",
                              opacity: "0.9",
                              fontSize: "1rem",
                            }}
                          >
                            Order amount
                          </span>{" "}
                          &nbsp;$ {calculateTotalCostWithoutDiscount()}
                        </div>
                        {promoCodeApplied !== null &&
                          promoCodeApplied === true && (
                            <div className="d-flex justify-content-between">
                              <span
                                style={{
                                  fontFamily: "Clash Display Medium",
                                  opacity: "0.9",
                                  fontSize: "1rem",
                                }}
                              >
                                Promo discount
                              </span>{" "}
                              &nbsp;(-) $ {appliedDiscount.discount}
                            </div>
                          )}
                        <hr />
                        <div className="d-flex justify-content-between">
                          <span
                            style={{
                              fontFamily: "Clash Display Medium",
                              opacity: "0.9",
                              fontSize: "1rem",
                            }}
                          >
                            Total
                          </span>{" "}
                          &nbsp;$ {calculateTotalCost()}
                        </div>
                      </div>
                      <div
                        style={{
                          float: "right",
                          marginTop: "1.5rem",
                          width: "90%",
                        }}
                      >
                        <PaypalCheckoutButton
                          amount={calculateTotalCost()}
                          products={payForAssets}
                          closeModal={setModalShow}
                          promoCode={appliedDiscount}
                          style={{ margin: "1rem" }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      whiteSpace: "nowrap",
                      fontFamily: "Clash Display SemiBold",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      padding: "1.5rem 3rem 1.5rem 3rem",
                      margin: "1rem",
                      width: "100%",
                      minHeight: "10rem",
                    }}
                  >
                    No assets selected
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>

          {/* <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={paymentModal}
              onHide={() => { setModalShow(false); setPaymentModal(false) }}
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontFamily: "Clash Display Bold" }}>
                  XRC Payment
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Payment amount={amount} assets={rowsAllowed} />
              </Modal.Body>

            </Modal> */}
        </div>
      )}
    </div>
  );
}

export default Recent;
