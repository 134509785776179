import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../Context";
import Modal from "react-bootstrap/Modal";

const PriceEstimation = (props) => {
  const { costData, daysData, terms } = useContext(Context);
  const [cost, setCost] = costData;
  const [days, setDays] = daysData;
  const [termsCheck, setTermsCheck] = terms;

  const fav = Object.keys(props.platforms).filter(
    (key) => props.platforms[key]
  );

  const platformData = {
    zepeto: {
      timeToComplete: 2,
      costToComplete: 600,
    },
    sandbox: {
      timeToComplete: 1,
      costToComplete: 300,
    },
    roblox_layered: {
      timeToComplete: 2,
      costToComplete: 600,
    },
    roblox_classic: {
      timeToComplete: 0.5,
      costToComplete: 150,
    },
    decentraland: {
      timeToComplete: 1.5,
      costToComplete: 450,
    },
    minecraft: {
      timeToComplete: 0.5,
      costToComplete: 150,
    },
    clonex: {
      timeToComplete: 1,
      costToComplete: 300,
    },
    ready_player_me: {
      timeToComplete: 1,
      costToComplete: 300,
    },
    snapchat: {
      timeToComplete: 3,
      costToComplete: 900,
    },
    hq_3d: {
      timeToComplete: 3,
      costToComplete: 900,
    },
  };

  useEffect(() => {
    let totalCost = 0,
      totalDays = 0;
    let hasClonex = fav.includes("clonex");
    let hasSnapchat = fav.includes("snapchat");
    let hasHq3d = fav.includes("hq_3d");
    let hasRpm = fav.includes("ready_player_me")

    fav.forEach((item) => {
      totalCost += platformData[item].costToComplete;
      totalDays += platformData[item].timeToComplete;
    });

    if ((hasClonex || hasSnapchat || hasRpm) && !hasHq3d) {
      totalCost += platformData["hq_3d"].costToComplete;
      totalDays += platformData["hq_3d"].timeToComplete;
    }

    setCost(() => totalCost);
    setDays(() => totalDays);
  }, [fav.length]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {/* {console.log(props.platforms)} */}
      <div
        className="d-flex flex-column align-items-center flex-lg-row justify-content-lg-around mt-5"
        style={{
          color: "white",
          fontFamily: "Clash Display Bold",
          width: "100%",
        }}
        data-tour='esitmated-price'
      >
        <div>
          <div className="fs-m" >
            Approximate Budget *
            <div className="text-center mt-3 fs-xxl">${cost}</div>
          </div>
        </div>

        <div className="fs-m mt-5 mt-lg-0" data-tour='esitmated-time'>
          <div>
            Expected Delivery Time *
            <div className="text-center mt-3 fs-xxl">{days}d</div>
          </div>
        </div>
      </div>
      <label className="mt-5">
        <input
          type="checkbox"
          className="form-check-input mr-2"
          required
          name="checkbox"
          onChange={(e) => {
            setTermsCheck(e.target.checked);
          }}
        />
        <span
          className="ml-2 position-relative text-white fs-xs"
          style={{ fontFamily: "Clash Display Medium" }}
        >
          By checking this box you confirm that you have read and agree to be
          bound by the WRBL
          <a
            onClick={() => setIsModalOpen(true)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {" "}
            Terms of Use.
          </a>
        </span>
      </label>

      <div
        className="text-white mt-4 d-flex mb-5 fs-xs"
        style={{ fontFamily: "Clash Display Medium", opacity: "0.8" }}
      >
        <div
          style={{
            fontFamily: "Clash Display Bold",
            fontSize: "1.3rem",
            marginRight: "0.6rem",
          }}
        >
          *
        </div>
        <div>
          The approximate budget & expected delivery date is just an estimate.
          You will get the actual cost & time once our team has received your
          submission.
        </div>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#02012d" }}
          closeVariant="white"
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="fs-sm"
            style={{ fontFamily: "Clash Display Bold" }}
          >
            Terms and Condition
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="fs-xs" style={{ backgroundColor: "#02012d" }}>
          <li className="term-privacy" style={{ padding: "0.5em 1em" }}>
            At NFT XRCOUTURE Pvt Ltd, we take the ownership and authenticity of
            user data very seriously. By submitting your personal or
            confidential information on our platform or website, you acknowledge
            that all information provided is true and accurate to the best of
            your knowledge, and that you are the legal owner of the information
            submitted.
          </li>
          <li className="term-privacy" style={{ padding: "0.5em 1em" }}>
            You affirm that you are not infringing upon the intellectual
            property rights of any third party and that you have obtained any
            necessary permissions, licenses or consents for any data, materials
            or content that you provide or upload on our platform. You agree to
            indemnify NFT XRCOUTURE Pvt Ltd against any and all claims, damages
            or losses resulting from any third party claims related to the
            information you provide or upload.
          </li>
          <li className="term-privacy" style={{ padding: "0.5em 1em" }}>
            You agree to allow NFT XRCOUTURE Pvt Ltd to collect, process, store,
            use, and share the information you provide or upload on our platform
            or website in accordance with our Privacy Policy. This includes
            sharing your information with third-party service providers, if
            necessary, for the provision of our services.
          </li>
          <li className="term-privacy" style={{ padding: "0.5em 1em" }}>
            By accepting this policy, you acknowledge that any breach of this
            policy may lead to legal action, termination of your account, or
            other appropriate measures.
          </li>
          <li className="term-privacy" style={{ padding: "0.5em 1em" }}>
            We take every measure to ensure the security and protection of your
            information, but we cannot guarantee the absolute security of any
            information transmitted through our website or platform. We
            encourage you to take necessary steps to protect your data,
            including using strong passwords, keeping your login details
            confidential, and not sharing any personal or sensitive information
            with others.
          </li>
          <li className="term-privacy" style={{ padding: "0.5em 1em" }}>
            If you do not agree with this policy or have any questions, please
            contact our customer support team.
          </li>
          <li className="term-privacy" style={{ padding: "0.5em 1em" }}>
            By clicking "I agree" or using our platform or website, you agree to
            the terms and conditions of this policy.
          </li>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PriceEstimation;
