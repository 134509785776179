import React, { useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import axios from "axios";

import "./TeaserVideo.css";

const TeaserVideo = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [err, setErr] = useState("");

  const [videoURL, setVideoURL] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const onChange = (e) => {
    setEmail(e.target.value);
    setErr("");
  };

  const onSumbit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    await axios
      .post(
        "https://api.xrcouture.com/teaser/accessvideo",
        {
          email: email,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res)
        setIsSubmitting(false);
        setIsAuth(true);
        setVideoURL(res.data.url);
      })
      .catch((err) => {
        // console.log(err)
        setIsAuth(false);
        setIsSubmitting(false);

        if (err.response) {
          if (err.response.data.msg === "Invalid user") {
            setErr(
              <>
                You're not on the list. Drop a "GM"{" "}
                <a
                  rel="noreferrer"
                  className="text-primary"
                  target={"_blank"}
                  href="https://www.linkedin.com/posts/subham101_interoperability-chatgpt-wrbl-activity-7050089782969827329-q9eL"
                >
                  here
                </a>{" "}
                to get exclusive access.
              </>
            );
          } else if (err.response.data.msg === "Expired") {
            setErr(
              <>
                Looks like, you've reached the maximum number of times to watch
                the demo. If you wish to learn more. Book a{" "}
                <a
                  rel="noreferrer"
                  className="text-primary"
                  target={"_blank"}
                  href="https://calendly.com/xrcouture/wrbl"
                >
                  consultation call
                </a>{" "}
                with us.
              </>
            );
          } else {
            setErr(
              "Something went wrong, Please try again later. Or contact us at hello@xrcouture.com"
            );
          }
        } else {
          setErr(
            "Something went wrong, Please try again later. Or contact us at hello@xrcouture.com"
          );
        }
      });
  };

  return (
    <div className="app-container-teaser">
      {!isAuth ? (
        <>
          <div className="input-cont-teaser">
            <div className="player-header-teaser d-flex align-items-center">
              <img
                style={{ width: "5rem", marginRight: "0.3em" }}
                src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/xrcnew.webp"
                alt=""
              ></img>
              <div>
                <span style={{ fontFamily: "Clash Display Bold" }}>WRBL:</span>{" "}
                &nbsp; Exclusive Demo
              </div>
            </div>

            <div className="player-input-teaser footer-subscribe-form-teaser">
              <form className="" onSubmit={onSumbit}>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  className="footer-subscribe-text-teaser w-100"
                  required
                  autoComplete="off"
                  title=""
                  onChange={onChange}
                />
                <button
                  type="submit"
                  className="footer-subscribe-button-teaser teaser-button"
                  disabled={isSubmitting}
                >
                  Watch video
                </button>
              </form>
            </div>

            <div className="output-cont-teaser d-flex align-items-center">
              {isSubmitting ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                <p className="text-white mt-2">{err}</p>
              )}
            </div>
          </div>
        </>
      ) : (
        <ReactHlsPlayer
          src={videoURL}
          autoPlay={true}
          controls={true}
          width="100%"
          height="auto"
        />
      )}
    </div>
  );
};

export default TeaserVideo;
