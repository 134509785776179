import React, { useState, useEffect } from "react";

import JSZip from "jszip";
import { saveAs } from "file-saver";

import axios from "axios";
import { useParams, useNavigate } from "react-router";

import "./AssetView.css";
import { MdOutlineDownload } from "react-icons/md";
import S3 from 'aws-sdk/clients/s3';

const AssetView = (props) => {
  const navigate = useNavigate();

  const { brandx, assetx } = useParams();

  const [assetTitle, setAssetTitle] = useState("");
  const [description, setDescription] = useState("");

  const [files, setFiles] = useState([]);

  const [platforms, selectPlatforms] = useState({
    zepeto: false,
    sandbox: false,
    roblox_layered: false,
    roblox_classic: false,
    decentraland: false,
    clonex: false,
    minecraft: false,
    snapchat: false,
    hq_3d: false,
  });

  const [platformsArray, setPlatformsArray] = useState([]);

  const [status, setStatus] = useState("");

  const [progress, setProgress] = useState("");

  const [cost, setCost] = useState("");

  const [days, setDays] = useState("");

  const [fCost, setFCost] = useState("");

  const [fDays, setFDays] = useState("");

  const [thumbnail, setThumbnail] = useState("");

  const [created, setCreated] = useState("");

  const [updated, setUpdated] = useState("");

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    var platformsArrayData = [];

    for (const [key, value] of Object.entries(platforms)) {
      value === true && platformsArrayData.push(key);
    }

    setPlatformsArray(platformsArrayData);
  }, [platforms]);

  useEffect(() => {
    axios
      .post(
        "https://api.xrcouture.com/brands/asset",
        {
          brand: brandx,
          name: assetx,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.data.asset[0]);
        const data = response.data.asset[0];

        setAssetTitle(!data.assetName ? "-" : data.assetName);
        setDescription(!data.description ? "-" : data.description);
        if (data.thumbnail.length) setThumbnail(data.thumbnail[0]);

        setFiles(data.brandAssetFiles);

        const newPlatforms = platforms;

        data.platform.map((item) => {
          console.log(item);
          return (newPlatforms[item] = true);
        });

        selectPlatforms(newPlatforms);

        setStatus(!data.status ? "-" : data.status);

        setProgress(!data.progress ? "-" : data.progress);

        setCost(!data.approximatePrice ? "-" : "$ " + data.approximatePrice);

        setDays(!data.approximateTime ? "-" : data.approximateTime);

        setFCost(!data.actualPrice ? "-" : "$ " + data.actualPrice);

        setFDays(!data.actualTime ? "-" : data.actualTime);

        setCreated(
          !data.createdAt ? "-" : new Date(data.createdAt).toLocaleString()
        );

        setUpdated(
          !data.updatedAt ? "-" : new Date(data.updatedAt).toLocaleString()
        );
      });
  }, []);

  const fileName = (link) => {
    const url = new URL(link);
    const pathParts = url.pathname.split("/");
    const filename = pathParts[pathParts.length - 1];
    const filenameParts = filename.split("_");
    return filenameParts[filenameParts.length - 1];
  };

  const closeViewAsset = () => {
    navigate(`/admin/brands/${brandx}`);
  };

  async function downloadFiles() {
    setDownloading(true);
    const zip = new JSZip();
    for (const link of files) {
      const response = await fetch(link);
      const blob = await response.blob();
      const filename = link.split("/").pop();
      zip.file(filename, blob);
    }
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `${brandx}-files`);
    setDownloading(false);
  }


  const downloadFilesAsZip = async (bucketName, fileLinks) => {
    const s3 = new S3();
    const zip = new JSZip();
  
    for (const link of fileLinks) {
      const fileKey = link.split(bucketName + '/')[1];
      const fileData = await s3.getObject({ Bucket: bucketName, Key: fileKey }).promise();
      zip.file(fileKey.split('/').pop(), fileData.Body);
    }
  
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'files.zip');
  };



  return (
    <div
      className="asset-view-container"
      style={{ background: "#070414", height: "100%" }}
    >
      <div className="d-flex justify-content-between align-items-center mb-5 asset-view-header">
        <div className="upload-title fs-xl">View Asset</div>
        <div className="d-flex flex-row">
          <button style={{padding:"10px 10px", marginRight:"50px", borderRadius:"10px", fontFamily:"Clash Display Medium",color:"white", cursor:status==="Completed"?"pointer":"no-drop"}} disabled={status !== "Completed"?true:false} className={`${status!=="Completed"?"btn-secondary":""} "btn-create box-shadow"`}>
            Download <MdOutlineDownload color="white" />
          </button>
        <div
          className="upload-title fs-lg"
          onClick={closeViewAsset}
          style={{ cursor: "pointer" }}
        >
          x
        </div>
        </div>
      </div>

      <div className="text-white asset-view-body">
        <div
          className="d-flex flex-wrap asset-view-box"
          style={{ border: "1px solid grey", borderRadius: "0.6em" }}
        >
          {thumbnail && thumbnail.length && (
            <div className="user-data-group-alt">
              <div className="user-data-value-alt">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{}}
                >
                  <img
                    className="thumbnail-preview-img-view"
                    src={thumbnail}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          )}
          <div className="user-data-group-alt">
            <div className="">
              <div className="user-data-field-alt fs-xs">Asset name</div>
              <div className="user-data-value-alt fs-m">{assetTitle}</div>
            </div>

            <div className="mt-4 mt-md-5">
              <div className="user-data-field-alt fs-xs">Description</div>
              <div className="user-data-value-alt fs-m">
                {description && description.length ? description : "-"}
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex mt-5 asset-view-box flex-wrap flex-sm-nowrap"
          style={{ border: "1px solid grey", borderRadius: "0.6em" }}
        >
          <div className="user-data-group-alt" style={{ marginRight: "2em" }}>
            <div className="user-data-field-alt fs-xs">Selected platforms</div>
            {Object.keys(platforms)
              .filter((platform) => platforms[platform])
              .map((platform) => (
                <div className="user-data-value-alt fs-m">{platform}</div>
              ))}
          </div>

          <div className="d-flex flex-wrap">
            <div className="user-data-group-alt">
              <div className="user-data-field-alt fs-xs">Status</div>
              <div className="user-data-value-alt fs-m">{status}</div>
            </div>

            <div className="user-data-group-alt">
              <div className="user-data-field-alt fs-xs">Created at</div>
              <div className="user-data-value-alt fs-m">{created}</div>
            </div>

            <div className="user-data-group-alt">
              <div className="user-data-field-alt fs-xs">Last updated</div>
              <div className="user-data-value-alt fs-m">{updated}</div>
            </div>

            <div className="user-data-group-alt">
              <div className="user-data-field-alt fs-xs">Progress</div>
              <div className="user-data-value-alt fs-m">{progress} / 100</div>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-wrap mt-5 asset-view-box"
          style={{ border: "1px solid grey", borderRadius: "0.6em" }}
        >
          <div className="user-data-group-alt">
            <div className="user-data-field-alt fs-xs">Approximate cost</div>
            <div className="user-data-value-alt fs-m">{cost}</div>
          </div>

          <div className="user-data-group-alt">
            <div className="user-data-field-alt fs-xs">
              Approximate days to complete
            </div>
            <div className="user-data-value-alt fs-m">{days}</div>
          </div>

          <div className="user-data-group-alt">
            <div className="user-data-field-alt fs-xs">Final Cost</div>
            <div className="user-data-value-alt fs-m">{fCost}</div>
          </div>

          <div className="user-data-group-alt">
            <div className="user-data-field-alt fs-xs">
              Actual time to complete
            </div>
            <div className="user-data-value-alt fs-m">{fDays}</div>
          </div>
        </div>

        <div
          className="d-flex flex-column mt-5 asset-view-box"
          style={{ border: "1px solid grey", borderRadius: "0.6em" }}
        >
          <div className="user-data-group-alt">
            <div className="user-data-field-alt fs-xs">Uploaded files</div>
            <div className="user-data-value-alt d-flex flex-wrap">
              {files.map((file) => {
                return (
                  <div
                    className="d-flex align-items-center uploaded-img-cont"
                    style={{
                      background: "rgb(29 27 67 / 35%)",
                      borderRadius: "0.6em",
                    }}
                  >
                    <img
                      className="uploaded-img-preview"
                      src={file}
                      alt=""
                      style={{ marginRight: "0.6em" }}
                    ></img>
                    <div className="d-flex flex-column">
                      <div className="user-data-img-title fs-sm">
                        {fileName(file)}
                      </div>
                      <div className="user-data-img-size fs-xs">10 MB</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {props.role === "admin" && (
            <div
              className="user-data-group-alt"
              style={{ paddingRight: "0rem" }}
            >
              <button
                onClick={downloadFiles}
                className="form-submit uc-buttons fs-sm"
              >
                {downloading ? "Downloading" : "Download files"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetView;
