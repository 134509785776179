import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Loading from "../Loading";
import { isMobile } from "react-device-detect";

const BrandsProtectedRoutes = () => {
  const { brandx } = useParams();

  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [role, setRole] = useState("");
  const [brand, setBrand] = useState("");
  const [whitlelisted, setWhitlelisted] = useState("");

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          "https://api.xrcouture.com/auth/authorize",
          {
            email: localStorage.getItem("email"),
            brand: localStorage.getItem("brand"),
          },
          { withCredentials: true }
        );
        console.log(response);
        setIsAuthenticated(response.data.isUserAuthorized);
        setRole(response.data.role);
        setRole(response.data.role);
        setBrand(response.data.brand);
        setWhitlelisted(response.data.isUserwhitelisted);
      } catch (error) {
        console.error(error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/signup");
      console.warn("Not Authenticated");
    } else if (isAuthenticated && whitlelisted === false) {
      navigate(`/brands/${brand}/waitlist`);
      console.warn("NO ACCESS");
    } else if (isAuthenticated && role === "brands" && whitlelisted === true) {
      console.warn("Authenticated as BRAND");
      if (brandx !== brand) {
        navigate(`/brands/${brand}`);
        console.error("ACCESS RESTRICATED TO THIS BRAND");
      }
    } else if (isAuthenticated && role === "admin") {
      console.log("ACCESS RESTRICATED TO THIS USER");
      navigate(`/ admin / brands`);
    }
  }, [isAuthenticated, role, brand, navigate, brandx, whitlelisted]);

  console.log("Is user authenticated? ", isAuthenticated);

  return isAuthenticated === null ? (
    <Loading />
  ) : (
    isAuthenticated && role === "brands" && brandx === brand && (
      <Sidebar>
        <Outlet />
      </Sidebar>
    )
  );
};

export default BrandsProtectedRoutes;
