import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import "./TicketPage.css";
import { BeatLoader } from "react-spinners";

const TicketPage = (props) => {
  const { ticketId, brandx } = useParams();

  const navigate = useNavigate();

  const [chats, setChats] = useState([]);
  const [ticketName, setTicketName] = useState();
  const [ticketNumber, setTicketNumber] = useState();
  const [ticketStatus, setTicketStatus] = useState();
  const [update, setUpdate] = useState(false);

  const [msgOnChange, setMsgOnChange] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [closeTicketLoading, setCloseTicketLoading] = useState(false);

  const [descToggle, setDescToggle] = useState(true);

  const [selectedfile, SetSelectedFile] = useState([]);

  const InputChange = (e) => {
    e.preventDefault();

    // check the file quant should be less than or equals to 10 *done
    // check that already how many files are uploaded *done

    // console.log(e.target.files.length)

    if (
      e.target.files.length > 10 ||
      selectedfile.length === 10 ||
      e.target.files.length + selectedfile.length > 10
    ) {
      alert("You can only upload upto 10 files!");
      return;
    }

    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        // check file type : e.target.files[i].type
        // check file size : e.target.files[i].size *done

        // if (!isValidFileUploadedAdmin(e.target.files[i])) {
        //   alert(file.name + "This file is not supported")
        //   return;
        // }
        // if (!isValidFileUploaded(e.target.files[i])) {
        //   alert(file.name + "This file is not supported")
        //   return;
        // }

        if (e.target.files[i].size > 20971520) {
          alert("Please not this file: " + file.name);
          return;
        }

        SetSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              // id: shortid.generate(),
              filename: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileimage: reader.result,
              datetime:
                e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
              // filesize: filesizes(e.target.files[i].size),
              fileData: e.target.files[i],
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };

  useEffect(() => {
    axios
      .post(
        "https://api.xrcouture.com/support/getticket",
        {
          ticketId,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setChats(response.data.tickets.description);
        setTicketName(response.data.tickets.title);
        setTicketNumber(response.data.tickets.ticketId.split("ID")[1]);
        setTicketStatus(response.data.tickets.status);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [update]);

  const handleMsgOnChange = (e) => {
    e.preventDefault();
    setMsgOnChange(e.target.value);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();

    if (msgOnChange.length) {
      setSubmitting(true);

      var formData = new FormData();

      formData.append("message", msgOnChange);
      formData.append("ticketId", ticketId);

      if (props.role === "admin") {
        formData.append("sender", "admin");
        formData.append("receiver", brandx);
      } else {
        formData.append("sender", brandx);
        formData.append("receiver", "admin");
      }

      var filesArray = [];
      filesArray = selectedfile.map((item) => item.fileData);

      for (let i = 0; i < filesArray.length; i++) {
        formData.append("image", filesArray[i]);
      }

      await axios
        .post("https://api.xrcouture.com/support/update", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          setMsgOnChange("");
          setSubmitting(false);
          setUpdate((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
          setMsgOnChange("");
          setSubmitting(false);
        });
    }
  };

  const closeTicketFun = async () => {
    setCloseTicketLoading(true);

    await axios
      .post(
        "https://api.xrcouture.com/support/status",
        {
          brandName: brandx,
          ticketId: ticketId,
          status: "close",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCloseTicketLoading(false);
        navigate(`/brands/${brandx}/support`);
      })
      .catch((err) => {
        console.log(err);
        setCloseTicketLoading(false);
      });
  };

  return (
    <div className="support-chat-container">
      <div className="upload-title fs-lg">
        <span
          className={
            ticketStatus === "open"
              ? "text-success fs-lg"
              : "text-secondary fs-lg"
          }
        >
          #{ticketNumber}
        </span>
        &nbsp;{ticketName}
      </div>

      <div className={"chat-body mt-4 d-flex flex-column"}>
        {chats.map((msg) => {
          return (
            <div className={msg.sender === "admin" ? "a-box" : "r-box"}>
              <div className="info-cont">
                <div
                  className={
                    msg.sender === "admin" ? "msg-info-a" : "msg-info-r"
                  }
                >
                  {msg.sender === "admin" ? (
                    <span style={{ fontFamily: "Clash Display SemiBold" }}>
                      ADMIN
                    </span>
                  ) : (
                    <span style={{ fontFamily: "Clash Display SemiBold" }}>
                      YOU
                    </span>
                  )}{" "}
                  <span
                    style={{
                      fontSize: "0.85rem",
                      fontFamily: "Clash Display Light",
                      color: "#c4c4c4",
                    }}
                  >
                    &nbsp;&nbsp;{msg.timestamp.split("GMT")[0]}
                  </span>
                </div>
                <hr style={{ margin: "0" }} />
                <div className="msg-body">{msg.message}</div>
                {msg.files.map((file) => {
                  return (
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        margin: "0rem 2rem 1rem 2rem",
                      }}
                      src={file}
                      alt=""
                    ></img>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* Options */}
      {ticketStatus === "open" && (
        <form onSubmit={handleSendMessage}>
          <div className="chat-input mt-5">
            {/* <input autoFocus onChange={handleMsgOnChange} value={msgOnChange} className='support-chat-input' type={Text}></input> */}

            <div>
              <div className="d-flex desc-options-cont">
                <div
                  className={
                    descToggle === true ? "desc-options active" : "desc-options"
                  }
                  onClick={() => setDescToggle(true)}
                >
                  Message
                </div>
                <div
                  className={
                    descToggle === true ? "desc-options" : "desc-options active"
                  }
                  style={{ marginLeft: "1rem" }}
                  onClick={() => setDescToggle(false)}
                >
                  Upload Files
                </div>
              </div>

              {descToggle ? (
                <textarea
                  style={{ borderRadius: "0rem 0.6rem 0.6rem 0.6rem" }}
                  required
                  onChange={handleMsgOnChange}
                  rows="4"
                  className="uc-items-input"
                  type="text"
                  name="name"
                  maxLength={100}
                  value={msgOnChange}
                />
              ) : (
                <div
                  className="fileupload-view upload-contents"
                  style={{
                    marginTop: "0",
                    border: "0.1em solid #2E2C6A",
                    background: "#2E2C6A",
                    borderRadius: "0rem 0.6rem 0.6rem",
                  }}
                >
                  <div className="row justify-content-center m-0">
                    <div className="col-md-12">
                      <div className="card" style={{ margin: "0" }}>
                        <div className="card-body">
                          <div className="kb-data-box">
                            <div
                              className="kb-modal-data-title"
                              style={{ margin: "0" }}
                            >
                              <div className="kb-data-title">
                                {/* <h6>Multiple File Upload With Preview</h6> */}
                              </div>
                              {/* <form onSubmit={FileUploadSubmit}> */}
                              <form>
                                <div
                                  className="kb-file-upload"
                                  style={{
                                    marginBottom: "0",
                                    borderBottom: "1px solid #c4c4c4",
                                  }}
                                >
                                  <div
                                    className="file-upload-box d-flex flex-column"
                                    style={{ border: "none" }}
                                  >
                                    <input
                                      type="file"
                                      id="fileupload"
                                      className="file-upload-input"
                                      onChange={InputChange}
                                      multiple
                                    />
                                    <span className="uc-items-title text-white">
                                      Drag and drop or{" "}
                                      <span className="file-link uc-items-link">
                                        Choose your files
                                      </span>
                                    </span>

                                    <div className="uc-items-subtitle">
                                      Max file size is 20 MB
                                    </div>

                                    <div className="uc-items-subtitle">
                                      Max file size is 20 MB
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="kb-attach-box"
                                  style={{
                                    padding: "3rem",
                                    background: "#080808",
                                  }}
                                >
                                  {selectedfile.map((data, index) => {
                                    const {
                                      id,
                                      filename,
                                      filetype,
                                      fileimage,
                                      datetime,
                                      filesize,
                                    } = data;
                                    return (
                                      <div className="file-atc-box" key={id}>
                                        {filename.match(
                                          /.(jpg|jpeg|png|gif|svg)$/i
                                        ) ? (
                                          <div className="file-image">
                                            {" "}
                                            <img src={fileimage} alt="" />
                                          </div>
                                        ) : (
                                          <div className="file-image">
                                            <i className="far fa-file-alt"></i>
                                          </div>
                                        )}
                                        <div className="file-detail text-white">
                                          <h6>{filename}</h6>
                                          <p></p>
                                          <p>
                                            <span>Size : {filesize}</span>
                                            <span className="ml-2">
                                              Modified Time : {datetime}
                                            </span>
                                          </p>
                                          <div className="file-actions">
                                            <button
                                              type="button"
                                              className="file-action-btn"
                                              onClick={() =>
                                                DeleteSelectFile(id)
                                              }
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* buttons */}
            <div className="d-flex justify-content-end">
              {ticketStatus === "open" && (
                <button
                  onClick={closeTicketFun}
                  className="uc-buttons mt-4"
                  style={{ padding: "0.5em 2em" }}
                >
                  {closeTicketLoading ? (
                    <BeatLoader size={8} color="white" />
                  ) : (
                    "Close ticket"
                  )}
                </button>
              )}
              <button
                type="submit"
                className="uc-buttons mt-4"
                style={{ padding: "0.5em 2em", marginLeft: "2em" }}
              >
                {submitting ? <BeatLoader size={8} color="white" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default TicketPage;
