import React, { useState, useEffect, useContext } from "react";
import "./Dashboard.css";

import MyComponent from "./AdminBrands";
import { useParams } from "react-router";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from "@novu/notification-center";
import { Context1 } from "../../../Context";

function Dashboard() {
  const [filterText, setFilterText] = React.useState("");
  const { brandx } = useParams();
  const [open, setOpen] = useState(false);
  const {credits} = useContext(Context1)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".notification")) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);

  return (
    <div>
      <div className="row container-dashboard">
        <div className="col-6">
          <h1 style={{ color: "white", fontFamily: "Clash Display Medium" }}>
            {brandx} Overview
          </h1>
        </div>
        <div className="col-6 row d-flex align-items-center justify-content-between">
          <div className="col-10 h-100 m-0 p-0">
            <div class="input-icons">
              <i class="fa fa-search input-icon"></i>
              <input
                class="input-field manage-asset-input"
                type="text"
                placeholder="Search Assets"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1 p-1 d-flex justify-content-center">
            <div className="icons-dashboard">
              <NovuProvider
                subscriberId={"admin"}
                applicationIdentifier={"fFvjvHFwBD3F"}
              >
                <PopoverNotificationCenter>
                  {({ unseenCount, unseenBadgeColor }) => (
                    <>
                      {console.log(unseenCount)}
                      <NotificationBell unseenCount={unseenCount} />
                      <div class="number">{unseenCount}</div>
                    </>
                  )}
                </PopoverNotificationCenter>
              </NovuProvider>
            </div>
          </div>
        </div>
      </div>
      <MyComponent filterText={filterText} brand={brandx} />
    </div>
  );
}

export default Dashboard;
