import logo from "./logo.svg";
import "./App.css";
import Register from "./onboard-brand/Register";
import { Route, Routes, useRoutes } from "react-router-dom";
import Signin from "./onboard-brand/Signin";
import Resetpassword from "./onboard-brand/Resetpassword";
import Updatepassword from "./onboard-brand/Updatepassword";
import Data from "./onboard-brand/Data";
import Sidebar from "./components/Sidebar";
import { TourProvider, useTour } from "@reactour/tour";
import { useNavigate, useParams } from "react-router";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { components } from '@reactour/tour'
import Dashboard from "./pages/Dashboard.jsx";
import About from "./pages/About.jsx";
import Analytics from "./pages/Analytics.jsx";
import Comment from "./pages/Asset.jsx";
import Product from "./pages/Product.jsx";
import ProductList from "./pages/ProductList.jsx";
import VerifyEmail from "./pages/VerifyEmail.js";
import { gapi } from "gapi-script";
import { useEffect } from "react";
import ManageAssets from "./Asset_components/ManageAssets";
import "bootstrap/dist/css/bootstrap.min.css";
import Upload from "./pages/Upload";
import AdminPage from "./pages/AdminPage";
import Update from "./pages/Update";
import AssetView from "./pages/AssetView";
import Upload1 from "./Asset_components/Upload";
import Assetedit from "./Asset_components/Assetedit";
import Dashboard1 from "./New-src/Dashboard/Dashboard/Dashboard";
import "swiper/css";
import ChatBody from "./components/chatBody/ChatBody";
import AdminDashboard from "./New-src/AdminDashboard/AdminDashboard";
import AdminBrand from "./New-src/AdminDashboard/AdminPage/Dashboard";
import ProtectedRoutes from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Signin1 from "./onboard-brand/Signin";
import Drafts from "./pages/Drafts";
import AdminProtectedRoutes from "./RouteProtection/AdminProtectedRoutes";
import BrandsProtectedRoutes from "./RouteProtection/BrandsProtectedRoutes";
import RouteNotDefined from "./RouteProtection/RouteNotDefined";
import RouteHidden from "./RouteProtection/RouteHidden";
import ScrollToTop from "./ScrollToTop";
import Payment from "./components/Payment/Payment";
import { useState } from "react";
import { Context1 } from "./Context";
import Collection from "./pages/Collection";
import Support from "./pages/Support";
import TicketPage from "./components/Support/TicketPage";
import AdminSupportTickets from "./components/Support/AdminSupportTickets";

import { GoogleOAuthProvider } from "@react-oauth/google";
import Test from "./pages/Test";
import WaitlistRouteProtection from "./RouteProtection/WaitlistRouteProtection";
import Waitlist from "./pages/Waitlist";
import UserList from "./New-src/AdminDashboard/UserList";
import BrandDetailsPage from "./pages/BrandDetailsPage";
import OneTimeVideo from "./pages/OneTimeVideo";
import { isMobile } from "react-device-detect";
import MobileRestrictionScreen from "./pages/MobileRestrictionScreen";
import TeaserVideo from "./pages/TeaserVideo";
import MobileOrNot from "./RouteProtection/MobileOrNot";
import { toast } from "react-toastify";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { BiSkipNext } from "react-icons/bi";

const App = () => {

  //  const steps = [
  //   {
  //     selector: ".first-step",
  //     content: "Create your custom virtual clothing",
  //   },
  //   {
  //     selector:".create-1",
  //     content:"Give the name for your asset"
  //   },

  //   {
  //     selector: ".second-step",
  //     content: "Never miss an update with our notifications",
  //   },
  //   {
  //     selector: ".third-step",
  //     content: "Find your assets with our search",
  //   },
  //   {
  //     selector: ".fourth-step",
  //     content: "Find exactly what you’re looking for with our filters",
  //   },
  //   {
  //     selector: ".fifth-step",
  //     content: "Download your completed assets",
  //   },
  //   {
  //     selector: ".sixth-step",
  //     content: "Pay for your pending assets with ease",
  //   },
  //   {
  //     selector: ".seventh-step",
  //     content: "Discover how interoperability works with our video",
  //   },
  //   {
  //     selector: ".eighth-step",
  //     content: "Contact us for help with your assets",
  //   },
  //   // ...
  // ];
  const steps = [
    {
      selector: "[data-tour='paragraph']",
      content: (
        <p><code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 1: </code>Start by clicking on "Create 3D Asset"</p>
      )
    },
    {
      selector: "[data-tour='heading']",
      content: (
        <p>
         <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 2: </code> Give the thumbnail for your asset
        </p>
      )
    },
    {
      selector: "[data-tour='asset-info']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 3: </code>Give a name and description.
        </p>
      )
    },
    {
      selector: "[data-tour='next-step']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 4: </code>Proceed to next step.
        </p>
      )
    },
    {
      selector: "[data-tour='upload-files']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 5: </code>Upload of your design reference(Images, Video, Pdf, 3D Files, etc..).
        </p>
      )
    },
    {
      selector: "[data-tour='platform']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 6: </code>Select the platforms you wish to upload your design on.
        </p>
      )
    },
    {
      selector: "[data-tour='esitmated-price']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 7: </code>The estimate delivery time and cost will be shown here.
        </p>
      )
    },
    {
      selector: "[data-tour='asset-submit']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 8: </code>Click "Submit" to publish your asset.
        </p>
      )
    },
    {
      selector: "[data-tour='recent-assets']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 9: </code>You can manage and view the status of your 3D assets here.
        </p>
      )
    },
    {
      selector: "[data-tour='pay-assets']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 10: </code>Once your assets have been reviewed by our team, you can make the payment to continue with the development process.
        </p>
      )
    },
    {
      selector: "[data-tour='download-assets']",
      content: (
        <p>
         <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 11: </code> Finally, Once the assets are developed you can download the files
        </p>
      )
    },

    {
      selector: "[data-tour='notification']",
      content: (
        <p>
          <code style={{fontFamily:"Clash Display Light",fontSize:"1em"}}>Step 12: </code>Never miss an update with our notifications.
        </p>
      )
      },
  ];
  const disableBody = (target) => disableBodyScroll(target);
  
  const enableBody = (target) => enableBodyScroll(target);

  
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragHighlight("#ffffff");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragHighlight("#0B0947");
  };

  const handleDrop = () => {
    setDragHighlight("#0B0947");
  };

  const CLIENT_ID ="369046900295-j3ntqemlshdnmpqaa8mveo8qimd8vh6f.apps.googleusercontent.com";
    useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });
  const navigate = useNavigate()
  const [cbtn,setCbtn] = useState(false)
  const [open,setOpen] = useState(true)
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateAssets, setUpdateAssets] = useState(false);
  const [page,setPage] = useState(1)
  const [payForAssets, setPayForAssets] = useState([]);
  const brandx  = localStorage.getItem("brand")
  const [step, setStep] = useState(0);
  const [pending, setPending] = useState(false)
  const [op,setop] = useState(false)
  const [credits,setCredits] = useState(0)
  const [endBoard,setEndBoard] = useState(false)
  const setCurrentStep = (step) => {
    switch (step) {
      case 0:
        navigate(`/brands/${brandx}`, true);
        setOpen(true)
        break;
      case 1:
        navigate(`/brands/${brandx}/create/`, true);
        setPage(1)
        break;
      case 2:
        navigate(`/brands/${brandx}/create/`, true);
        setPage(1)
        break;
      case 3:
        navigate(`/brands/${brandx}/create/`, true);
        setPage(1)
        break;
      case 4:
        navigate(`/brands/${brandx}/create/`, true);
        setPage(2)
        break
      case 5:
        navigate(`/brands/${brandx}/create/`, true);
        setPage(3)
        break;
      case 6:
        navigate(`/brands/${brandx}/create/`, true);
        break;
      case 7:
        navigate(`/brands/${brandx}/create/`, true);
        break;
      case 8:
        navigate(`/brands/${brandx}/`, true);
        break;
      case 11:
        navigate(`/brands/${brandx}/`, true);
        break;
      case 12:
        navigate(`/brands/${brandx}/`, true);
        setOpen(true)
        break
      case 14:
        navigate(`/brands/${brandx}/`, true);
        setOpen(true)
        break
      default:
        setOpen(false)
        break
    }
    setStep(step);
  }
  const CustomPreviousButton = ({ onClick }) => (
    <button onClick={onClick}>
      <FiChevronLeft />
    </button>
  );
  
  const CustomNextButton = ({ onClick }) => (
    <button onClick={onClick}>
      <BiSkipNext />
    </button>
  );
  function Close({ onClick }) {
    return (
 
      <button
        onClick={onClick}
        className="btn-close-tour d-flex align-items-center justify-content-center"
        style={{position:"absolute", right:0, top:0,transform:"translate(-50%,-50%)", width:"25px", height:"25px", background:"#02012d",borderColor:".6px solid #fff",  borderRadius:"50%", color:"white", fontSize:".8em"}}
      >
        x
      </button>
    )
  }
  function Badge({ children }) {
    return (
      <components.Badge
        styles={{ badge: (base) => ({ ...base, background:"none", position:"absolute" ,fontFamily:"Clash Display Medium", display:"flex", alignItems:"center",justifyContent:"center", fontSize:".em" }) }}
      >
        {children}
      </components.Badge>
    )
  }

  const { isOpen, close, goTo, next, prev, currentStep } = useTour();
  const currentStepIndex = currentStep;

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    prev();
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    next();
  };
  const [dragHighlight, setDragHighlight] = useState("#0B0947");
  const {setIsOpen} = useTour()
  const [onboard,setOnboard] = useState(false)
  useEffect(()=>{
    setIsOpen(op)
    console.log("states changed",op)
  },[op])

  return (
    <TourProvider
    steps={steps}
    currentStep={step}
    setCurrentStep={setCurrentStep}
    badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}
    showDots={false}
    components={{ Badge, Close }}
    disableInteraction
    onRequestClose={close}
    goToStep={goTo}
    // showPrevNextButtons={false}
    navigation={{
      prevButton: currentStepIndex !== 0 ? (
        <CustomPreviousButton onClick={handlePrev} />
      ) : null,
      nextButton: currentStepIndex !== steps.length - 1 ? (
        <CustomNextButton onClick={handleNext} />
      ) : null,
    }}
    onClickHighlighted={(e) => {
      e.stopPropagation()
    }}
    onClickMask={(e)=>{
      e.setIsOpen(true)
    }}
    scrollSmooth={true}
    onClickClose={({setIsOpen,currentStep})=>{
      setStep(0)
      setop(false)
      setIsOpen(false)
      setEndBoard(true)
      setOpen(false)
      setPage(1)
      navigate(`/brands/${brandx}/`, true);
    }}
    disableKeyboardNavigation={['esc']}
  >
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {
        <Context1.Provider
          value={{
            selectedRows,
            setSelectedRows,
            updateAssets,
            setUpdateAssets,
            payForAssets,
            setStep,
            setPayForAssets,
            page,
            setPage,
            open,
            setOpen,
            pending, 
            setPending,
            op,
            setop,
            credits,
            setCredits,
            endBoard,setEndBoard,
            onboard,setOnboard
          }}
        >
  <GoogleOAuthProvider clientId={CLIENT_ID}>
            <>
              <ScrollToTop />
              <Routes>
                <Route>
                  <Route path="/signin" element={<Signin />} />
                  <Route path="/signup" element={<Register />} />
                  <Route path="/forgot-password" element={<Resetpassword />} />

                  <Route
                    path="/user/reset-password"
                    element={<Updatepassword />}
                  />
                  <Route path="/user/verify-email" element={<VerifyEmail />} />
                </Route>

                {/* <Route path="/data" element={<Data />} /> */}

                {/* First Time Show Only */}
                <Route element={<RouteHidden />}>
                  <Route path="/brand-data" element={<Data />} />
                </Route>

                {/* ADMIN */}
                <Route element={<AdminProtectedRoutes />}>
                  <Route path="/admin/brands" element={<AdminDashboard />} />
                  <Route
                    path="/admin/brands/:brandx"
                    element={<AdminBrand />}
                  />
                  <Route
                    path="/admin/brands/:brandx/:assetx"
                    element={<AssetView role="admin" />}
                  />
                  <Route path="/admin/users" element={<UserList />} />
                  <Route
                    path="/admin/support"
                    element={<Support role="admin" />}
                  />
                  <Route path="/admin/:brandx" element={<BrandDetailsPage />} />
                  <Route
                    path="/admin/:brandx/support/:ticketId"
                    element={<TicketPage role="admin" />}
                  />
                </Route>

                <Route element={<WaitlistRouteProtection />}>
                  <Route
                    path="/brands/:brandx/waitlist"
                    element={<Waitlist />}
                  />
                </Route>

                {/* BRANDS */}
                <Route element={<BrandsProtectedRoutes />}>
                  <Route
                    path="/brands/:brandx"
                    exact
                    element={<Dashboard1 />}
                  />
                  <Route
                    path="/brands/:brandx/create"
                    element={
                      <Upload
                        draft={false}
                        dragHighlightColor={dragHighlight}
                        handleDrop={handleDrop}
                      />
                    }
                  />
                  <Route
                    path="/brands/:brandx/:assetx"
                    element={<AssetView role="brand" />}
                  />
                  {/* <Route path="/brands/:brandx/video" element={<OneTimeVideo />} /> */}
                  <Route
                    path="/brands/:brandx/:assetx/updateInfo"
                    element={
                      <Update
                        dragHighlightColor={dragHighlight}
                        handleDrop={handleDrop}
                      />
                    }
                  />
                  <Route
                    path="/brands/:brandx/:assetx/drafts"
                    element={
                      <Drafts
                        dragHighlightColor={dragHighlight}
                        handleDrop={handleDrop}
                      />
                    }
                  />
                  <Route
                    path="/brands/:brandx/collections"
                    element={<Collection />}
                  />
                  {/* <Route
                    path="/brands/:brandx/analytics"
                    element={<Analytics />}
                  /> */}
                  <Route path="/brands/:brandx/support" element={<Support />} />
                  <Route
                    path="/brands/:brandx/support/:ticketId"
                    element={<TicketPage />}
                  />
                </Route>

                <Route path="/teaser" exact element={<TeaserVideo />} />

                <Route
                  path="/restricted"
                  exact
                  element={<MobileRestrictionScreen />}
                />

                <Route element={<RouteNotDefined />}>
                  <Route path="*" />
                </Route>
              </Routes>
            </>
          </GoogleOAuthProvider>
        </Context1.Provider>
      }
    </div>
    </TourProvider>
  );
}

export default App;