import React from "react";
import MyComponent from "./BrandLists";
import "./AdminDashboard.css";

function AdminDashboard() {
  return (
    <div>
      <MyComponent />
    </div>
  );
}

export default AdminDashboard;
