import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ModalSuccess from "../components/ModalSuccess";
import "./verify.css";
import { MdVerified, MdError } from "react-icons/md";
import Loading from "../Loading";

import { HiCheckCircle } from "react-icons/hi";
import { RiCloseCircleFill } from "react-icons/ri";

function VerifyEmail() {
  // console.log("called")
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState();
  const [load, setLoad] = useState(true);
  const [msg, setMsg] = useState("");
  const [called, setCalled] = useState(false);
  const [time, setTime] = useState(5);

  const verifyEmail = () => {
    if (!called) {
      setCalled(true);
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let token = params.get("token");
      let email = params.get("email");
      console.log(token, email);
      axios
        .post("https://api.xrcouture.com/auth/verify-email", {
          email: email,
          verificationToken: token,
        })
        .then((res) => {
          setLoad(false);
          setStatus(res.status);
          setMsg(res.data.msg);
          console.log(res.data.msg);
        })
        .catch((err) => {
          setMsg(err.response.data.msg);
          setStatus(err.response.status);
          setLoad(false);
        });
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  useEffect(() => {
    time > 0 && setInterval(() => setTime(time - 1), 1000);
    if (time === 1) {
      window.location.replace("/signin");
    }
  });

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div>
          {status === 200 ? (
            <div className="waitlist-body-alt d-flex flex-column">
              <div
                className="text-white mb-5"
                style={{
                  fontSize: "2.5rem",
                  fontFamily: "Clash Display SemiBold",
                }}
              >
                {/* Your email is verified! */}
              </div>
              <div
                className="waitlist-msg-alt"
                style={{
                  border: "1px solid white",
                  borderRadius: "0.6rem",
                  color: "white",
                  padding: "3rem",
                }}
              >
                <HiCheckCircle
                  size={100}
                  style={{ marginTop: "-1rem", paddingBottom: "1rem" }}
                />{" "}
                <br /> {`Congratulations, ${msg}`}! <br /> Redirecting to sign
                in page in {time} seconds.
              </div>
            </div>
          ) : (
            <div className="waitlist-body-alt d-flex flex-column">
              <div
                className="text-white mb-5"
                style={{
                  fontSize: "2.5rem",
                  fontFamily: "Clash Display SemiBold",
                }}
              >
                {/* Something went wrong! */}
              </div>
              <div
                className="waitlist-msg-alt"
                style={{
                  border: "1px solid white",
                  borderRadius: "0.6rem",
                  color: "white",
                  padding: "3rem",
                }}
              >
                <RiCloseCircleFill
                  size={100}
                  style={{ marginTop: "-1rem", paddingBottom: "1rem" }}
                />{" "}
                <br /> {`Oops, ${msg}`}! <br /> Redirecting to sign in page in{" "}
                {time} seconds.
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default VerifyEmail;
