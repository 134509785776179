import React, { useContext, useEffect,useState } from "react";
import { Context, Context1 } from "../../../Context";
import {BsCheck, BsCheck2} from 'react-icons/bs'
import shortid from "shortid";
import Modal from "react-bootstrap/Modal";
import "./FileUpload.css";
import { IoArrowBackOutline } from "react-icons/io5";
import { useTour } from "@reactour/tour";

const FileUpload = (props) => {
  // STATES
  const { filesData } = useContext(Context);
  const [selectedfile, SetSelectedFile] = filesData;
  
  const [modalShow,setModalShow] = useState(false)


  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const isValidFileUploaded = (file) => {
    const validExtensions = [
      "png",
      "jpg",
      "jpeg",
      "webp",
      "mp4",
      "mov",
      "pdf",
      "blend",
      "glb",
      "gltf",
      "fbx",
      "obj",
      "usd",
      "c4d",
      "max",
      "mb",
      "unitypackage",
      "dae",
      "dwg",
    ];
    return validExtensions.includes(
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
        file.name
    );
  };

  const isValidFileUploadedAdmin = (file) => {
    const validExtensions = [
      "blend",
      "glb",
      "gltf",
      "fbx",
      "obj",
      "usd",
      "c4d",
      "max",
      "mb",
      "unitypackage",
      "dae",
      "dwg",
    ];
    return validExtensions.includes(
      file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length) ||
        file.name
    );
  };

  const InputChange = (e) => {
    // check the file quant should be less than or equals to 10 *done
    // check that already how many files are uploaded *done

    // console.log(e.target.files.length)

    if (
      e.target.files.length > 10 ||
      selectedfile.length === 10 ||
      e.target.files.length + selectedfile.length > 10
    ) {
      alert("You can only upload upto 10 files!");
      return;
    }

    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        // check file type : e.target.files[i].type
        // check file size : e.target.files[i].size *done

        // if (!isValidFileUploadedAdmin(e.target.files[i])) {
        //   alert(file.name + "This file is not supported")
        //   return;
        // }
        if (!isValidFileUploaded(e.target.files[i])) {
          alert(file.name + "This file is not supported");
          return;
        }

        if (e.target.files[i].size > 20971520) {
          alert("Please not this file: " + file.name);
          return;
        }

        SetSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              id: shortid.generate(),
              filename: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileimage: reader.result,
              datetime:
                e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
              filesize: filesizes(e.target.files[i].size),
              fileData: e.target.files[i],
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }

    props.handleDropFun()
  }

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };

  const {setIsOpen} = useTour()
  const {setStep,setOpen,open} = useContext(Context1)
  const {op} = useContext(Context1)
  useEffect(()=>{
    // setStep(6)
    setIsOpen(op)
    console.log("file upload page in components")
  },[])

  const {notes,setNotes} = useContext(Context)

  // useEffect(() => {
  //   const parentDiv = document.querySelector('#upload-title-height2');

  //   const firstDivHeight = window.getComputedStyle(parentDiv).height;
  //   props.setCloseButtonHeightFun(firstDivHeight)
  // }, [])

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center text-white">
        <div className="upload-title fs-xl" id="upload-title-height1">
          Create Asset
        </div>
      </div>
      <div className="d-flex align-items-center mt-4">
          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #fff",
                background: "#02012d",
                color: "#000",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
                cursor:"pointer"
              }}
              onClick={()=>props.backButton(1)}
            >
              <div ><BsCheck2 color="white" size={"1.5em"} /></div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold", marginRight:"7px" }}
            >
              Define
            </div>
          </div>

          <hr
            style={{
              color: "white",
              background: "white",
              width: "3em",
              height: "0.1em",
              opacity:1
            }}
          />

          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #282760",
                background: "#fff",
                color: "#000",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
              }}
            >
              <div>2</div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold", marginRight:"7px" }}
            >
              Design
            </div>
          </div>

          <hr
            style={{
              color: "white",
              background: "white",
              width: "3em",
              height: "0.1em",
            }}
          />

          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #c4c4c4",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
              }}
            >
              <div>3</div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold" }}
            >
              Publish
            </div>
          </div>
      </div>
      <p className="fs-xs text-white mt-2" style={{fontFamily:"Clash Display Light",opacity:"0.7"}}>Tell us more about your design by uploading multiple files in any format, such as images, videos, 3D models or PDFs.</p>

      <div className="fileupload-view upload-contents">
        <div className="row justify-content-center m-0">
          <div className="col-md-12" data-tour='upload-files'>
            <div className="card">
              <div className="card-body">
                <div className="kb-data-box">
                  <div className="kb-modal-data-title">
                    <div className="kb-data-title">
                      {/* <h6>Multiple File Upload With Preview</h6> */}
                    </div>
                  </div>
                  {/* <form onSubmit={FileUploadSubmit}> */}
                  <form>
                    <div className="kb-file-upload">
                      <div className="file-upload-box d-flex flex-column" style={{border: `0.1em dotted ${props.dragHighlightColor}`}}>
                        <input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />
                        <span className='uc-items-title fs-xs text-white'>Drag and drop or <span className="file-link fs-xs uc-items-link">Choose your files</span></span>
                        <div className="uc-items-subtitle fs-xs">
                          Max file size is 20 MB
                        </div>
                      </div>
                    </div>
                    {/* <p className="text-right position-absolute"  style={{cursor:"pointer",float:"right",color:"rgb(78, 75, 352)",textDecoration:"underline", right:"15px"}} onClick={()=>setModalShow(!modalShow)}>Add Note?</p> */}
                    <div className="kb-attach-box mt-5 mb-3">
                      {selectedfile.map((data, index) => {
                        const {
                          id,
                          filename,
                          filetype,
                          fileimage,
                          datetime,
                          filesize,
                        } = data;
                        return (
                          <div className="file-atc-box" key={id}>
                            {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                              <div className="file-image">
                                {" "}
                                <img src={fileimage} alt="" />
                              </div>
                            ) : (
                              <div className="file-image">
                                <i className="far fa-file-alt"></i>
                              </div>
                            )}
                            <div className="file-detail text-white">
                              <div
                                className="fs-sm"
                                style={{ fontFamily: "Clash Display Medium" }}
                              >
                                {filename}
                              </div>
                              <p></p>
                              <p>
                                <span
                                  className="fs-xxs"
                                  style={{ fontFamily: "Clash Display Light" }}
                                >
                                  Size : {filesize}
                                </span>
                                <span
                                  className="fs-xxs"
                                  style={{
                                    fontFamily: "Clash Display Light",
                                    marginLeft: "1em",
                                  }}
                                >
                                  Modified Time : {datetime}
                                </span>
                              </p>
                              <div className="file-actions fs-xxs">
                                <button
                                  type="button"
                                  className="file-action-btn fs-xxs"
                                  style={{ fontFamily: "Clash Display Light" }}
                                  onClick={() => DeleteSelectFile(id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className="kb-buttons-box">
                      <button type="submit" className="btn btn-primary form-submit">Submit</button>
                    </div> */}
                  </form>
                 
                </div>
        <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        style={{background:"rgba(0,0,0,.6)"}}
        onHide={() => {
          setModalShow(false);
        }}
        
      >
        <Modal.Header closeButton closeVariant="white" style={{background:"#02012d"}}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "Clash Display Bold",color:"goldenrod !important" }}
          >
           Tell us more about your design
          </Modal.Title>
        </Modal.Header>

        <Modal.Body color="white" style={{color:"white" , fontFamily:"Clash Display Medium",background:"#02012d",paddingTop:"20px",paddingBottom:"20px"}}>
          <div className="d-flex flex-column justify-content-between" style={{fontSize:"1em"}}>
            <div className="" style={{width:"100%", marginBottom:"15px",paddingBottom:"10px"}} >
            <textarea rows={8} placeholder="Add your note here........" style={{padding:"10px",border:"none",width:"100%", background:"#282760",color:"white",borderRadius:"5px"}} value={notes} onChange={(e)=>setNotes(e.target.value)}/>
            {/* <button style={{float:"right",padding:"10px 20px",fontFamily:"Clash Display Bold", color:"white",borderRadius:"5px",border:"none",background:"rgb(78, 75, 222)"}} className="box-shadow">Submit</button> */}
            </div>
            <div >             
          </div>
          </div>

        </Modal.Body>
      </Modal>
              </div>
              
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
