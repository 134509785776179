// Define action types
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const START_CREATING_ASSET = "START_CREATING_ASSET";
export const STOP_CREATING_ASSET = "STOP_CREATING_ASSET";

export const UNSAVED_CHANGES = 'UNSAVED_CHANGES'
export const SAVED_CHANGES = 'SAVED_CHANGES'

// Define action creators
export function showModal() {
  return { type: SHOW_MODAL };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

export function startCreatingAsset() {
  return { type: START_CREATING_ASSET };
}

export function stopCreatingAsset() {
  return { type: STOP_CREATING_ASSET };
}

export function unsavedChanges() {
  return { type: UNSAVED_CHANGES };
}

export function savedChanges() {
  return { type: SAVED_CHANGES };
}
