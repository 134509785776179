import React from "react";

const MobileRestrictionScreen = () => {
  return (
    <div
      className="otv-app-container flex-column"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "3em",
      }}
    >
      <div style={{ marginTop: "-3rem" }}>
        <img
          src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/XR+WRBL.png"
          style={{ height: "auto", width: "12rem" }}
          alt="logo"
          className="logo-sidebar"
        />
      </div>

      <div
        className="text-center"
        style={{
          fontFamily: "Clash Display SemiBold",
          padding: "0.8em",
          wordSpacing: "0.1em",
          fontSize: "1rem",
        }}
      >
        Please visit from a desktop browser for the best experience.
      </div>
      <div
        className="text-center mt-3"
        style={{
          fontFamily: "Clash Display Medium",
          border: "1px solid white",
          borderRadius: "0.6em",
          padding: "1.5em",
          wordSpacing: "0.1em",
          opacity: "0.9",
          fontSize: "0.92rem",
        }}
      >
        Sorry for the inconvenience, but our website is currently optimized for
        desktop viewing only. We are working on making our website
        mobile-friendly and appreciate your patience.
      </div>
    </div>
  );
};

export default MobileRestrictionScreen;
