export const STOP_PROP_TAG = "allowRowEvents";
export var Direction;
(function (Direction) {
  Direction["LTR"] = "ltr";
  Direction["RTL"] = "rtl";
  Direction["AUTO"] = "auto";
})(Direction || (Direction = {}));
export var Alignment;
(function (Alignment) {
  Alignment["LEFT"] = "left";
  Alignment["RIGHT"] = "right";
  Alignment["CENTER"] = "center";
})(Alignment || (Alignment = {}));
export var Media;
(function (Media) {
  Media["SM"] = "sm";
  Media["MD"] = "md";
  Media["LG"] = "lg";
})(Media || (Media = {}));
