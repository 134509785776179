import * as React from "react";
import styled from "styled-components";
import { CellBase } from "./Cell";
import Checkbox from "./Checkbox";
const ColumnStyle = styled(CellBase)`
  flex: 0 0 48px;
  justify-content: center;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  font-size: unset;
`;
function ColumnCheckbox({
  headCell = true,
  rowData,
  keyField,
  allSelected,
  mergeSelections,
  selectedRows,
  selectableRowsComponent,
  selectableRowsComponentProps,
  selectableRowDisabled,
  onSelectAllRows,
}) {
  const indeterminate = selectedRows.length > 0 && !allSelected;
  const rows = selectableRowDisabled
    ? rowData.filter((row) => !selectableRowDisabled(row))
    : rowData;
  const isDisabled = rows.length === 0;
  // The row count should subtract rows that are disabled
  const rowCount = Math.min(rowData.length, rows.length);
  const handleSelectAll = () => {
    onSelectAllRows({
      type: "SELECT_ALL_ROWS",
      rows,
      rowCount,
      mergeSelections,
      keyField,
    });
  };
  return (
    <ColumnStyle className="rdt_TableCol" headCell={headCell} noPadding>
      <Checkbox
        name="select-all-rows"
        component={selectableRowsComponent}
        componentOptions={selectableRowsComponentProps}
        onClick={handleSelectAll}
        checked={allSelected}
        indeterminate={indeterminate}
        disabled={isDisabled}
      />
    </ColumnStyle>
  );
}
export default ColumnCheckbox;
