import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import OneTimeVideo from "./OneTimeVideo";
import { IoAddCircleOutline } from "react-icons/io5";

const Collection = () => {
  const { brandx } = useParams();

  useEffect(() => {
    document.getElementById("home").classList.remove("active");
  }, []);

  return (
    <>
      {/* <div
        className="row container-dashboard m-0"
        id="collections-header-banner"
      >
        <div className="col-6">
          <h1 style={{ color: "white", fontFamily: "Clash Display Bold" }}>
            Interoperable Wearables{" "}
          </h1>
        </div>
        <div className="col-6 p-0 row d-flex align-items-center justify-content-end">
          <div
            className="col-4 p-0 h-100 m-0 d-flex justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <div
              className="icons-dashboard btn-create box-shadow"
              style={{
                width: "unset",
                color: "white",
                fontFamily: "Clash Display Medium",
                background: "#2a0fd3",
                border: "1px solid transparent",
                padding: "1rem 1.5rem",
              }}
            >
              <Link
                to={"https://calendly.com/xrcouture/wrbl"}
                target="_blank"
                className="text-white "
              >
                Book a demo
              </Link>
            </div>
          </div>
        </div>
        <p className="fs-sm text-white" style={{fontFamily:"Clash Display Light",opacity:"0.7"}}>You can track and analyze their assets. It provides insights into asset and helps with decision-making.</p>
      </div> */}
      <div className="container-dashboard">
      <div className="d-flex flex-row align-items-start">
        <div
          className="mr-auto d-flex flex-column  p-0 m-0 mb-0 text-header-top"
          style={{ marginRight: "auto !importaant" }}
        >
          <h3
            style={{
              color: "white",
              fontFamily: "Clash Display SemiBold",
              width: "fit-content",
            }}
            className="header-text-overall fs-lg"
          >
            Interoperable Wearables
          </h3>
        <p className="fs-xs mt-1 text-white col-md-9 col-sm-9" style={{fontFamily:"Clash Display Medium",opacity:.5}}>
          {/* With WRBL, you can create and customize your own wearable 3D assets that can be used across different metaverse platforms and online games. You can design clothing, accessories, jewelry, tattoos and more for your avatars. You can also export your wearables to other apps and games that support them. WRBL’s interoperable wearables let you express yourself and have fun in the virtual world. Watch the video for more info. */}
          Create and customize 3D wearables for use across metaverse platforms and games with WRBL. Express yourself in the virtual world. Watch the video for more info.
          </p>
        </div>
        <div
              className="icons-dashboard btn-create box-shadow fs-sm"
              style={{
                width: "unset",
                color: "white",
                fontFamily: "Clash Display Medium",
                background: "#2a0fd3",
                border: "1px solid transparent",
                padding: "1rem 1.5rem",
              }}
            >
              <Link
                to={"https://calendly.com/xrcouture/wrbl"}
                target="_blank"
                className="text-white "
              >
                Book a demo
              </Link>
            </div>
      </div>
      </div>

      <OneTimeVideo />
    </>
  );
};

export default Collection;
