import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import logo from "../assets/XR_R3.png";
import { FormGroup, Input, Label } from "reactstrap";
import * as Yup from "yup";
import "./register.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate, redirect } from "react-router";
import axios from "axios";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { error } from "toastr";
import { BeatLoader, PuffLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import profile from "../assets/profile.png";
import Modal from "react-bootstrap/Modal";

function Data() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const phoneRegExp =
    /^((\+\d{1,4}[\s-]*)|(\(\d{2,3}\)[\s-]*)|(\d{2,4})[\s-]*)*?\d{3,4}?[\s-]*\d{3,4}?$/;
  const [name, setName] = useState("Unnamed");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const SignupSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    userName: Yup.string().required("userName is required"),
    type: Yup.string().required("type is required"),
    countryIndividual: Yup.string().when("type", {
      is: "Individual",
      then: Yup.string().required("Please select the country"),
    }),
    instagram: Yup.string().when("type", {
      is: "Individual",
      then: Yup.string()
        .test(
          "atLeastOneRequired",
          "At least one field is required",
          function (value) {
            return !!value || !!this.parent.twitter || !!this.parent.linkedin;
          }
        )
        .test("ValidLink", "Please enter a valid link", function (value) {
          return (
            (!!value && !!value.includes("instagram.com/")) ||
            (!!this.parent.twitter &&
              !!this.parent.twitter.includes("twitter.com/")) ||
            (!!this.parent.linkedin &&
              !!this.parent.linkedin.includes("linkedin"))
          );
        }),
    }),
    twitter: Yup.string(),
    linkedin: Yup.string(),
    businessName: Yup.string().when("type", {
      is: "business",
      then: Yup.string().required("Business Name is required"),
    }),
    size: Yup.string().when("type", {
      is: "business",
      then: Yup.string()
        .required("Size is required")
        .oneOf(["1-5", "5-20", "20-1000"], "Invalid size"),
    }),
    addressLine1: Yup.string().when("type", {
      is: "business",
      then: Yup.string().required("Address Line 1 is required"),
    }),
    addressLine2: Yup.string(),
    city: Yup.string().when("type", {
      is: "business",
      then: Yup.string().required("City is required"),
    }),
    countryBusiness: Yup.string().when("type", {
      is: "business",
      then: Yup.string().required("Please select the country"),
    }),
    pin: Yup.string().when("type", {
      is: "business",
      then: Yup.string().required("Pin is required"),
    }),
    phone: Yup.string().when("type", {
      is: "business",
      then: Yup.string()
        .required("Phone number is required")
        .matches(phoneRegExp, "Phone number is not valid"),
    }),
    websiteBusiness: Yup.string().when("type", {
      is: "business",
      then: Yup.string().required("Website is required"),
    }),
    checkbox: Yup.boolean().required("Agree terms and conditions"),
  });

  return (
    <div className="main-signup" style={{ overflowY: "scroll" }}>
      <img
        src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/wrbl.png"
        alt=""
        className="logo"
      />
      <Row
        className="cont-row"
        style={{ overflowY: "scroll", marginTop: "50px", marginBottom: "70px" }}
      >
        <Col
          className="col-md-12 cont cont-data "
          style={{ overflowY: "scroll" }}
        >
          <div
            className="box box-data w-100"
            style={{ maxWidth: "80% !important" }}
          >
            <div className="box-header">
              <h1 className="box-header-text">Tell us a bit about yourself</h1>
              <hr></hr>

              {/* onChange={e=>handleChange(e)} */}
            </div>
            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                userName: "",
                type: "Individual",
                countryIndividual: "",
                websiteIndividual: "",
                instagram: "",
                twitter: "",
                linkedin: "",
                businessName: "",
                size: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                countryBusiness: "",
                pin: "",
                phone: "",
                websiteBusiness: "",
                checkbox: false,
              }}
              validationSchema={SignupSchema}
              onSubmit={async (values, actions) => {
                // try {
                //   const validData = SignupSchema.validateSync(values);
                //   console.log(validData)
                // } catch (err) {
                //   console.log(err.name); // ValidationError
                //   console.log(err.errors); // ['name is a required field', 'age must be a positive number']
                // }
                setLoading(true);
                console.log(values);
                await axios
                  .post(
                    "https://api.xrcouture.com/auth/form",
                    {
                      brandName: values.userName,
                      website:
                        values.type === "Individual"
                          ? values.websiteIndividual
                          : values.websiteBusiness,
                      logo: "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133352152-stock-illustration-default-placeholder-profile-icon.jpg",
                      firstName: values.firstname,
                      lastName: values.lastname,
                      type: values.type,
                      country:
                        values.type === "Individual"
                          ? values.countryIndividual
                          : values.countryBusiness,
                      instagram: values.instagram,
                      twitter: values.twitter,
                      linkedin: values.linkedin,
                      portfolio: values.websiteIndividual,
                      companySize: values.size,
                      addressLine1: values.addressLine1,
                      addressLine2: values.addressLine2,
                      businessName: values.businessName,
                      city: values.city,
                      pincode: values.pin,
                      phoneNumber: values.phone,
                    },
                    { withCredentials: true }
                  )
                  .then((res) => {
                    if (res.data.signUpCompleted === true) {
                      localStorage.setItem("brand", values.userName);
                      navigate(`/brands/${values.userName}`);
                    } else {
                      actions.resetForm();
                      toast.error("Oops, Something went wrong...");
                    }
                  })
                  .catch((err) => {
                    actions.resetForm();
                    toast.error("!Oops, Something went wrong...");
                  });
                // console.log(err))
                setTimeout(() => {
                  setLoading(false);
                }, 1500);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form>
                  {/* <Row>
                    <Col sm={12} md={6}>
                    <div className="asset-profile-icon header-logo d-flex align-items-center" style={{backgroundImage:`url(https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133352152-stock-illustration-default-placeholder-profile-icon.jpg)`,backgroundSize: "contain",backgroundPosition:"center",backgroundRepeat:"no-repeat",
                        height: "200px",width:"200px",borderRadius:"25px"}}>
                    <input type="file" required className="profile-input position-absolute"  disabled={loading} />
                    <div className={`h-100 w-100 d-flex align-items-center justify-content-center ${!loading ? 'edit-icon-profile' : 'loading-icon-profile'}`}>
                    {loading ? <PuffLoader color="green" /> :  <FaRegEdit className="" color="white"  size={30}/>}
                    </div>
                    </div>
                    </Col>
                    <Col md={6} sm={12}>
                      
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm={12} md={6}>
                      <Label for="firstname">First Name*</Label>
                      <Field type="text" name="firstname" />
                      <ErrorMessage
                        name="firstname"
                        className="error"
                        component="div"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <Label for="lastname">Last Name*</Label>
                      <Field type="text" name="lastname" />
                      <ErrorMessage
                        name="lastname"
                        className="error"
                        component="div"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Label for="userName">UserName*</Label>
                      <Field type="text" name="userName" />
                      <ErrorMessage
                        name="userName"
                        className="error"
                        component="div"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <Label for="type">Are you a</Label>
                      <Field as="select" name="type">
                        <option value="Individual" selected>
                          Individual/Creator
                        </option>
                        <option value="business">Business</option>
                      </Field>
                      <ErrorMessage
                        name="type"
                        className="error"
                        component="div"
                      />
                    </Col>
                  </Row>
                  {values.type === "Individual" && (
                    <>
                      <Row>
                        <Col sm={12} md={6}>
                          <Label for="countryIndividual">Country*</Label>
                          <CountryDropdown
                            name="countryIndividual"
                            value={values.countryIndividual}
                            onChange={(val) =>
                              setFieldValue("countryIndividual", val)
                            }
                            // value={country}
                            // onChange={(val) => selectCountry(val)}
                          />
                          <ErrorMessage
                            name="countryIndividual"
                            className="error"
                            component="div"
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Label for="websiteIndividual">Website</Label>
                          <Field type="text" name="websiteIndividual" />
                          <ErrorMessage
                            name="websiteIndividual"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label for="brandlink">Socials*</Label>
                        <ErrorMessage
                          name="instagram"
                          className="error"
                          component="span"
                        />
                        <Col sm={12} md={4}>
                          <Field
                            type="text"
                            name="instagram"
                            placeholder="Instagram"
                          />
                        </Col>
                        <Col sm={12} md={4}>
                          <Field
                            type="text"
                            name="twitter"
                            placeholder="Twitter"
                          />
                          <ErrorMessage
                            name="twitter"
                            className="error"
                            component="div"
                          />
                        </Col>
                        <Col sm={12} md={4}>
                          <Field
                            type="text"
                            name="linkedin"
                            placeholder="Linkedin"
                          />
                          <ErrorMessage
                            name="linkedin"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {values.type === "business" && (
                    <>
                      <Row>
                        <Col sm={12} md={6}>
                          <Label for="businessName">Business Name*</Label>
                          <Field type="text" name="businessName" />
                          <ErrorMessage
                            name="businessName"
                            className="error"
                            component="div"
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Label for="size">Size*</Label>
                          <Field as="select" name="size">
                            <option value="" selected disabled>
                              Select Your Organisation Size
                            </option>
                            <option value="1-5">1-10 Employees</option>
                            <option value="5-20">10-100 Employees</option>
                            <option value="20-1000">100+ Employees</option>
                          </Field>
                          <ErrorMessage
                            name="size"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Label for="addressLine1">Address Line 1*</Label>
                          <Field type="text" name="addressLine1" />
                          <ErrorMessage
                            name="addressLine1"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Label for="addressLine2">Address Line 2</Label>
                          <Field type="text" name="addressLine2" />
                          <ErrorMessage
                            name="addressLine2"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <Label for="city">City*</Label>
                          <Field type="text" name="city" />
                          <ErrorMessage
                            name="city"
                            className="error"
                            component="div"
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Label for="countryBusiness">Country*</Label>
                          <CountryDropdown
                            name="countryBusiness"
                            value={values.countryBusiness}
                            onChange={(val) =>
                              setFieldValue("countryBusiness", val)
                            }
                            // value={country}
                            // onChange={(val) => selectCountry(val)}
                          />
                          <ErrorMessage
                            name="countryBusiness"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <Label for="pin">Pin*</Label>
                          <Field type="text" name="pin" />
                          <ErrorMessage
                            name="pin"
                            className="error"
                            component="div"
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Label for="phone">Phone*</Label>
                          <Field type="text" name="phone" />
                          <ErrorMessage
                            name="phone"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Label for="websiteBusiness">Website*</Label>
                          <Field type="text" name="websiteBusiness" />
                          <ErrorMessage
                            name="websiteBusiness"
                            className="error"
                            component="div"
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <label>
                    <Field
                      type="checkbox"
                      className="form-check-input mr-2"
                      required
                      name="checkbox"
                    />
                    <span className="ml-2 position-relative">
                      I accept the &nbsp;
                      <a
                        onClick={() => setIsModalOpen(true)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        Privacy Policy.
                      </a>
                    </span>
                  </label>
                  <div className="button-submit mt-2">
                    <button
                      type="submit"
                      className={`button ${
                        !values.checkbox ? "btn btn-secondary" : ""
                      }`}
                      disabled={!values.checkbox || loading}
                    >
                      {loading ? (
                        <BeatLoader size={10} color="white" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <ToastContainer />
          </div>
        </Col>
      </Row>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#02012d" }}
          closeVariant="white"
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "Clash Display Bold" }}
          >
            Privacy Policy
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "#02012d" }}>
          <li className="term-privacy">
            At NFT XRCOUTURE Pvt Ltd, we are committed to protecting the privacy
            and security of our users' personal and confidential information.
            This Privacy Policy outlines the type of information we collect, how
            we use it, and how we protect it.
          </li>
          <li className="term-privacy">
            Information We Collect: We may collect personal information such as
            your name, email address, and contact information when you sign up
            for our services, create an account, or contact us for support. We
            may also collect information about your device and usage of our
            platform or website through cookies, log files, and other tracking
            technologies.
          </li>
          <li className="term-privacy">
            How We Use Your Information: We use the information we collect to
            provide our services, improve our platform or website, and
            communicate with you about our products and services. We may also
            use your information for marketing and promotional purposes, but you
            can opt-out of such communications at any time.
          </li>
          <li className="term-privacy">
            We may share your information with third-party service providers to
            help us provide our services or to comply with legal obligations. We
            will only share your information with such third parties as
            necessary and subject to appropriate safeguards.
          </li>
          <li className="term-privacy">
            How We Protect Your Information: We take appropriate measures to
            protect your personal information from unauthorized access,
            disclosure, or misuse. We use industry-standard encryption,
            firewalls, and other security measures to protect your information.
          </li>
          <li className="term-privacy">
            We will only retain your personal information for as long as
            necessary to fulfill the purposes for which it was collected or as
            required by law.
          </li>
          <li className="term-privacy">
            Your Rights: You have the right to access, correct, or delete your
            personal information at any time. You can also request that we limit
            the processing of your personal information or object to its
            processing. To exercise your rights, please contact us at
            wrbl@xrcouture.com.
          </li>
          <li className="term-privacy">
            Updates to Privacy Policy: We may update this Privacy Policy from
            time to time. We will notify you of any material changes by posting
            a notice on our website or platform or by sending you an email. Your
            continued use of our services after such changes constitutes your
            acceptance of the updated Privacy Policy.
          </li>
          <li className="term-privacy">
            Contact Us: If you have any questions or concerns about our Privacy
            Policy, please contact us at wrbl@xrcouture.com.
          </li>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Data;
