import React, { useState, useEffect,useRef, useContext } from "react";
import "./Dashboard.css";
import { IoAddCircleOutline } from "react-icons/io5";
import Recent from "../Recents/Recent";
import { useNavigate, useParams } from "react-router-dom";
import { TourProvider, useTour } from "@reactour/tour";
import { Page, Paragraph, theme } from '@gilbarbara/components';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from "@novu/notification-center";
import {RiCopperCoinFill} from 'react-icons/ri'
import { AiOutlineCloseCircle, AiOutlineDownCircle } from "react-icons/ai";
import { CiCircleChevDown } from "react-icons/ci";
import { useMount } from 'react-use';
import Joyride, { CallBackProps } from 'react-joyride';
import {useAppContext} from '../../Context'
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Context1 } from "../../../Context";



function Dashboard() {
  const { setIsOpen } = useTour();

  const [modal, setModal] = useState(false);


  const coin = "https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/xrc_coin.png"
  const toggle = () => setModal(!modal);


  const [filterText, setFilterText] = React.useState("");
  // const [open, setOpen] = useState(false);
  const [tourM, setTourM] = useState("");
  const [tourD, setTourD] = useState("");
  const [startY, setStartY] = useState(null);
  const [announcementVisible, setAnnouncementVisible] = useState(false);
  const [step, setStep] = useState(0);
  const {open,setOpen,pending,credits} = useContext(Context1)
  const disableBody = (target) => disableBodyScroll(target);

  const setCurrentStep = (step) => {
    console.log(window.location.pathname)
    // if(step === 1 && window.location.pathname===`/brands/${brandx}` ){
    //   navigate(`/brands/${brandx}/create/`, true);
    // }
    // switch (step) {
    //   case 0:
    //     navigate(`/brands/${brandx}`, true);
    //     break;
    //   case 1 :
    //     navigate(`/brands/${brandx}/create/`, true);
    //     break;
    //   default:
    //     break;
    // }
    setStep(step);
  };

  const enableBody = (target) => enableBodyScroll(target);

  const navigate = useNavigate();


  const handleMouseDown = (e) => {
    setStartY(e.clientY);
  };

  const handleMouseMove = (e) => {
    if (startY && e.clientY - startY > 100) {
      setAnnouncementVisible(true);
    }
  };

  const handleMouseUp = () => {
    setStartY(null);
  };

  const [asset, setAsset] = useState({
    "Under Review": 0,
    "Pending payment": 0,
    "In Progress": 0,
    Completed: 0,
    Draft: 0,
    totalCount: 0,
  });
  const [msg, setMsg] = useState("")
  const [bar,setBar] = useState(0)
  const fetchAsset = async () => {
    await axios
      .get("http://api.xrcouture.com/admin/getAssets")
      .then((res) => {
        setData(res.data.assets);
        res.data.assets.filter((i) => {
          if (i.brand === brandx) {
            setAsset({
              ...asset,
              "Under Review": i["Under Review"] ? i["Under Review"]: 0,
              "Pending payment": i["Pending payment"] ? i["Pending payment"]: 0,
              totalCount: i["totalCount"] ? i["totalCount"]: 0,
              "In Progress": i["In Progress"] ? i["In Progress"]: 0,
              Completed: i["Completed"] ? i["Completed"]: 0,
              Draft: i["Draft"] ? i["Draft"]: 0,
            });
          }
        });
      })
      .catch((e) => console.log(e));
     
  };
  useEffect(()=>{
    fetchAsset()
    
  },[])
  useEffect(()=>{
    console.log(asset)
    if(asset['Completed']<=1){
      if(asset['Completed']>0){
        setMsg("You first asset is ready to download. Please download your 3D wearables.")
        setBar(4)
      }else if(asset['In Progress']>0){
        setMsg("You first asset is in progress. Please wait for the 3D wearables to be ready.")
        setBar(3)
      }else if(asset['Pending payment']>0){
        setMsg("Your first asset is under review. Please wait for the review to be completed.")
        setBar(2)
      }else if(asset['Draft']>0){
        setMsg("You first asset is in draft. Please complete the process and publish to get the 3D wearables.")
        setBar(1)
      }
      console.warn(msg)
    }
    else if(asset['totalCount']<=3){
      setMsg("You have more than one asset")
      if(asset['Completed']===2){
        setBar(1)
      }else if(asset['Completed']===3){
        setBar(2)
      }else{
        setBar(0)
      }
    }else{
      setMsg("You have more than three assets")
      console.warn(msg)
    }
    console.log(msg)
    console.warn(msg,bar,asset)
  },[asset])

  const ref = useRef();
  const height = ref.current ? ref.current.clientHeight : 0;
  // useEffect(()=>{
  //   console.log(pending)
  //   if(pending!==true){
  //     setIsOpen(true)
  //   }else{
  //     setIsOpen(false)
  //   }
  // },[pending])
  const [modalShow,setModalShow] = useState(false)
  useEffect(() => {
    document.getElementById("home").classList.add("active");
    if (window.screen.width <= 786) {
      setTourM("first-step");
    } else {
      setTourD("first-step");
    }
  }, []);
  const [openA,setOpenA] = useState(true)

  const handleAnnounce = ()=> {
    setOpenA(!openA)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".notification")) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);
  const { brandx } = useParams();

  const handleCreate = () => {
    navigate(`/brands/${brandx}/create`);
  };
  const DEFAULT_NOTIFICATION = {
    image:
      "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png",
    message: "Notification one.",
    detailPage: "/events",
    receivedTime: "12h ago",
  };
  const [data, setData] = useState([DEFAULT_NOTIFICATION]);
  const logo =
    "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png";

  return (
    <div>


      
      {/* <button onClick={()=> setIsOpen(true)}>Take a tour</button> */}
      {asset['Completed']<1  && (
      <div className={`d-flex flex-row p-4 align-items-center justify-content-between position-relative box-announcement ${!openA?"opened":""} `} ref={ref}  style={{background:"rgb(2 1 45 / 39%)"}}>
      <div className="d-flex align-items-sm-start flex-column flex-md-row align-items-md-center" style={{flexGrow:1,marginLeft:"15px"}}>
      <h6 className="text-white  p-0 m-0 fs-xs" style={{fontFamily:"Clash Display Medium",textAlign:";left"}}>🎉 Earn $100 in credits by creating your first asset.</h6>
      <div className="d-flex flex-row justify-content-center align-items-center" style={{width:"60%"}}>
        
        <div className="bar-1 draft-bar" style={{background:bar >=1 ? "green" : "transparent", border:bar >= 1 ? "1px solid green" : "1px solid white"}}>
          <p className="text">Create</p>
        </div>
        <div className="bar-1 under-review-bar" style={{background:bar >=2 ? "green" : "transparent", border:bar >= 2 ? "1px solid green" : "1px solid white"}}>
          <p className="text">Publish</p>
        </div>
        <div className="bar-1 in-progress-bar" style={{background:bar >=3 ? "green" : "transparent", border:bar >= 3 ? "1px solid green" : "1px solid white"}}>
          <p className="text">Develop</p>
        </div>
        <div className="bar-1 completed-bar" style={{background:bar >=4 ? "green" : "transparent", border:bar >= 4 ? "1px solid green" : "1px solid white"}}>
          <p className="text">Download</p>
        </div>
        <div className="">
        <h6 className="text-white m-0 fs-xs" style={{marginLeft:"5px",paddingLeft:"5px"}}>
          {bar}/4
        </h6>
        </div>
        </div>
      </div>
      <div>
      <AiOutlineCloseCircle color="white"  size={"1.5em"} onClick={()=>setOpenA(!openA)} style={{cursor:"pointer"}}/>
      </div>
      </div>
      )}
{/* 
      {
        (asset['Completed']>=1 && asset['totalCount'] < 4) && (
        <div className={`d-flex flex-row p-4 align-items-center justify-content-between position-relative box-announcement ${!openA?"opened":""} `} ref={ref}  style={{background:"rgb(2 1 45 / 39%)"}}>
        <div className="d-flex align-items-sm-start flex-column flex-md-row align-items-md-center" style={{flexGrow:1,marginLeft:"15px"}}>
        <h6 className="text-white  p-0 m-0 fs-xs" style={{fontFamily:"Clash Display Medium",textAlign:";left"}}>🎉 Complete 2 more asssets to unlock 100 credits.</h6>
        <div className="d-flex flex-row justify-content-center align-items-center" style={{width:"60%"}}>
          
          <div className="bar-1 draft-bar" style={{background:bar >=1 ? "green" : "transparent", border:bar >= 1 ? "1px solid green" : "1px solid white"}}>
            <p className="text">Asset 2</p>
          </div>
          <div className="bar-1 under-review-bar" style={{background:bar >=2 ? "green" : "transparent", border:bar >= 2 ? "1px solid green" : "1px solid white"}}>
            <p className="text">Asset 3</p>
          </div>
          <div className="">
          <h6 className="text-white m-0 fs-xs" style={{marginLeft:"5px",paddingLeft:"5px"}}>
            {bar}/2
          </h6>
          </div>
          </div>
        </div>
        <div>
        <AiOutlineCloseCircle color="white"  size={"1.5em"} onClick={()=>setOpenA(!openA)} style={{cursor:"pointer"}}/>
        </div>
        </div>
        )
      } */}

      <div className="container-dashboard">
      <div className="d-flex flex-row align-items-start">
        <div
          className="mr-auto d-flex flex-column  p-0 m-0 mb-0 text-header-top"
          style={{ marginRight: "auto !importaant" }}
        >
          <h3
            style={{
              color: "white",
              fontFamily: "Clash Display SemiBold",
              width: "fit-content",
            }}
            className="header-text-overall fs-lg"
            
          >
            Manage 3D Assets
          </h3>
        <p className="fs-xs mt-1 text-white col-md-8 col-sm-8" style={{fontFamily:"Clash Display Medium",opacity:.5}}>
        Create stunning 3D assets with WRBL’s powerful tools. Design, edit, and share with ease. Unleash your creativity with WRBL!
</p>
        </div>
        <div
          className=" p-0 d-md-none  h-auto m-0 d-flex justify-content-center "
          style={{ cursor: "pointer" }}
          onClick={handleCreate}
        >
          <div
            className={`icons-dashboard ${tourM}  btn-create box-shadow`}
            style={{
              width: "unset",
              color: "white",
              fontFamily: "Clash Display Medium",
              background: "#2a0fd3",
              border: "1px solid transparent",
            }}
            // data-tour='paragraph'
          >
            <IoAddCircleOutline size={25} color="white" />
          </div>
        </div>
        <div
          className=" d-none d-md-block  p-0 h-100 m-0 d-flex justify-content-center"
          style={{ cursor: "pointer" }}
          onClick={handleCreate}
        >
          <div
            className={`icons-dashboard ${tourD} btn-create box-shadow ` }
            style={{
              width: "max-content",
              color: "white",
              fontFamily: "Clash Display Medium",
              background: "#2a0fd3",
              border: "1px solid transparent",
            }}
            data-tour='paragraph'
          >
            Create 3d Asset
            <IoAddCircleOutline size={25} color="white" />
          </div>
        </div>
        <div
          className="h-auto d-flex justify-content-center "
          style={{ marginLeft: "20px" }}
          data-tour='notification'
        >
          <div className="icons-dashboard second-step" style={{zIndex:2}}>
            <NovuProvider
              subscriberId={localStorage.getItem("brand")}
              applicationIdentifier={"fFvjvHFwBD3F"}
            >
              <PopoverNotificationCenter>
                {({ unseenCount, unseenBadgeColor }) => (
                  <>
                    {console.log(unseenCount)}
                    <NotificationBell unseenCount={unseenCount} size={"1em"} />
                    <div class="number">{unseenCount}</div>
                  </>
                )}
              </PopoverNotificationCenter>
            </NovuProvider>
          </div>

        </div>

        <div
          className="h-auto d-flex justify-content-center "
          style={{ marginLeft: "20px" }}
          data-tour='notification'
        >
          <div className="icons-dashboard second-step" style={{zIndex:2,cursor:"pointer"}} onClick={()=>setModalShow(!modalShow)}>
          {/* <RiCopperCoinFill color="goldenrod" size={27}  /> */}
          <img src={coin} alt="" width="21.5px" />
          </div>

        </div>
      </div>
      </div>
      <Recent filterText={filterText} />

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        style={{background:"rgba(0,0,0,.6)",zIndex:9876432578}}
        onHide={() => {
          setModalShow(false);
        }}
        
      >
        <Modal.Header closeButton closeVariant="white" style={{background:"#02012d"}}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "Clash Display Bold",color:"goldenrod !important" }}
          >
           WRBL CREDITS
          </Modal.Title>
        </Modal.Header>

        <Modal.Body color="white" style={{color:"white" , fontFamily:"Clash Display Light",background:"#02012d",paddingTop:"20px",paddingBottom:"20px"}}>
          <div className="d-flex row justify-content-between" style={{fontSize:"1em"}}>
            <div className="col-6 d-flex flex-column align-items-center justify-content-center">
            {/* <RiCopperCoinFill color="goldenrod" size={180} style={{marginLeft:"5px",marginRight:"3px"}}  /> */}
            <img src={coin} alt="" width="80%"/>
            <span className="d-flex align-items-center" style={{fontFamily:"Clash Display Bold",fontSize:"20px"}}> Available : <span style={{color:"goldenrod",fontSize:"25px",marginLeft:"10px",fontFamily:"Clash Display Bold"}}>{credits}</span> </span>
            </div>
            <div className="col-6">
            WRBL rewards users with credits or coins that can be used towards payments within the app. Each coin is equivalent to one US dollar, providing a simple and convenient way for users to make purchases and save money.
            </div>
          </div>

        </Modal.Body>
      </Modal>



    </div>
  );
}

export default Dashboard;