import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Loading from "../Loading";

const AdminProtectedRoutes = () => {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [role, setRole] = useState("");
  const [brand, setBrand] = useState("");

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          "https://api.xrcouture.com/auth/authorize",
          {
            email: localStorage.getItem("email"),
            brand: localStorage.getItem("brand"),
          },
          { withCredentials: true }
        );
        console.log(response);
        setIsAuthenticated(response.data.isUserAuthorized);
        setRole(response.data.role);
        setRole(response.data.role);
        setBrand(response.data.brand);
      } catch (error) {
        console.error(error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/signin");
      console.log("NOT AUTHENTICATED");
    } else if (isAuthenticated && role === "brands") {
      navigate(`/brands/${brand}`);
      console.error("BRANDS CANNOT ACCESS ADMIN PAGE");
    }
  }, [isAuthenticated, role, brand, navigate]);

  console.log("Is user authenticated? ", isAuthenticated);

  return isAuthenticated === null ? (
    <Loading />
  ) : (
    isAuthenticated && role === "admin" && (
      <Sidebar>
        <Outlet />
      </Sidebar>
    )
  );
};

export default AdminProtectedRoutes;
