import React, { useState, useEffect, useContext } from "react";
import {
  FaBars,
  FaCommentAlt,
  FaRegCommentDots,
  FaSignOutAlt,
} from "react-icons/fa";
import {  AiFillPlayCircle,
  AiFillExclamationCircle,
  AiOutlineCloseCircle,} from 'react-icons/ai'
import image from "../assets/Group.png";
import { NavLink, useParams } from "react-router-dom";
import { BiSupport } from "react-icons/bi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { RxHamburgerMenu, RxDashboard } from "react-icons/rx";
import { googleLogout } from "@react-oauth/google";
import { BeatLoader, PuffLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { MdDone } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { CiEdit } from "react-icons/ci";
import { MdOutlineLogout } from "react-icons/md";
import { showModal } from "../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AiOutlineFolderAdd } from "react-icons/ai";
import axios from "axios";
import { useTour } from "@reactour/tour";
import { Button } from "reactstrap";
import { Context, Context1 } from "../Context";



const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isCreatingAsset = useSelector(state => state.isCreatingAsset);
  const areThereUnsavedChanges = useSelector(state => state.createAssetChanges)
  const {credits,setCredits}= useContext(Context1)
  const {brandx} = useParams()


  const [w,setW] = useState(window.screen.width <= 768 ? window.screen.width-55 : window.screen.width-265)
  const [isOpen, setIsOpen1] = useState(true);
  const [name,setName] = useState("")
  const [userName,setUserName] = useState(brandx)
  const [loader,setLoader] = useState(false)
  const [loading,setLoading] = useState(false)

  const role = localStorage.getItem("role");
  const [show, setShow] = useState(false);
  const [logo, setLogo] = useState(
    "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133352152-stock-illustration-default-placeholder-profile-icon.jpg"
  );
const { setIsOpen } = useTour();
const {onboard,setOnboard} = useContext(Context1)

  const toggle = () => {
    console.log(isOpen);
    setIsOpen1(!isOpen);
  };
  const handleSubmit = (e) => {
    setLoader(true);
    axios
      .post("https://api.xrcouture.com/brands/updateprofile", {
        firstName: name,
        brandName: window.location.pathname.includes("/admin/")
          ? "admin"
          : brandx,
      })
      .then((res) => {
        setName(res.data.user.firstName);
        setTimeout(() => {
          setShow(!show);
          setLoader(false);
          toast.success("Name Updated Successfully!");
        }, 1500);
        console.log(res);
      })
      .catch((e) => {
        setLoader(false);
        toast.error("Something went wrong, Please Try agian!");
      });
  };
  const handleChange = async (e) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("logo", e.target.files[0]);
    formdata.append(
      "brandName",
      window.location.pathname.includes("/admin/") ? "admin" : brandx
    );
    axios
      .post("https://api.xrcouture.com/brands/updatelogo", formdata)
      .then((res) => {
        setLogo(res.data.user.logo[0]);
        toast.success("Profile Updated Successfully!");
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Something went wrong, Please Try agian!");
      });
  };

  const adminItem = [
    {
      path: `admin/brands`,
      name: "Manage 3d Assets",
      icon: <RxDashboard />,
      id: "home",
    },
    // {
    //   path: `brands/${brandx}/collections`,
    //   name: "Create Collections",
    //   icon: <AiOutlineFolderAdd />,
    // },
    // {
    //   path: `brands/${brandx}/analytics`,
    //   name: "Analytics",
    //   icon: <TbDeviceAnalytics />,
    // },
    {
      path: `admin/users/`,
      name: "Manage Users",
      icon: <FiUsers />,
    },
    {
      path:  `admin/support` ,
      name: "Support",
      icon: <BiSupport />,
    },
  ];
  const menuItem = [
    {
      path: `brands/${brandx}`,
      name: "Create 3d Assets",
      icon: <RxDashboard />,
      id: "home",
    },
    {
      path: `brands/${brandx}/collections`,
      name: "Interoperable WRBLs",
      icon: <AiOutlineFolderAdd />,
      class: "seventh-step",
    },
    // {
    //   path: `brands/${brandx}/analytics`,
    //   name: "Analytics",
    //   icon: <TbDeviceAnalytics />,
    // },
    {
      path: `brands/${brandx}/support`,
      name: "Support",
      icon: <BiSupport />,
      class: "eighth-step",
    },
  ];
  const [menu, setMenu] = useState(menuItem);
  const [mobile, setMobile] = useState(false);
  const {endBoard,setEndBoard} = useContext(Context1)
  useEffect(() => {
    console.log(w);
    if (window.location.pathname.includes("/admin/")) {
      setMenu(adminItem);
    }
    if(window.screen.width <= 768){
      setIsOpen(false)
      setMobile(true)
      // setW(w-50)
    }
    console.log(window.screen.width);
    axios
      .post(
        "https://api.xrcouture.com/brands/userdetails",
        {
          brandName: window.location.pathname.includes("/admin/")
            ? "admin"
            : brandx,
        },
        { withCredentials: true }
      )
      .then((res) => {
        console.log(res.data);
        setName(res.data.user.firstName);
        setCredits(res.data.user.availableCredits?res.data.user.availableCredits:0)
        // setOnboard(!res.data.user.isTourViewed)
        // setOnboard(res.data.user.isTourViewed)
        if (res.data.user.logo.length > 0) {
          setLogo(res.data.user.logo[0]);
        }
      });
  }, []);
  useEffect(() => {
    if (window.screen.width <= 850) {
      setIsOpen1(false);
      setMobile(true);
      setW(window.screen.width - 55);
    } else {
      setW(window.screen.width - 265);
    }
  }, [window.screen.width]);

  const handleLinkClick = (e) => {
    if (isCreatingAsset && areThereUnsavedChanges) {
      e.preventDefault()
      dispatch(showModal())
    }
    else {
      navigate(e.currentTarget.href);
    }
  };
  const [load, setLoad] = useState(false);
  // const [op,setop] = useState(false)
  const {op, setop} = useContext(Context1)
  const startTour = async() =>{
    setOnboard(!onboard)
    console.log("closed", onboard)
    setop(true)
    setIsOpen(true)
    await axios.post("https://api.xrcouture.com/tour/completeTour", {
      email:localStorage.getItem("email")
    });

  }
  useEffect(()=>{
    setIsOpen(op)
    console.log("states changed" ,op)
  },[op])

  const handleCloseTour = async() => {
    setOnboard(!onboard)
    console.log("closed", onboard)
    setIsOpen(false)
    setop(false)
    await axios.post("https://api.xrcouture.com/tour/completeTour", {
      email:localStorage.getItem("email")
    });
  }  

  const handleLogout = async () => {
    if (isCreatingAsset) {
      dispatch(showModal());
      return;
    }

    googleLogout();
    setLoad(true);

    await axios
      .post(
        `https://api.xrcouture.com/auth/signout`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
        localStorage.removeItem("email", "");
        localStorage.removeItem("role", "");
        localStorage.removeItem("brand", "");
        window.location.replace("/signin");
        localStorage.removeItem("logintype", "");
      })
      .catch((e) => console.log(e));
    setLoad(false);
  };
  return (
    <div className="container-sidebar position-relative">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ zIndex: 100000 }}
      />
      <div className="popup-onboard" style={{display:true?"none":"block",position:"absolute", zIndex:214748364721}}>
          <div className="onboard-screen">
            <button className="onboard-close-btn" style={{cursor:"pointer"}} onClick={handleCloseTour} ><AiOutlineCloseCircle color="#fff" size={25}  /></button>
              <h1>🎉</h1>
              <h3>Welcome to WRBL!</h3>
              <h6>Convert your ideas into 3D</h6>
              <h6>Here's a step-by-step guide to help you.</h6>
              <div className="btns-onboard">
                <button style={{background:"gray", color:"white"}} onClick={handleCloseTour}>Skip Tour</button>
                <button style={{background:"white", color:"#02012d"}} onClick={startTour} >Start Tour</button>
              </div>
          </div>
      </div>

      {/* <div className="popup-onboard" style={{display:!endBoard?"none":"block",position:"absolute", zIndex:214748364721}}>
          <div className="onboard-screen d-flex align-items-center justify-content-center">
            <button className="onboard-close-btn" style={{cursor:"pointer"}} onClick={()=>setEndBoard(!endBoard)} ><AiOutlineCloseCircle color="#fff" size={25}  /></button>
              <h1>🌟</h1>
              <h3>Have Questions?</h3>
              <h6>Convert your ideas into 3D</h6>
              <h6 className="text-center">Explore the 'Sample Asset' or feel free to react out us using "Support"</h6>
          </div>
      </div> */}

      <div
        style={{ width: isOpen ? "265px" : "55px", zIndex: 2000 }}
        className="sidebar"
      >
        <div className="top_section d-flex align-items-center justify-content-center">
          <img
            src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/wrbl.png"
            style={{
              display: isOpen ? "block" : "none",
              // width: mobile ? "60%" : "80%",
              height:mobile?"50px":"60px",
              // height: "auto",
            }}
            alt="logo"
            className="logo-sidebar"
          />
          <div
            style={{
              marginLeft: isOpen ? "30px" : "0px",
              marginRight: isOpen ? "20px" : "0px",
              display: !mobile ? "none" : "block",
            }}
            className="bars"
          >
            <FaBars onClick={toggle} />
          </div>
        </div>

        {menu.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={`link ${item.class}`}
            activeclassName="active"
            style={{ paddingLeft: "20px" }}
            id={item.id}
            onClick={handleLinkClick}
          >
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
            {/* <div className="arrow-icon">
            <BsArrowRightCircle  size={25}/>
            </div> */}
          </NavLink>
        ))}
        <img
          style={{ display: isOpen ? "block" : "none" }}
          src={image}
          alt=""
          className="img-sidebar"
        />
        <div
          className="icon d-flex align-items-center"
          style={{ marginTop: "15px", opacity: 0.6, marginLeft: "20px" }}
        >
          <MdOutlineLogout
            style={{
              marginLeft: isOpen ? "70px" : "0px",
              display: isOpen ? "none" : "block",
            }}
            onClick={() => handleLogout()}
          />
        </div>
        <div
          className="sidebar-profile-container mt-4"
          style={{ display: isOpen ? "block" : "none", marginTop: "50px" }}
        >
          <div className="row p-2">
            <div className="col-md-4">
              {/* <img src={profile} alt="profile-image" width="100%" /> */}
              <div
                className="asset-profile-icon header-logo d-flex align-items-center"
                style={{
                  backgroundImage: `url(${logo})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                }}
              >
                <input
                  type="file"
                  required
                  className="profile-input position-absolute"
                  onChange={(e) => handleChange(e)}
                  disabled={loading}
                  style={{ height: "50px", width: "50px" }}
                />
                <div
                  className={`h-100 w-100 d-flex align-items-center justify-content-center ${
                    !loading ? "edit-icon-profile" : "loading-icon-profile"
                  }`}
                >
                  {loading ? (
                    <PuffLoader color="green" size={30} />
                  ) : (
                    <FaRegEdit className="" color="white" size={10} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div
                className="asset-profile-icon asset-profile-text  ml-0"
                style={{ fontFamily: "Clash Display Bold", color: "#fff" }}
              >
                {!show && (
                  <>
                    <div className="d-flex text-cover ">
                      <div>{name}</div>{" "}
                      <CiEdit
                        size={12}
                        className="edit-icon-disable"
                        onClick={() => setShow(!show)}
                      />{" "}
                    </div>
                  </>
                )}
                {show && (
                  <div class="inputWithButton ">
                    <input
                      type="text"
                      className="asset-profile-text"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {!loader ? (
                      <button>
                        <MdDone color="white" onClick={() => handleSubmit()} />
                      </button>
                    ) : (
                      <BeatLoader size={5} color="white" />
                    )}
                  </div>
                )}
              </div>
              {/* <p style={{paddingBottom:0,marginBottom:0,fontFamily:"Clash Display Medium"}}>{localStorage.getItem('brand').charAt(0).toUpperCase() + localStorage.getItem('brand').slice(1)}</p> */}
              <p
                style={{
                  opacity: "0.5",
                  marginTop: 0,
                  PaddinTop: 0,
                  fontFamily: "Clash Display Light",
                }}
              >
                {window.location.pathname.includes("/admin/")
                  ? "admin"
                  : brandx}
              </p>
              <button
                className="profile-button"
                disabled={load}
                onClick={() => handleLogout()}
                style={{
                  fontFamily: "Clash Display Medium",
                  fontSize: "15px",
                  padding: "5px 10px",
                }}
              >
                {load ? (
                  <BeatLoader size={10} color="white" />
                ) : (
                  <>
                    <FaSignOutAlt style={{ marginRight: "5px" }} /> Logout
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="tag-container-sidebar" style={{ display: !isOpen ? "none" : "block" }} >
          <div className="sidebar-tag">

          <AiFillExclamationCircle  size={20} color="#4e4bde" style={{marginTop:"5px",alignSelf:"center"}}/>
          <p style={{fontSize:"12px",fontFamily:"Clash Display Medium"}}>Need Guidence?</p>

          <p style={{fontSize:"9px",opacity:0.5, padding:"0px 8px",textAlign:"center",fontFamily:"Clash Display Light"}}>See The Tutorial, It Can Help You To Understand The Application</p>
          <button style={{fontFamily:"Clash Display Medium"}} onClick={()=>setop(true)}><AiFillPlayCircle size={15}/> Play Tutorial</button>
          
          </div>
        </div>
      </div>
      <main
        style={{
          width: `${w}px`,
          marginLeft: mobile ? "55px" : "265px",
          overflowX: "hidden",
       
        }}
      >
        {children}
      </main>
      
    </div>
  );
};

export default Sidebar;

// ,marginLeft:isOpen?"250px":"50px"
