import * as React from "react";
import styled from "styled-components";
const ExpanderRowStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  ${({ theme }) => theme.expanderRow.style};
  ${({ extendedRowStyle }) => extendedRowStyle};
`;
function ExpanderRow({
  data,
  ExpanderComponent,
  expanderComponentProps,
  extendedRowStyle,
  extendedClassNames,
}) {
  // we need to strip of rdt_TableRow from extendedClassNames
  const classNamesSplit = extendedClassNames
    .split(" ")
    .filter((c) => c !== "rdt_TableRow");
  const classNames = ["rdt_ExpanderRow", ...classNamesSplit].join(" ");
  return (
    <ExpanderRowStyle
      className={classNames}
      extendedRowStyle={extendedRowStyle}
    >
      <ExpanderComponent data={data} {...expanderComponentProps} />
    </ExpanderRowStyle>
  );
}
export default React.memo(ExpanderRow);
