import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "../../src/DataTable/DataTable";
import axios from "axios";

const AdminSupportTickets = (props) => {
  const [data, setData] = useState([]);
  const [assetList, setAssetList] = useState([]);

  const columns = [
    {
      name: "#",
      selector: "id",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.3,
      cell: (row) => (
        <>
          <p className="text-white">{row.ticketId.split("ID")[1]}</p>
        </>
      ),
    },
    {
      name: "Subject",
      selector: "assetName",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.8,
      cell: (row) => (
        <>
          <Link to={`/admin/${row.brandName}/support/${row.ticketId}`}>
            {row.title}
          </Link>
        </>
      ),
    },
    {
      name: "Date",
      selector: "approximateTime",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          <p className="text-white">
            {new Date(row.updatedAt).toLocaleString()}
          </p>
        </>
      ),
    },
    {
      name: "Linked asset",
      selector: "approximateTime",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          <p className="text-white">{row.assetName}</p>
        </>
      ),
    },
  ];

  const tableCustomStyles = {
    headCells: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
    },
    headRow: {
      style: {
        minHeight: "52px",
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
        opacity: 0.7,
        paddingRight: 0,
        padding: "2rem",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    pagination: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        color: "white",
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Clash Display Medium",
        justifyContent: "center",
        color: "white",
        backgroundColor: "#1E1D46",
        minHeight: "100px",
      },
    },
    rows: {
      style: {
        padding: "0rem 2rem",
        fontSize: "13px",
        fontWeight: 400,
        color: "white",
        minHeight: "80px !important",
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        borderBottomWidth: ".5px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "gray",
        },
      },
      progress: {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#1E1D46",
        },
      },
    },
  };

  useEffect(() => {
    axios.get("https://api.xrcouture.com/support/getall").then((res) => {
      console.log(res);
      setData(res.data.tickets);
    });
  }, []);

  useEffect(() => {
    const filteredItems = data.filter(
      (item) => item.assetName && item.assetName.toLowerCase().includes("a")
    );
    setAssetList(filteredItems);
  }, [data]);

  return (
    <div className="mb-4">
      <div className="tables">
      <DataTable
        columns={columns}
        className="data-table"
        data={assetList}
        // selectableRows
        // contextActions={contextActions}
        // onSelectedRowsChange={handleRowSelected}
        // progressPending={pending}
        // paginationPerPage={5}
        // paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
        // paginationResetDefaultPage={resetPaginationToggle}
        // responsive={true}
        customStyles={tableCustomStyles}
      />
      </div>
    </div>
  );
};

export default AdminSupportTickets;
