import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import differenceBy from "lodash/differenceBy";
import { FaTrashAlt } from "react-icons/fa";
import { IoBagCheckOutline } from "react-icons/io5";
import { FiTrash2, FiFilter, FiDownload, FiDelete } from "react-icons/fi";
import Swal from "sweetalert2";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useContext } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import ur from "../assets/documents.png";
import cm from "../assets/checked.png";
import pd from "../assets/wall-clock.png";
import { Context1 } from "../Context";
import "./basic.css";
import CustomInput from "../components/Input";
// import { AiOutlineDelete } from "react-icons/ai";
import { BiDownArrow, BiDownload, BiTrash } from "react-icons/bi";
// import { DownChevron } from "react-select/dist/declarations/src/components/indicators";
import { Delete, Edit, Payment } from "@material-ui/icons";
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const CustomLoader = () => (
  <div style={{ padding: "24px" }}>
    <Spinner />
  </div>
);
export default function MyComponent(props) {
  const { selectedRows, setSelectedRows, updateAssets, setUpdateAssets } =
    useContext(Context1);
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();
  const { brandx } = useParams();

  useEffect(() => {
    if (window.screen.width <= 850) {
      setMobile(true);
    }
  }, [window.screen.width]);
  const [open, setOpen] = useState(false);
  const downloadFilesAsZip = async (filesArray) => {
    const s3Links = Object.values(filesArray);
    const zip = new JSZip();
    const files = await Promise.all(
      s3Links.map((link) =>
        fetch(link).then((response) => response.arrayBuffer())
      )
    );
    files.forEach((file, index) => {
      const fileName = s3Links[index].split("/").pop();
      zip.file(fileName, file);
    });
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "Digital Wearables.zip");
  };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.assetName.toLowerCase();
    const b = rowB.assetName.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const caseNumberDays = (rowA, rowB) => {
    const a = rowA.approximateTime ? rowA.approximateTime : 0;
    const b = rowB.approximateTime ? rowB.approximateTime : 0;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const caseNumberProgress = (rowA, rowB) => {
    const a = rowA.progress ? rowA.progress : 0;
    const b = rowB.progress ? rowB.progress : 0;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  let aaa = ["Completed", "In Progress"];
  const rowDisabledCriteria = (row) => aaa.includes(row.status);

  const handlePay = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to pay $${row.price}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, pay it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Paid!", "Your payment has been paid.", "success");
      } else {
        Swal.fire("Cancelled", "Your payment has been cancelled.", "error");
      }
    });
  };
  const handlePayTotal = (price) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to checkout $${price}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, pay it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Paid!", "Your payment has been paid.", "success");
      } else {
        Swal.fire("Cancelled", "Your payment has been cancelled.", "error");
      }
    });
  };
  const [startDate, setStartDate] = useState(moment());
  const [workingDays, setWorkingDays] = useState(0);
  const [estimatedDate, setEstimatedDate] = useState(null);

  function workday_count(start, end) {
    var first = start.clone().endOf("week"); // end of first week
    var last = end.clone().startOf("week"); // start of last week
    var days = (last.diff(first, "days") * 5) / 7; // this will always multiply of 7
    var wfirst = first.day() - start.day(); // check first week
    if (start.day() == 0) --wfirst; // -1 if start with sunday
    var wlast = end.day() - last.day(); // check last week
    if (end.day() == 6) --wlast; // -1 if end with saturday
    return wfirst + Math.floor(days) + wlast; // get the total
  }

  function handleCalculate() {
    let endDate = startDate.clone().add(workingDays, "days");
    let totalDays = workday_count(startDate, endDate);
    setEstimatedDate(endDate.add(workingDays - totalDays, "days"));
  }

  const columns = [
    {
      name: "#ID",
      selector: "productId",
      sortFunction: caseInsensitiveSort,
      sortable: true,
      grow: 0.5,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      cell: (row) => (
        <>
          <div className="text-white">{row.productId}</div>
          {/* <Link to={`/brands/${localStorage.getItem("brand")}/${row.assetName}`}>{row.assetName.charAt(0).toUpperCase() + row.assetName.slice(1)}</Link> */}
          {/* <Link to={row.status === "Action Required" ? `/brands/${brandName}/${row.assetName}/update` : `/brands/${brandName}/${row.assetName}`}>{row.assetName}</Link> */}
        </>
      ),
    },
    {
      name: "Thumbnail",
      selector: "thumbnail",
      style: {
        maxWidth: "90%",
        padding: 0,
        fontFamily: "Clash Display Medium",
        width: "100%",
      },
      center: true,
      grow: 0.5,
      cell: (row) => (
        <>
          {row.thumbnail && row.thumbnail != "" ? (
            <img
              src={row.thumbnail}
              style={{ width: "50px", height: "50px" }}
            />
          ) : (
            <div
              style={{
                height: "50px",
                width: "50px",
                backgroundColor: "black",
                alignContent: "center",
                justifyContent: "center",
                fontSize: "40px",
                fontWeight: "bolder",
                color: "gray",
                textAlign: "center",
                fontFamily: "Clash Display Medium",
              }}
            >
              {row.assetName
                .split(" ")
                .slice(0, 1)
                .map((n) => n[0])
                .join("")
                .toUpperCase()}
            </div>
          )}
        </>
      ),
    },

    {
      name: "Name",
      selector: "assetName",
      sortFunction: caseInsensitiveSort,
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          {row.status === "Action Required" ? (
            <Link to={`/brands/${row.brandName}/${row.assetName}/update`}>
              {row.assetName.charAt(0).toUpperCase() + row.assetName.slice(1)}
            </Link>
          ) : row.status === "Draft" ? (
            <Link to={`/brands/${row.brandName}/${row.assetName}/drafts`}>
              {row.assetName.charAt(0).toUpperCase() + row.assetName.slice(1)}
            </Link>
          ) : (
            <Link to={`/brands/${row.brandName}/${row.assetName}`}>
              {row.assetName.charAt(0).toUpperCase() + row.assetName.slice(1)}
            </Link>
          )}
          {/* <Link to={row.status === "Action Required" ? `/brands/${brandName}/${row.assetName}/update` : `/brands/${brandName}/${row.assetName}`}>{row.assetName}</Link> */}
        </>
      ),
    },
    {
      name: "Progress",
      selector: "progress",
      sortable: true,
      grow: 1.5,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      sortFunction: caseNumberProgress,
      cell: (row) => (
        //     <div className='d-flex flex-column w-100 m-0'>
        //     <div className='progress-container d-flex ' style={{width:"100%"}} >
        //      <div className='asset-progress' style={{background:"#302DDE",width:"90%"}}>
        //        <div className='progress-bar1' style={{width:`${row.status === 'Under Review' ? '14%' : row.status === 'Draft' ? "4%" : row.status === "Action Required" ? `${row.progress+4}%` : row.status === "Pending payment" ? '29%' : `${row.progress+4}%`}`}}>
        //        <div className='progress-bar-ball' >
        //          </div>
        //        </div>
        //      </div>
        //      </div>
        //        <p className='text-white text-center' style={{fontSize:"12px", fontWeight:"bolder", marginBottom:"5%"}}>{row.status === 'Under Review' ? '10' : row.status === 'Draft' ? "0" : row.status === "Action Required" ? `${row.progress}` : row.status === "Pending payment" ? '25' : `${row.progress}`}/100</p>
        //  </div>
        // <ol class="steps">
        //     <li class="step is-complete" data-step="1">
        //       Under Review
        //     </li>
        //     {/* <li class="step is-complete" data-step="2">
        //       Login
        //     </li> */}
        //     <li class="step is-active" data-step="3">
        //       Payment
        //     </li>
        //     <li class="step" data-step="4">
        //       Completed
        //     </li>
        //   </ol>
        <div className="d-flex w-100 flex-column h-100 align-item-center justify-content-center">
          <div className="progress-bar-steps">
            <div className="br-pg" style={{ width: `${row.progress}%` }}>
              <div
                className="progress-step-11 progress-step-container"
                style={{
                  background: row.progress > 10 ? "#276626" : "gray",
                  border:
                    row.progress > 0 && row.progress <= 10
                      ? "1px solid #276626"
                      : "1px solid transparent",
                }}
              >
                <div
                  className="text-pg"
                  style={{ color: row.progress <= 10 ? "gray" : "green" }}
                >
                  Under Review
                </div>
                {/* <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/completed.png" style={{width:"60%",height:"60%"}} /> */}
              </div>
              <div
                className="progress-step-12 progress-step-container"
                style={{
                  background: row.progress <= 25 ? "gray" : "#276626",
                  border:
                    row.progress > 10 && row.progress <= 25
                      ? "1px solid #276626"
                      : "1px solid transparent",
                }}
              >
                <div
                  className="text-pg"
                  style={{ color: row.progress <= 25 ? "gray" : "green" }}
                >
                  Payment
                </div>
                {/* <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/completed.png" style={{width:"60%",height:"60%"}} /> */}
              </div>
              <div
                className="progress-step-13 progress-step-container"
                style={{
                  background: row.progress == 100 ? "#276626" : "gray",
                  border:
                    row.progress > 25 && row.progress < 100
                      ? "1px solid #276626"
                      : "1px solid transparent",
                }}
              >
                <div
                  className="text-pg"
                  style={{ color: row.progress !== 100 ? "gray" : "green" }}
                >
                  Completed
                </div>
                {/* <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/completed.png" style={{width:"60%",height:"60%"}} /> */}
              </div>
            </div>
          </div>
          <p
            className="text-white text-center mt-2"
            style={{
              fontSize: "12px",
              fontWeight: "bolder",
              marginBottom: "-8%",
            }}
          >
            {row.progress}/100
          </p>
        </div>
        // <ProgressBar
        //   percent={row.status === 'Under Review' ? 9 : row.status === 'Draft' ? 0 : row.status === "Action Required" ? row.progress : row.status === "Pending payment" ? 24 : 35}
        //   filledBackground="linear-gradient(to right, rgb(114 145 254), rgb(49 92 240))"
        //   steps={[10, 25, 100]}
        // >
        //   <Step transition="scale" position={10} className="hellow" >
        //     {({ accomplished, position }) => (
        //       <>
        //         <img
        //           style={{ filter: `grayscale(${row.progress >= 10 ? 0 : 80}%)`, background: "#1E1D46", borderRadius: "50%", padding: "5px", left: position }}
        //           width="25"
        //           height="25"
        //           src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/review.png"
        //         />
        //         <span className='text-progress'>Under Review</span>
        //       </>
        //     )}
        //   </Step>
        //   <Step transition="scale">
        //     {({ accomplished }) => (
        //       <>
        //         <img
        //           style={{ filter: `grayscale(${row.progress >= 25 ? 0 : 80}%)`, background: "#1E1D46", borderRadius: "50%", padding: "5px" }}
        //           width="25"
        //           src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/action.png"
        //         />
        //         <span className='text-progress'>Paid</span>
        //       </>
        //     )}
        //   </Step>
        //   <Step transition="scale">
        //     {({ accomplished }) => (
        //       <>
        //         <img
        //           style={{ filter: `grayscale(${row.progress ? 100 : 80}%)`, background: "#1E1D46", borderRadius: "50%", padding: "5px" }}
        //           width="25"
        //           src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/completed.png"
        //         />
        //         <span className='text-progress'>Completed</span>
        //       </>
        //     )}
        //   </Step>
        // </ProgressBar>
      ),
      sortable: true,
    },
    {
      name: "EDD",
      selector: "approximateTime",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          {row.estimated !== undefined ? (
            <div className="text-white">{row.estimated}</div>
          ) : (
            <div className="text-white">---</div>
          )}
        </>
      ),
      sortFunction: caseNumberDays,
    },
    {
      name: "Status",
      selector: "status",
      grow: 1,
      sortable: true,
      style: {
        width: "400px",
        padding: 0,
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      cell: (row) => (
        <div className="d-flex justify-content-between align-items-center">
          <div
            style={{
              border: "1px solid",
              borderColor: `${
                row.status === "Under Review"
                  ? "#568B7F"
                  : row.status === "Draft"
                  ? "#CACACD"
                  : row.status === "Action Required"
                  ? "#8B565E"
                  : row.status === "Pending payment"
                  ? "#A68567"
                  : row.status === "In Progress"
                  ? "#5B568B"
                  : "#538260"
              }`,
              background: `${
                row.status === "Under Review"
                  ? "rgba(0, 138, 122, 0.6)"
                  : row.status === "Draft"
                  ? "rgb(177 173 173 / 46%)"
                  : row.status === "Action Required"
                  ? "#662626"
                  : row.status === "Pending payment"
                  ? "#665026"
                  : row.status === "In Progress"
                  ? "#272666"
                  : "#276626"
              }`,
              borderRadius: "10px",
            }}
          >
            {row.status === "Pending payment" ? (
              <p
                className="d-flex flex-row align-items-center justify-content-center"
                style={{
                  color: "white",
                  padding: "5px 8px",
                  marginBottom: "0",
                }}
              >
                {row.status}
                <span className="text-align-center">{`($${row.actualPrice})`}</span>{" "}
              </p>
            ) : (
              <p
                style={{
                  color: "white",
                  padding: "5px 8px",
                  marginBottom: "0",
                }}
              >
                {row.status}
              </p>
            )}
          </div>
          {/* <p style={{color: `${row.status ==== "Under Review" ? "violet" : row.status ==== "Draft" ? "gray" : "green"}`}}>{row.status}</p> */}
        </div>
      ),
    },
    {
      name: '',
      // selector: "",
      grow: .1,
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      cell: (row) => (
        <div className="d-flex flex-row flex-1">
                    <div>
          <div className="button-download-div">
          <button style={{marginRight:"10px", background:"transparent",border:"none", color:"white",cursor:!['Completed','In Progress'].includes(row.status)?"pointer":"no-drop"}} disabled={['Completed','In Progress'].includes(row.status)?true:false} onClick={()=>handleDelete(row)} >
            <FiTrash2 size={15} />
          </button>
          <div
            className="hover-messaage-icon"
            style={{
              visibility: `${!['Completed','In Progress'].includes(row.status) ? "hidden" : "visible"}`,
              justifySelf: "center",
              left: "0%",
              width:"200px",
              zIndex:10
            }}
          >
            You can't delete this asset bcoz this asset is in {row.status}
          </div>
          </div>
          {/* <p className="text-white">In order to download the asset the progress should be 100. If it's not wait untill it reaches 100.</p> */}
          </div>
          <div>
          <div className="button-download-div">
          <button style={{marginRight:"10px", background:"transparent",border:"none", color:"white",cursor:!['Completed','In Progress']? "pointer":"no-drop"}} onClick={()=>downloadFilesAsZip(row.digitalWearables)} disabled={!['Completed'].includes(row.status)} >
            <FiDownload size={15} />
          </button>
          <div
            className="hover-messaage-icon"
            style={{
              visibility: `${row.status == "Completed" ? "hidden" : "visible"}`,
              justifySelf: "center",
              left: "0%",
              width:"200px",
              zIndex:10
            }}
          >
            Please note that the asset will not be available for download until its progress reaches 100% completion.
          </div>
          </div>
          {/* <p className="text-white">In order to download the asset the progress should be 100. If it's not wait untill it reaches 100.</p> */}
          </div>
        </div>
        // <UncontrolledDropdown direction="start">
        //   <DropdownToggle
        //     color="transparent"
        //     className="action-toggle"
        //     direction="end"
        //   >
        //     <BiDownArrow className='action-container-icon ' />
        //   </DropdownToggle>
        //   <DropdownMenu dark>

        //     {
        //       row.status === 'Pending payment' &&
        //       <>
        //         <DropdownItem onClick={() => (alert(`Are you ready to pay: ${row.budget}`))}>
        //           <Payment /> Pay Now
        //         </DropdownItem>
        //         <DropdownItem divider />
        //       </>
        //     }

        //     {row.status === "Action Required" &&
        //       <>
        //         <DropdownItem onClick={() => navigate(`/brands/${localStorage.getItem("brand")}/${row.assetName}/update`)}>
        //           <Edit /> Edit
        //         </DropdownItem>
        //         <DropdownItem divider />
        //       </>
        //     }

        //     {row.status === "Action Required" &&
        //       <>
        //         <DropdownItem onClick={() => navigate(`/brands/${brandx}/${row.assetName}/update`)}>
        //           <Edit /> Edit
        //         </DropdownItem>
        //         <DropdownItem divider />
        //       </>
        //     }

        //     {row.status === "Completed" &&
        //       <>
        //         <DropdownItem onClick={() => downloadFilesAsZip(row.digitalWearables)}>
        //           <BiDownload /> Download
        //         </DropdownItem>
        //         <DropdownItem divider />
        //       </>
        //     }

        //     {
        //       (row.status === "Under Review" || row.status === "Draft" || row.status === "Pending payment" || row.status === "Action Required") &&
        //       <>
        //         <DropdownItem onClick={() => handleDelete(row)}>
        //           <Delete /> Delete
        //         </DropdownItem>
        //       </>
        //     }

        //   </DropdownMenu>
        // </UncontrolledDropdown>
      )
    },
  ];

  const [pending, setPending] = useState(true);
  // const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [image, setimage] = useState("");
  const [name, setname] = useState("");
  const [payment, setpayment] = useState(false);
  const [platform, setplatform] = useState("");
  const [date, setdate] = useState("");
  const [status, setstatus] = useState(0);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const handleView = (row) => {
    console.log(row);
    setViewModal(true);
    setimage(row.image);
    setname(row.name);
    setpayment(row.payment);
    setplatform(row.platform);
    setdate(row.date);
    setstatus(row.status);
    console.log(viewModal);
  };
  const [assetList, setAssetList] = useState([]);
  const filteredItems = data.filter(
    (item) =>
      item.assetName &&
      item.assetName.toLowerCase().includes(filterText.toLowerCase())
  );
  const fetchAsset = async () => {
    await axios
      .post("https://api.xrcouture.com/brands/assets", {
        brand: brandx,
      })
      .then((res) => {
        setData(res.data.assets);
      });
  };
  console.log(data);

  const tableCustomStyles = {
    headCells: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        // textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
    },
    headRow: {
      style: {
        minHeight: "52px",
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
        opacity: 0.7,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    pagination: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        color: "white",
        paddingRight: "30px",
        paddingLeft: "30px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Clash Display Medium",
        justifyContent: "center",
        color: "white",
        backgroundColor: "#1E1D46",
        minHeight: "100px",
      },
    },
    rows: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        color: "white",
        minHeight: "100px !important",
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        borderBottomWidth: ".5px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
        paddingLeft: "10px",
        paddingRight: "10px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "gray",
        },
        "&:hover": {
          boxShadow: "0px 1px 10px 5px rgba(255,255,255,0.2) !important",
          cursor: "pointer",
        },
      },
      progress: {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#1E1D46",
        },
      },
    },
  };
  useEffect(() => {
    setPending(true);
    fetchAsset();
    const timeout = setTimeout(() => {
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [updateAssets]);

  useEffect(() => {
    var a = data;
    if (data.length > 0) {
      data.map((i) => {
        if (i.paymentDate !== undefined) {
          let startDate = moment(i.paymentDate);
          let workingDays = i.extraDays
            ? i.actualTime + i.extraDays
            : i.actualTime;
          let endDate = startDate.clone().add(workingDays, "days");
          let totalDays = workday_count(startDate, endDate);
          setEstimatedDate(endDate.add(workingDays - totalDays, "days"));
          let est = endDate.add(workingDays - totalDays, "days");
          console.warn(moment(est._d).format("DD-MM-YYYY"));
          let tt = moment(est._d).format("DD-MM-YYYY");
          i.estimated = tt;
          console.warn(i);
        }
      });
    }
    setData(a);
    setAssetList(filteredItems);
    console.log(assetList);
  }, [data]);

  const [filterState, setFilterState] = useState("All");

  const filteredItemsByState = data.filter(function (item) {
    return item.status == filterState;
  });

  useEffect(() => {
    console.log("changed");
    if (filterState != "All") {
      setAssetList(filteredItemsByState);
    } else {
      setAssetList(data);
    }
  }, [filterState]);

  useEffect(() => {
    setAssetList(filteredItems);
  }, [filterText]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const ale = (a, row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: `You want to delete ${row.assetName}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    const successalt = (a, x) => {
      Swal.fire({
        position: "center",
        icon: a,
        title: x,
        showConfirmButton: false,
        timer: 1500,
      });
    };
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `You want to delete ${row.assetName}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
        reverseButtons: false,
      })
      .then(async (result) => {
        console.log(row);
        if (result.isConfirmed) {
          await axios
            .delete("https://api.xrcouture.com/brands/delete", {
              data: { 
                brand: localStorage.getItem("brand"),
                name: [row.assetName],
              },
            })
            .then((res) => {
              window.location.reload();
              setPending(true);
              fetchAsset();
              setAssetList(filteredItems);
              const timeout = setTimeout(() => {
                setPending(false);
              }, 1000);
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
              return () => clearTimeout(timeout);
            })
            .catch((e) => alert(e));

          setData(differenceBy(data, [row], "id"));
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          successalt("error", "Cancelled");
        }
      });
  };

  const handleDelete = (row) => {
    ale("Delete", row);
  };

  const contextActions = React.useMemo(() => {
    var price = 0;
    selectedRows.map((r) => {
      if (Number(r.actualPrice)) {
        price += r.actualPrice;
      }
    });
    return (
      <>
        <div className="tables">
          <h6 className="p-4 h-100">${price}</h6>
        </div>
        <IoBagCheckOutline onClick={() => handlePayTotal(price)} />
        <FaTrashAlt key="delete" onClick={handleDelete} className="m-4" icon />
      </>
    );
  }, [data, selectedRows, toggleCleared]);

  const handleDeleteAll = () => {
    var name = [];
    var notdel = [];
    const successalt = (a, x) => {
      Swal.fire({
        position: "center",
        icon: a,
        title: x,
        showConfirmButton: false,
        timer: 1500,
      });
    };
    selectedRows.map((row) => {
      if (!["Completed", "In Progress"].includes(row.status)) {
        name.push(row.assetName);
      } else {
        notdel.push(row.assetName);
      }
    });

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `${
          notdel.length > 0
            ? `Only ${name.length}/${selectedRows.length} are deletable, Continue deleting!`
            : `Are you sure, You want to delete:\r ${selectedRows.length} assets?`
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setToggleCleared(!toggleCleared);

          console.log({
            brand: brandx,
            name: name,
          });
          await axios
            .delete("https://api.xrcouture.com/brands/delete", {
              data: {
                brand: brandx,
                name: name,
              },
            })
            .then((res) => {
              window.location.reload();
              setPending(true);
              fetchAsset();
              setAssetList(filteredItems);
              const timeout = setTimeout(() => {
                setPending(false);
              }, 1000);
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
              return () => clearTimeout(timeout);
            })
            .catch(() => alert("Something Went Wrong"));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          successalt("error", "Cancelled");
        }
      });
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [del, setDel] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className="mb-5 mt-4" style={{marginBottom:"5em !important"}}>
      <div class="container-line" style={{height:"1px"}}>
    <div style={{background:"linear-gradient(0deg,rgba(71, 118, 240, 0.06) 0%,#0057ff 50%,rgba(74, 71, 240, 0.06) 99.48%)"}}></div>
    </div>
    <div className="position-relative" style={{borderRadius:"5px"}}>
    <div style={{background:"linear-gradient(152.97deg, #1E1D47 18.75%, rgba(0, 0, 0, 0.58) 100%)",borderRadius:"10px"}} className="table-container">
      <div className="tabs-container d-flex justify-content-between" >
        <div className="d-flex align-items-center">
          <h3
            style={{
              color: "white",
              float: "left",
              fontFamily: "Clash Display Medium",
              marginBottom: "0",
              // marginRight: "10px",
            }}
            className="fs-lg"
          >
            All Assets
          </h3>
          {selectedRows.length > 0 && (
            <BiTrash
              color="white"
              fontWeight={100}
              style={{ transition: "1s ease-in",marginLeft:"10px" }}
              onClick={(e) => handleDeleteAll()}
              size={25}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight:"10px"
          }}
        >
          {mobile ? (
                <div className="body-search third-step" style={{ zIndex: open ? 100 : "" }}>
                <div
                  class="search"
                  style={{
                    width: open ? "90%" : "fit-content",
                    position: open ? "absolute" : "relative",
                    top: open ? "50%" : 0,
                    transform: open ? "translateY(-50%)" : "none",
                  }}
                >
                  <div class="search_bar" style={{ width: open ? "100%" : "" }}>
                    <input id="searchOne" type="checkbox" />
                    <label for="searchOne">
                      {/* <i class='icon ion-android-search'></i> */}
                      {/* <i class="fa fa-duotone fa-magnifying-glass" onClick={()=>setOpen(!open)}></i> */}
                      <i class="las la-search" onClick={() => setOpen(!open)}></i>
                      {/* <i class=' icon ion-android-close'></i> */}
                      <i class="last las la-times" onClick={() => setOpen(!open)}></i>
                      <p>|</p>
                    </label>
                    <input
                      placeholder="Search..."
                      type="text"
                      class="input-field manage-asset-input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
          ) : (
            <div
              class="input-icons third-step"
              style={{ width: "300px", height: "100px", borderColor: "white" }}
            >
              <i class="fa fa-magnifying-glass input-icon"></i>
              <input
                class="input-field manage-asset-input"
                type="text"
                placeholder="Search Assets"
                value={filterText}
                style={{
                  fontFamily: "Clash Display Light",
                  fontSize: "18px !important",
                  minHeight: "35px !important",
                  background: "white !important",
                  padding: "10px !important",
                }}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          )}
          <div class="btn-group dropdown-status ">
            <button
              type="button"
              style={{ fontSize: "18px", fontFamily: "Clash Display Light" }}
              className="btn btn-secondary dropdown-toggle dropdown-status fourth-step"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {!mobile && "Filter"}
              <FiFilter style={{ marginLeft: "5px" }} />
            </button>
            {/* <button type="button" style={{ fontSize: "18px", fontFamily: "Clash Display Light" }} className="btn btn-secondary dropdown-toggle dropdown-status d-md-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <FiFilter style={{marginLeft:"5px"}} />
            </button> */}
            <div
              class="dropdown-menu dropdown-menu-right"
              style={{ marginTop: "5px" }}
            >
              <div className="drop">
                <button
                  type="button"
                  style={{ fontFamily: "Clash Display Medium" }}
                  className={
                    filterState == "All"
                      ? "dropdown-item active-filter"
                      : "dropdown-item"
                  }
                  onClick={() => setFilterState("All")}
                >
                  All
                </button>
                <button
                  type="button"
                  style={{ fontFamily: "Clash Display Medium" }}
                  className={
                    filterState == "Draft"
                      ? "dropdown-item active-filter"
                      : "dropdown-item"
                  }
                  onClick={() => setFilterState("Draft")}
                >
                  Draft
                </button>
                <button
                  type="button"
                  style={{ fontFamily: "Clash Display Medium" }}
                  className={
                    filterState == "Under Review"
                      ? "dropdown-item active-filter"
                      : "dropdown-item"
                  }
                  onClick={() => setFilterState("Under Review")}
                >
                  Under Review
                </button>
                <button
                  type="button"
                  style={{ fontFamily: "Clash Display Medium" }}
                  className={
                    filterState == "Action Required"
                      ? "dropdown-item active-filter"
                      : "dropdown-item"
                  }
                  onClick={() => setFilterState("Action Required")}
                >
                  Action Required
                </button>
                <button
                  type="button"
                  style={{ fontFamily: "Clash Display Medium" }}
                  className={
                    filterState == "Pending payment"
                      ? "dropdown-item active-filter"
                      : "dropdown-item"
                  }
                  onClick={() => setFilterState("Pending payment")}
                >
                  Payment Pending
                </button>
                <button
                  type="button"
                  style={{ fontFamily: "Clash Display Medium" }}
                  className={
                    filterState == "In Progress"
                      ? "dropdown-item active-filter"
                      : "dropdown-item"
                  }
                  onClick={() => setFilterState("In Progress")}
                >
                  In Progress
                </button>
                <button
                  type="button"
                  style={{ fontFamily: "Clash Display Medium" }}
                  className={
                    filterState == "Completed"
                      ? "dropdown-item active-filter"
                      : "dropdown-item"
                  }
                  onClick={() => setFilterState("Completed")}
                >
                  Completed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tables" style={{ position: "relative" }}>
        <DataTable
          columns={columns}
          className="data-table"
          data={assetList}
          selectableRows
          contextActions={contextActions}
          onSelectedRowsChange={handleRowSelected}
          progressPending={pending}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginationResetDefaultPage={resetPaginationToggle}
          responsive={true}
          customStyles={tableCustomStyles}
          highlightOnHover={true}
          pagination
          progressComponent={<CustomLoader />}
          // conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      <div className="d-flex justify-content-between">
      </div>
    </div>
    </div>
    </div>
  );
}
