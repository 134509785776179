import { PaymentElement } from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import "./Payment.css";
import Swal from "sweetalert2";

import axios from "axios";
import { Context1 } from "../../Context";
import { success } from "toastr";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { updateAssets, setUpdateAssets } = useContext(Context1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, status } = await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://localhost:3000/",
        },
        redirect: "if_required",
      })
      .then((res) => {
        console.warn("HEREEEEEEEEEEEEEEEEEEE", res);
        if (res.paymentIntent.status === "succeeded") {
          axios
            .post(
              "https://api.xrcouture.com/payments/updatePayments",
              {
                assetNames: props.assets,
                brandName: props.brand,
                transactionId: res.paymentIntent.id,
                price: res.paymentIntent.amount,
                status: "Completed",
              },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              setUpdateAssets((prev) => !prev);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Purchase Successful",
                showConfirmButton: false,
                timer: 3000,
              });
            })
            .catch((err) => {
              console.log(err);
            });

          console.log("updated");
        }
      });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    if (status) {
      console.log(status);
      setMessage(status);
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="text-white">
      <PaymentElement id="payment-element" />
      <button
        className="btn btn-primary mt-4"
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
