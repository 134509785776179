import React from 'react';
import * as ReactDOM from 'react-dom';
import "./Faq.css"
import { Link } from 'react-router-dom';
export const panels = [
    {
        label: 'What is WRBL?',
        content: 'WRBL is a 3D asset builder that lets you create and manage stunning 3D assets for your projects. You can design anything you want and share it with the world, and later mint it as a NFT. WRBL also supports interoperable wearables that can be used across different platforms.',
    },
    {
        label: 'How do I create a 3D asset with WRBL?',
        content: 'To create a 3D asset with WRBL, you need to sign up for a free account and fill out a form with the details of your desired asset. We will create the 3D asset based on the information you submit and send it to you for approval. You can request revisions if needed. ',
    },	
    {
        label: 'How can I publish the assets once created?',
        content: 'Once the asset is created, you can download the file and publish it across your desired platforms. Our support team can guide you, on how to publish the 3D asset on each platform. You can also choose to export your assets in different formats and modify them if you want.',
    },
    {
        label: 'How do I use interoperable wearables with WRBL? ',
        content:<> To use interoperable wearables feature, you need to get in touch with us. <Link
		to={"https://calendly.com/xrcouture/wrbl"}
		target="_blank"
		// className="text-white"
	  >
		Book a demo
	  </Link> </>,
    },
    {
        label: 'How do I get support from WRBL?',
        content: 'If you need any help or have any questions about WRBL, you can contact our support team anytime via email or chat. We are always ready to assist you and solve any issues you may encounter. You can also access our FAQ section and our community forum for more information and tips.'
    },
];

export class Panel extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			height: 0
		};
	}

    

	componentDidMount() {
		window.setTimeout(() => {
			const el = ReactDOM.findDOMNode(this);
			const height = el.querySelector('.panel__inner').scrollHeight;
			this.setState({
				height
			});
		}, 333);
	}

	render () {
		const { label, content, activeTab, index, activateTab } = this.props;
		const { height } = this.state;
		const isActive = activeTab === index;
		const innerStyle = {
			height:  `${isActive ? height : 0}px`
		}

		return (
			<div className='panel'
				role='tabpanel'
				aria-expanded={ isActive }>
				<button className='panel__label'
					role='tab'
					onClick={ activateTab }>
					{ label }
				</button>
				<div className='panel__inner'
					style={ innerStyle }
					aria-hidden={ !isActive }>
					<p className='panel__content'>
						{ content }
					</p>
				</div>
			</div>
		);
	}
}

export class Accordion extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			activeTab: 0
		};
		
		this.activateTab = this.activateTab.bind(this);
	}
	
	activateTab(index) {
		this.setState(prev => ({
			activeTab: prev.activeTab === index ? -1 : index
		}));
	}
	
	render() {
		const { panels } = this.props;
		const { activeTab } = this.state;
		return (
			<div className='accordion' role='tablist'>
				{panels.map((panel, index) =>
					<Panel
						key={ index }
						activeTab={ activeTab }
						index={ index }
						{ ...panel } 
						activateTab={ this.activateTab.bind(null, index) }
					/>
				)}
			</div>
		);
	}
}

