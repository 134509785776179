import React, { useState, useContext } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import { useParams } from "react-router";
import { Context1 } from "../Context";
import Swal from "sweetalert2";

const PaypalCheckoutButton = (props) => {
  const { amount, products } = props;

  const {credits,setCredits} = useContext(Context1)
  const { brandx } = useParams();

  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);

  const { updateAssets, setUpdateAssets } = useContext(Context1);

  const handleApprove = async (orderId) => {
    // Call backend function to fulfill order

    const data = {
      assetNames: products.map((item) => item.assetName),
      brandName: brandx,
      transactionId: orderId,
      price: amount,
      status: "Completed",
      credits:credits
    };

    if (props.promoCode.discount !== null) {
      data.discountedPrice = props.promoCode.discount;
      data.promocode = props.promoCode.code;
    }

    await axios
      .post("https://api.xrcouture.com/payments/updatePayments", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log(response.data);
        setUpdateAssets((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });

    // if response is success
    setPaidFor(true);

    // Refresh user's account or subscription status

    // if response is error
    // alert("Your payment was processed successfully. However, we are unable to fulfill your purchase. Please contact us at support@designcode.io for assistance.");
  };

  if (paidFor) {
    // Display success message, modal or redirect user to success page
    props.closeModal();
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Purchase Successful",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  if (error) {
    // Display error message, modal or redirect user to error page
    alert(error);
  }

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AUJcycdBvXYUuYbTnYYWZC3jGfgsfLji1usUAoNDzOLjAVnf_dT0T_0F8-l8Rv2b_SgT47TdVuDa3OGC",
      }}
    >
      <PayPalButtons
        style={{
          label: "pay",
          layout: "horizontal",
          color: "white",
          height: 48,
          tagline: false,
          shape: "pill",
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "Some description",
                amount: {
                  currency_code: "USD",
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const order = await actions.order.capture();
          console.log("order", order);

          handleApprove(data.orderID);
        }}
        onError={(err) => {
          setError(err);
          console.error("PayPal Checkout onError", err);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalCheckoutButton;
