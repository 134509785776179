import React, { useEffect, useState } from "react";
import DataTable from "../../src/DataTable/DataTable";
import Swal from "sweetalert2";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from "@novu/notification-center";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
const CustomLoader = () => (
  <div style={{ padding: "24px" }}>
    <Spinner />
  </div>
);
export default function MyComponent(props) {
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.status;
    const b = rowB.status;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const columns = [
    {
      name: "Brand Logo",
      selector: "thumbnail",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          {row.thumbnail && row.thumbnail != "" ? (
            <img
              src={row.thumbnail}
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <div
              style={{
                height: "30px",
                width: "30px",
                backgroundColor: "black",
                alignSelf: "center",
                justifySelf: "center",
                fontSize: "20px",
                fontWeight: "bolder",
                color: "gray",
                textAlign: "center",
              }}
            >
              {row.brand
                .split(" ")
                .slice(0, 1)
                .map((n) => n[0])
                .join("")
                .toUpperCase()}
            </div>
          )}
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.brand,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      sortable: true,
      cell: (row) => <Link to={`/admin/brands/${row.brand}`}>{row.brand}</Link>,
    },
    {
      name: "Draft",
      selector: (row) => row["Draft"],
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      sortable: true,
      cell: (row) => (
        <>
          {" "}
          {row.Draft ? (
            <h6 className="text-white">{row.Draft}</h6>
          ) : (
            <h6 className="text-white">0</h6>
          )}
        </>
      ),
    },
    {
      name: "Under Review",
      selector: "Under Review",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      sortable: true,
      cell: (row) => (
        <>
          {row["Under Review"] ? (
            <h6 style={{ color: "violet" }}>{row["Under Review"]}</h6>
          ) : (
            <h6 style={{ color: "violet" }}>0</h6>
          )}
        </>
      ),
    },

    {
      name: "Action Needed",
      selector: "Action Needed",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      sortable: true,
      cell: (row) => (
        <>
          {row["Action Needed"] ? (
            <h6 style={{ color: "red" }}>{row["Action Needed"]}</h6>
          ) : (
            <h6 style={{ color: "red" }}>0</h6>
          )}
        </>
      ),
    },
    {
      name: "Payment Pending",
      selector: "Pending payment",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          {row["Pending payment"] ? (
            <h6 style={{ color: "yellow" }}>{row["Pending payment"]}</h6>
          ) : (
            <h6 style={{ color: "yellow" }}>0</h6>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "In Progress",
      selectable: "In Progress",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          {row["In Progress"] ? (
            <h6 style={{ color: "blue" }}>{row["In Progress"]}</h6>
          ) : (
            <h6 style={{ color: "blue" }}>0</h6>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "Completed",
      selectable: "Completed",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          {row["Completed"] ? (
            <h6 style={{ color: "blue" }}>{row["Completed"]}</h6>
          ) : (
            <h6 style={{ color: "blue" }}>0</h6>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "Total Count",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      selector: (row) => (
        <>
          <h6 style={{ color: "green" }}>{row.totalCount}</h6>
        </>
      ),
      sortable: true,
    },
  ];

  const [pending, setPending] = useState(true);
  const [data, setData] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [assetList, setAssetList] = useState([]);
  const fetchAsset = async () => {
    await axios
      .get("https://api.xrcouture.com/admin/getAssets")
      .then((res) => {
        setData(res.data.assets);
      })
      .catch((e) => console.log(e));
    setAssetList(filteredItems);
    setPending(false);
  };

  useEffect(() => {
    setPending(true);
    fetchAsset();
  }, []);

  const filteredItems = data.filter(
    (item) =>
      item.brand && item.brand.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    setAssetList(filteredItems);
  }, [data, filterText]);

  const tableCustomStyles = {
    headCells: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    headRow: {
      style: {
        minHeight: "52px",
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    pagination: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        color: "white",
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Clash Display Medium",
        justifyContent: "center",
        color: "white",
        backgroundColor: "#1E1D46",
        minHeight: "100px",
      },
    },
    rows: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        color: "white",
        minHeight: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottomWidth: ".5px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "gray",
        },
      },
      progress: {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#1E1D46",
        },
      },
    },
  };

  return (
    <div className="p-2 mb-4">
      <div className="row container-dashboard m-0">
        <div className="col-6">
          <h1 style={{ color: "white", fontFamily: "Clash Display Medium" }}>
            Brands Overview
          </h1>
        </div>
        <div className="col-6 row d-flex align-items-center justify-content-between">
          <div className="col-10 h-100 m-0 p-0">
            <div class="input-icons">
              <i class="fa fa-search input-icon"></i>
              <input
                class="input-field manage-asset-input"
                type="text"
                placeholder="Search Brand"
                value={filterText}
                style={{ fontFamily: "Clash Display Medium" }}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1 p-1 d-flex justify-content-center">
            <div className="icons-dashboard">
              <NovuProvider
                subscriberId={"admin"}
                applicationIdentifier={"fFvjvHFwBD3F"}
              >
                <PopoverNotificationCenter>
                  {({ unseenCount, unseenBadgeColor }) => (
                    <>
                      {console.log(unseenCount)}
                      <NotificationBell unseenCount={unseenCount} />
                      <div class="number">{unseenCount}</div>
                    </>
                  )}
                </PopoverNotificationCenter>
              </NovuProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="tables mt-4" style={{ position: "relative" }}>
        <DataTable
          columns={columns}
          className="data-table"
          data={assetList}
          progressPending={pending}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginationResetDefaultPage={resetPaginationToggle}
          pagination
          progressComponent={<CustomLoader />}
          customStyles={tableCustomStyles}
        />
      </div>
    </div>
  );
}
