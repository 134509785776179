import React from "react";
import "./Loading.css";
import { PuffLoader } from "react-spinners";

const Loading = () => {
  return (
    <>
      <div className="route-loading">
        <PuffLoader color={"white"} size={80} />
      </div>
    </>
  );
};

export default Loading;
