import React, { useState } from "react";


function CustomInput() {
  const [open, setOpen] = useState(false);
  return (
    <div className="body-search third-step" style={{ zIndex: open ? 100 : "" }}>
      <div
        class="search"
        style={{
          width: open ? "90%" : "fit-content",
          position: open ? "absolute" : "relative",
          top: open ? "50%" : 0,
          transform: open ? "translateY(-50%)" : "none",
        }}
      >
        <div class="search_bar" style={{ width: open ? "100%" : "" }}>
          <input id="searchOne" type="checkbox" />
          <label for="searchOne">
            {/* <i class='icon ion-android-search'></i> */}
            {/* <i class="fa fa-duotone fa-magnifying-glass" onClick={()=>setOpen(!open)}></i> */}
            <i class="las la-search" onClick={() => setOpen(!open)}></i>
            {/* <i class=' icon ion-android-close'></i> */}
            <i class="last las la-times" onClick={() => setOpen(!open)}></i>
            <p>|</p>
          </label>
          <input
            placeholder="Search..."
            type="text"
            class="input-field manage-asset-input"
          />
        </div>
      </div>
    </div>
  );
}

export default CustomInput;
