import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import axios from "axios";
import Loading from "../Loading";

const RouteHidden = () => {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [role, setRole] = useState("");
  const [brand, setBrand] = useState("");
  const [signUpCompleted, setSignUpCompleted] = useState("");

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          "https://api.xrcouture.com/auth/authorize",
          {
            email: localStorage.getItem("email"),
            brand: localStorage.getItem("brand"),
          },
          { withCredentials: true }
        );
        console.log(response);
        setIsAuthenticated(response.data.isUserAuthorized);
        setRole(response.data.role);
        setRole(response.data.role);
        setBrand(response.data.brand);
        setSignUpCompleted(response.data.signUpCompleted);
      } catch (error) {
        console.error(error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/signup");
    } else if (
      isAuthenticated &&
      role === "brands" &&
      signUpCompleted === true
    ) {
      navigate(`/brands/${brand}`);
    } else if (isAuthenticated && role === "admin") {
      navigate("/admin/brands");
    }
  }, [isAuthenticated, role, navigate, signUpCompleted, brand]);

  console.log("Is user authenticated? ", isAuthenticated);

  return isAuthenticated === null ? (
    <Loading />
  ) : (
    isAuthenticated && role === "brands" && signUpCompleted === false && (
      <Outlet />
    )
  );
};

export default RouteHidden;
