import React, { useContext, useEffect } from "react";

import { Context } from "../../../Context";

import PriceEstimation from "../Estimation/PriceEstimation";

import sandboxImg from "../../../assets/sandbox_IMG.png";
import snapchatImg from "../../../assets/snapchat_IMG.png";
import zepetoImg from "../../../assets/zepeto_IMG.png";
import clonexImg from "../../../assets/clonex_IMG.png";
import decentralandImg from "../../../assets/decentraland_IMG.png";
// import minecraftImg from "../../../assets/minecraft_IMG.png";
import robloxBlackImg from "../../../assets/roblox-black_IMG.png";
import robloxRedImg from "../../../assets/roblox-red_IMG.png";
import clo3dImg from "../../../assets/clo3d_IMG.png";
import rpm from '../../../assets/rpm.png'
import minecraftImg from '../../../assets/minecraft.webp'

import "./PlatformSelect.css";

import { IoArrowBackOutline } from "react-icons/io5";
import { BsCheck2 } from "react-icons/bs";

const PlatformSelect = (props) => {
  // STATES
  const { platformsData } = useContext(Context);
  const [platforms, selectPlatforms] = platformsData;

  const handleClick = (e) => {
    const { name } = e.target;

    selectPlatforms((prev) => {
      const obj = { ...prev };
      obj[name] = !prev[name];
      return obj;
    });
  };

  // useEffect(() => {
  //   const parentDiv = document.querySelector('#upload-title-height3');

  //   const firstDivHeight = window.getComputedStyle(parentDiv).height;
  //   props.setCloseButtonHeightFun(firstDivHeight)
  // }, [])

  return (
    <div>
      <div className="d-flex align-items-center text-white">
        <div className="upload-title fs-xl" id="upload-title-height1">
          Create Asset
        </div>
      </div>
      <div className="d-flex align-items-center mt-4" style={{width:"80% !important"}}>
          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #fff",
                background: "#02012d",
                color: "#000",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
                cursor:"pointer"
              }}
              onClick={()=>props.backButton(2)}
            >
              <div><BsCheck2 color="white" size={"1.5em"}   /></div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold",marginRight:"7px" }}
            >
              Define
            </div>
          </div>

          <hr
            style={{
              color: "white",
              background: "white",
              width: "3em",
              height: "0.1em",
              marginLeft:"0.1em",
              opacity:1,
              marginRight:"0.1em"
            }}
          />

          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #fff",
                background: "#02012d",
                color: "#000",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
                cursor:"pointer"
              }}
              onClick={()=>props.backButton(1)}
            >
              <div><BsCheck2 color="white" size={"1.5em"}   /></div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold",marginRight:"7px" }}
            >
              Design
            </div>
          </div>

          <hr
            style={{
              color: "white",
              background: "white",
              width: "3em",
              height: "0.1em",
              opacity:1
            }}
          />

          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #c4c4c4",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
                background:"white",
                color:"black"
              }}
            >
              <div>3</div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold" }}
            >
              Publish
            </div>
          </div>
      </div>
      <p className="fs-xs text-white mt-2" style={{fontFamily:"Clash Display Light",opacity:"0.7"}}> Choose one or more platforms that you want to create compatible 3D assets for. WRBL will generate the appropriate files and formats for each platform.</p>


      <div className="d-flex flex-wrap justify-content-center align-items-center upload-contents" >
        {/* <div className='d-flex'> */}

        <div className="platforms-checkbox-container" data-tour='platform'>
          <label
            className="platforms-checkbox"
            style={{ outline: platforms.zepeto ? "#b0edba solid 2px" : "none", background:platforms.zepeto ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030" }}
            htmlFor="customCheckbox3"
          >
            <img src={zepetoImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Zepeto
            </span>
            <div
              className={
                platforms.zepeto ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a .zepeto file that can be directly uploaded on zepeto
              studio
            </div>
          </label>

          <input
            id="customCheckbox3"
            checked={platforms.zepeto}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="zepeto"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{
              outline: platforms.sandbox ? "#b0edba solid 2px" : "none",
              background:platforms.sandbox ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
            }}
            htmlFor="customCheckbox1"
          >
            <img src={sandboxImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Sandbox
            </span>
            <div
              className={
                platforms.sandbox ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a vox file that can be configured in vox edit and added
              to the sandbox marketplace
            </div>
          </label>
          <input
            id="customCheckbox1"
            checked={platforms.sandbox}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="sandbox"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{
              outline: platforms.roblox_layered ? "#b0edba solid 2px" : "none",
              background:platforms.roblox_layered ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
            }}
            htmlFor="customCheckbox6"
          >
            <img src={robloxRedImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Roblox Layered Clothing
            </span>
            <div
              className={
                platforms.roblox_layered
                  ? "pl-checkboxes check"
                  : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a .rbxm file that can be uploaded on roblox as a UGC
              category garment
            </div>
          </label>

          <input
            id="customCheckbox6"
            checked={platforms.roblox_layered}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="roblox_layered"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{
              outline: platforms.roblox_classic ? "#b0edba solid 2px" : "none",
              background:platforms.roblox_classic ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
            }}
            htmlFor="customCheckbox7"
          >
            <img src={robloxBlackImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Roblox Classic Clothing
            </span>
            <div
              className={
                platforms.roblox_classic
                  ? "pl-checkboxes check"
                  : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a set of png files which can be uploaded to the roblox
              marketplace as shirts or pants category
            </div>
          </label>

          <input
            id="customCheckbox7"
            checked={platforms.roblox_classic}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="roblox_classic"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{
              outline: platforms.decentraland ? "#b0edba solid 2px" : "none",
              background:platforms.decentraland ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
            }}
            htmlFor="customCheckbox5"
          >
            <img src={decentralandImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Decentraland
            </span>
            <div
              className={
                platforms.decentraland ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a glb file of the garment which can be uploaded to
              decentraland
            </div>
          </label>

          <input
            id="customCheckbox5"
            checked={platforms.decentraland}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="decentraland"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{ outline: platforms.clonex ? "#b0edba solid 2px" : "none",
            background:platforms.clonex ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030" }}
            htmlFor="customCheckbox4"
          >
            <img src={clonexImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Clone X
            </span>
            <div
              className={
                platforms.clonex ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a glb file of the garment which is compatible with any
              clonex rig
            </div>
          </label>

          <input
            id="customCheckbox4"
            checked={platforms.clonex}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="clonex"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{
              outline: platforms.minecraft ? "#b0edba solid 2px" : "none",
              background:platforms.minecraft ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
            }}
            htmlFor="customCheckbox8"
          >
            <img src={minecraftImg} style={{transform:"scale(1.1"}} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Minecraft
            </span>
            <div
              className={
                platforms.minecraft ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a png skin that can be uploaded to minecraft
            </div>
          </label>

          <input
            id="customCheckbox8"
            checked={platforms.minecraft}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="minecraft"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{
              outline: platforms.snapchat ? "#b0edba solid 2px" : "none",
              background:platforms.snapchat ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
            }}
            htmlFor="customCheckbox2"
          >
            <img src={snapchatImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              Snapchat AR
            </span>
            <div
              className={
                platforms.snapchat ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get packaged lens studio folder that can be published on
              snapchat as a lens
            </div>
          </label>
          <input
            id="customCheckbox2"
            checked={platforms.sandbox}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="snapchat"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{ outline: platforms.hq_3d ? "#b0edba solid 2px" : "none",
            background:platforms.hq_3d ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
           }}
            htmlFor="customCheckbox9"
          >
            <img src={clo3dImg} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
              HQ 3d File
            </span>
            <div
              className={
                platforms.hq_3d ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a (.zprj, .fbx) file that can be imported in any 3d
              software
            </div>
          </label>
          <input
            id="customCheckbox9"
            checked={platforms.hq_3d}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="hq_3d"
          />
        </div>

        <div className="platforms-checkbox-container">
          <label
            className="platforms-checkbox"
            style={{ outline: platforms.ready_player_me ? "#b0edba solid 2px" : "none",
            background:platforms.ready_player_me ? "linear-gradient(to bottom, #4d4dff 0%, #02012d 100%)" : "#303030"
           }}
            htmlFor="customCheckbox10"
          >
            <img src={rpm} alt=""></img>
            <span
              className="uc-items-title fs-xs text-white"
              style={{ marginLeft: "0.5em", marginBottom: "0" }}
            >
             Ready Player Me
            </span>
            <div
              className={
                platforms.ready_player_me ? "pl-checkboxes check" : "pl-checkboxes"
              }
            ></div>
            <div
              className="hover-message-alt fs-xxs"
              style={{ visibility: "visible" }}
            >
              You'll get a glb file compatible for male and female ready player me avatars
            </div>
          </label>
          <input
            id="customCheckbox10"
            checked={platforms.ready_player_me}
            onChange={handleClick}
            type="checkbox"
            style={{ cursor: "pointer", display: "none" }}
            name="ready_player_me"
          />
        </div>
        {/* </div> */}

        {/* <div className='d-flex'> */}

        {/* </div> */}
      </div>

      <PriceEstimation platforms={platforms} />
    </div>
  );
};

export default PlatformSelect;
