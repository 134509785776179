import React, { useEffect, useState } from "react";
import DataTable from "../../../src/DataTable/DataTable";
import { Button } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import { Formik } from "formik";
import Pagination from "../../../src/DataTable/Pagination";
import "./update-files.css";
import "./update-info.css";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
const CustomLoader = () => (
  <div style={{ padding: "24px" }}>
    <Spinner />
  </div>
);
export default function MyComponent(props) {
  const { brandx } = useParams();

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.status;
    const b = rowB.status;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const caseNumberDays = (rowA, rowB) => {
    const a = rowA.approximateTime ? rowA.approximateTime : 0;
    const b = rowB.approximateTime ? rowB.approximateTime : 0;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const caseNumberProgress = (rowA, rowB) => {
    const a = rowA.progress ? rowA.progress : 0;
    const b = rowB.progress ? rowB.progress : 0;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    headRow: {
      style: {
        minHeight: "52px",
        borderBottomWidth: "1px",
        borderBottomColor: "rgba(137, 137, 137, 0.3)",
        borderBottomStyle: "solid",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    pagination: {
      style: {
        fontFamily: "Clash Display Medium",
        fontSize: "14px",
        textAlign: "center",
        color: "white",
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Clash Display Medium",
        justifyContent: "center",
        color: "white",
        backgroundColor: "#1E1D46",
        minHeight: "100px",
      },
    },
    rows: {
      style: {
        fontSize: "13px",
        fontWeight: 400,
        color: "white",
        minHeight: "100px !important",
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center !important",
        borderBottomWidth: ".5px !important",
        borderBottomColor: "rgba(137, 137, 137, 0.3) !important",
        borderBottomStyle: "solid",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "gray",
        },
      },
      progress: {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#1E1D46",
        },
      },
    },
  };

  const columns = [
    {
      name: "THUMBNAIL",
      selector: "thumbnail",
      style: {
        maxWidth: "90%",
        padding: 0,
        fontFamily: "Clash Display Medium",
      },
      center: true,
      grow: 0.5,
      cell: (row) => (
        <>
          {row.thumbnail && row.thumbnail != "" ? (
            <img
              src={row.thumbnail}
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <div
              style={{
                height: "30px",
                width: "30px",
                backgroundColor: "black",
                alignSelf: "center",
                justifySelf: "center",
                fontSize: "20px",
                fontWeight: "bolder",
                color: "gray",
                textAlign: "center",
                fontFamily: "Clash Display Medium",
              }}
            >
              {row.assetName
                .split(" ")
                .slice(0, 1)
                .map((n) => n[0])
                .join("")
                .toUpperCase()}
            </div>
          )}
        </>
      ),
    },
    {
      name: "NAME",
      selector: "assetName",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          <Link to={`/admin/brands/${brandx}/${row.assetName}`}>
            {row.assetName.charAt(0).toUpperCase() + row.assetName.slice(1)}
          </Link>
        </>
      ),
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "N0.OF DAYS",
      selector: "approximateTime",
      sortable: true,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          {!["Under Review", "Draft"].includes(row.status) ? (
            <h6 className="text-white">{row.approximateTime}</h6>
          ) : (
            <h6 className="text-white">---</h6>
          )}
        </>
      ),
      sortFunction: caseNumberDays,
    },

    {
      name: "PROGRESS",
      selector: "progress",
      sortable: true,
      grow: 1.5,
      style: {
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      sortFunction: caseNumberProgress,
      cell: (row) => (
        <div className="d-flex flex-column w-100 m-0">
          <div className="progress-container d-flex " style={{ width: "100%" }}>
            <div
              className="asset-progress"
              style={{
                background: "#302DDE",
                width: "90%",
                marginBottom: "2%",
              }}
            >
              <div
                className="progress-bar1"
                style={{ width: `${row.progress + 4}%` }}
              >
                <div className="progress-bar-ball"></div>
              </div>
            </div>
          </div>
          <p
            className="text-white text-center"
            style={{ fontSize: "12px", fontWeight: "bolder", marginBottom: 0 }}
          >
            {row.progress}/100
          </p>
        </div>
      ),
    },
    {
      name: "STATUS",
      selector: "status",
      sortable: true,
      style: {
        padding: 0,
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      cell: (row) => (
        <>
          <div
            style={{
              backgroundColor: `${
                row.status === "Under Review"
                  ? "rgba(238,130,238,.1)"
                  : row.status === "Draft"
                  ? "rgba(128,128,128,.1)"
                  : row.status === "Action Required"
                  ? "rgba(255,0,0,.1)"
                  : row.status === "Pending payment"
                  ? "rgba(255,255,204,.1)"
                  : row.status === "In Progress"
                  ? "rgba(0,0,255,.1)"
                  : "rgba(0,255,0,.1)"
              }`,
              borderRadius: "10px",
            }}
          >
            {row.status === "Pending payment" ? (
              <p
                className="d-flex flex-column align-items-center justify-content-center"
                style={{
                  color: `${
                    row.status === "Under Review"
                      ? "violet"
                      : row.status === "Draft"
                      ? "gray"
                      : row.status === "Action Required"
                      ? "red"
                      : row.status === "Pending payment"
                      ? "yellow"
                      : row.status === "In Progress"
                      ? "blue"
                      : "green"
                  }`,
                  padding: "5px 8px",
                  marginBottom: "0",
                }}
              >
                {row.status} {` ($${row.actualPrice})`}{" "}
              </p>
            ) : (
              <p
                style={{
                  color: `${
                    row.status === "Under Review"
                      ? "violet"
                      : row.status === "Draft"
                      ? "gray"
                      : row.status === "Action Required"
                      ? "red"
                      : row.status === "Pending payment"
                      ? "yellow"
                      : row.status === "In Progress"
                      ? "blue"
                      : "green"
                  }`,
                  padding: "5px 8px",
                  marginBottom: "0",
                }}
              >
                {row.status}
              </p>
            )}
          </div>
          {/* <p style={{color: `${row.status ==== "Under Review" ? "violet" : row.status ==== "Draft" ? "gray" : "green"}`}}>{row.status}</p> */}
        </>
      ),
    },
    {
      name: "EDIT",
      style: {
        padding: 0,
        fontFamily: "Clash Display Medium",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        display: "block",
      },
      grow: 0.5,
      cell: (row) => (
        <>
          <h6 className="text-white" onClick={() => handleView(row)}>
            <BiEdit />
          </h6>
        </>
      ),
    },
  ];

  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [asset, setasset] = React.useState();

  const handleView = (row) => {
    setasset(row);
    console.log(row);
    setViewModal(true);
  };
  const [assetList, setAssetList] = useState([]);
  const filteredItems = data.filter(
    (item) =>
      item.assetName &&
      item.assetName.toLowerCase().includes(props.filterText.toLowerCase())
  );
  const fetchAsset = async () => {
    await axios
      .post("https://api.xrcouture.com/brands/assets", {
        brand: brandx,
      })
      .then((res) => {
        setData(res.data.assets);
      });
  };

  useEffect(() => {
    setPending(true);
    fetchAsset();
    const timeout = setTimeout(() => {
      setPending(false);
    }, 1000);
    data.map((r) => {
      if (r.status == "Pending Payment") {
        r["selected"] = true;
      }
    });
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setAssetList(filteredItems);
  }, [data]);

  const [filterState, setFilterState] = useState("All");

  const filteredItemsByState = data.filter(function (item) {
    return item.status == filterState;
  });

  useEffect(() => {
    console.log("changed");
    if (filterState != "All") {
      setAssetList(filteredItemsByState);
    } else {
      setAssetList(data);
    }
  }, [filterState]);

  useEffect(() => {
    setAssetList(filteredItems);
  }, [props.filterText]);

  const update = (values) => {
    alert(values.progress)
    const successalt = (a, x) => {
      Swal.fire({
        position: "center",
        icon: a,
        title: x,
        showConfirmButton: false,
        timer: 1500,
      });
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `You want to Update this `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        setLoading(true);
        if (result.isConfirmed) {
          console.log({
            ...values,
            name: asset.assetName,
            brand: data.brandName,
          });
          await axios
            .post("https://api.xrcouture.com/admin/update", {
              time: values.days,
              status: values.status,
              budget: values.budget,
              remarks: values.remarks,
              progress:
                values.status === "Under Review"
                  ? 10
                  : values.status === "Pending payment"
                  ? 25
                  : values.status === "Completed"
                  ? 100
                  : values.progress,
              name: asset.assetName,
              extraDays: values.extraDays,
              brand: asset.brandName,
            })
            .then((res) => {
              setPending(true);
              fetchAsset();
              setAssetList(filteredItems);
              setPending(false);
              swalWithBootstrapButtons.fire(
                "Update!",
                "Asset has been Updated.",
                "success"
              );
              setViewModal(false);
            })
            .catch((e) => alert(e));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          successalt("error", "Cancelled");
        }
        setLoading(false);
      });
  };
  const [pageToggle, setPageToggle] = useState(true);

  const [files, setfiles] = useState({
    zepeto: null,
    sandbox: null,
    clonex: null,
    snapchat: null,
    decentraland: null,
    roblox:null,
  });
  const updateFiles = () => {
    const successalt = (a, x) => {
      Swal.fire({
        position: "center",
        icon: a,
        title: x,
        showConfirmButton: false,
        timer: 1500,
      });
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `You want to Upload this files`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let formdata = new FormData();
          formdata.append("name", asset.assetName);
          formdata.append("brand", asset.brandName);
          // formdata.append('platform',asset.platform)
          asset.platform.map((p) => {
            formdata.append("platform", p);
          });
          asset.platform.map((p) => {
            console.log(files[p]);
            formdata.append("assets", files[p]);
          });
          console.log(formdata)
          await axios
            .post("https://api.xrcouture.com/admin/upload", formdata)
            .then((res) => {
              setPending(true);
              fetchAsset();
              setAssetList(filteredItems);
              setPending(false);
              swalWithBootstrapButtons.fire(
                "Updated!",
                "Asset has been Updated.",
                "success"
              );
              setViewModal(false);
            })
            .catch((e) => alert(e));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          successalt("error", "Cancelled");
        }
      });
  };
  const handleUpload = async () => {
    console.log(files);
    let formdata = new FormData();
    formdata.append("name", asset.assetName);
    formdata.append("brand", asset.brandName);
    formdata.append("platform", asset.platform);
    asset.platform.map((p) => {
      console.log(files[p]);
      formdata.append("assets", files[p]);
    });
    await axios
      .post("https://api.xrcouture.com/admin/upload", formdata)
      .then((res) => {});
    console.log([...formdata]);
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="p-2 mb-4" style={{ zIndex: "-5" }}>
      <div className="tabs-container d-flex justify-content-between">
        <h3
          style={{
            color: "white",
            float: "left",
            fontFamily: "Clash Display Medium",
          }}
        >
          All Assets
        </h3>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          direction="down"
          className="dropdown-status"
          style={{ float: "right" }}
        >
          <DropdownToggle
            caret
            style={{ fontSize: "18px", fontFamily: "Clash Display Medium" }}
          >
            <span style={{ opacity: 0.5 }}>Status</span> :{" "}
            <span style={{ opacity: 0.7 }}>{filterState}</span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              style={{ width: "unset", fontFamily: "Clash Display Medium" }}
              className={filterState == "All" ? "active-filter" : ""}
              onClick={() => setFilterState("All")}
            >
              All
            </DropdownItem>
            <DropdownItem
              style={{ width: "unset", fontFamily: "Clash Display Medium" }}
              className={filterState == "Draft" ? "active-filter" : ""}
              onClick={() => setFilterState("Draft")}
            >
              Draft
            </DropdownItem>
            <DropdownItem
              style={{ width: "unset", fontFamily: "Clash Display Medium" }}
              className={filterState == "Under Review" ? "active-filter" : ""}
              onClick={() => setFilterState("Under Review")}
            >
              Under Review
            </DropdownItem>
            <DropdownItem
              style={{ width: "unset", fontFamily: "Clash Display Medium" }}
              className={
                filterState == "Action Required" ? "active-filter" : ""
              }
              onClick={() => setFilterState("Action Required")}
            >
              Action Required
            </DropdownItem>
            <DropdownItem
              style={{ width: "unset", fontFamily: "Clash Display Medium" }}
              className={
                filterState == "Pending payment" ? "active-filter" : ""
              }
              onClick={() => setFilterState("Pending payment")}
            >
              Payment Pendiing
            </DropdownItem>
            <DropdownItem
              style={{ width: "unset", fontFamily: "Clash Display Medium" }}
              className={filterState == "Completed" ? "active-filter" : ""}
              onClick={() => setFilterState("Completed")}
            >
              Completed
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="tables" style={{ position: "relative" }}>
        <DataTable
          columns={columns}
          className="data-table"
          data={assetList}
          progressPending={pending}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginationResetDefaultPage={resetPaginationToggle}
          customStyles={tableCustomStyles}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: "",
            noRowsPerPage: false,
          }}
          paginationComponent={Pagination}
          paginationIconFirstPage={false}
          paginationIconLastPage={false}
          progressComponent={<CustomLoader />}
        />
      </div>
      {asset && (
        <Modal
          size="lg"
          show={viewModal}
          onHide={() => setViewModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title id="example-modal-sizes-title-lg text-white">
              {asset.assetName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="d-flex flex-column m-5 ">
                <Formik
                  initialValues={{
                    days: asset && asset.actualTime ? asset.actualTime : 0,
                    extraDays: asset && asset.extraDays ? asset.extraDays : 0,
                    status: asset.status,
                    budget: asset.actualPrice ? asset.actualPrice : 0,
                    progress: asset.progress,
                    remarks: asset && asset.remarks ? asset.remarks : "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    
                    if (values.status === "Completed") {
                      updateFiles();
                    } else {
                      update(values);
                    }
                    setLoading(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="m-3">
                        <label className="d-flex align-items-center">
                          <span>
                            <h4
                              className="m-0"
                              style={{ width: "10rem", color: "white" }}
                            >
                              {" "}
                              Status:{" "}
                            </h4>
                          </span>{" "}
                          &nbsp; &nbsp;
                          {/* <input className='input-style' type="text" name="name" onChange={""} /> */}
                          <select
                            class="form-select form-select-lg mb-3"
                            aria-label=".form-select-lg example"
                            name="status"
                            value={values.status}
                            onChange={(e) => {
                              // handleChange()
                              setFieldValue("status", e.target.value);
                              if (values.status == "Draft") {
                                setFieldValue("progress", 0);
                              } else if (values.status == "Under Review") {
                                setFieldValue("progress", 10);
                              } else if (values.status == "Pending payment") {
                                setFieldValue("progress", 25);
                              } else if (values.status == "Completed") {
                                setFieldValue("progress", 100);
                              }
                            }}
                            onBlur={handleBlur}
                          >
                            <option selected disabled>
                              Select the status
                            </option>
                            <option value="Draft">Draft</option>
                            <option value="Under Review">Under Review</option>
                            <option value="Action Required">
                              Action Required
                            </option>
                            <option value="Pending payment">
                              Pending payment
                            </option>
                            <option value="In Progress">In Progress</option>
                            <option value="Completed">Completed</option>
                          </select>
                        </label>
                      </div>
                      <div className="m-3">
                        <label className="d-flex align-items-center">
                          <span>
                            <h4
                              className="m-0"
                              style={{ width: "10rem", color: "white" }}
                            >
                              {" "}
                              Progress:{" "}
                            </h4>
                          </span>{" "}
                          &nbsp; &nbsp;
                          <input
                            class="form-control form-control-lg"
                            type="number"
                            aria-label=""
                            name="progress"
                            disabled={[
                              "Draft",
                              "Under Review",
                              "Action Required",
                              "Pending payment",
                              "Completed",
                            ].includes(values.status)}
                            value={
                              values.status === "Draft"
                                ? 0
                                : values.status === "Under Review"
                                ? 10
                                : values.status === "Pending payment"
                                ? 25
                                : values.status === "Completed"
                                ? 100
                                : values.progress
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min={0}
                            max={100}
                            required
                          />
                        </label>
                      </div>

                      {["In Progress", "Pending payment"].includes(
                        values.status
                      ) && (
                        <div className="m-3">
                          <label className="d-flex align-items-center">
                            <span>
                              <h4
                                className="m-0"
                                style={{ width: "10rem", color: "white" }}
                              >
                                {" "}
                                No.of Days:{" "}
                              </h4>
                            </span>{" "}
                            &nbsp; &nbsp;
                            <input
                              class="form-control form-control-lg"
                              type="number"
                              aria-label=""
                              name="days"
                              min={0}
                              value={values.days}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </label>
                        </div>
                      )}
                      {values.status === "Pending payment" && (
                        <div className="m-3">
                          <label className="d-flex align-items-center">
                            <span>
                              <h4
                                className="m-0"
                                style={{ width: "10rem", color: "white" }}
                              >
                                {" "}
                                Price:{" "}
                              </h4>
                            </span>{" "}
                            &nbsp; &nbsp;
                            <input
                              class="form-control form-control-lg"
                              type="number"
                              aria-label=""
                              name="budget"
                              value={values.budget}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </label>
                        </div>
                      )}
                      {values.status === "In Progress" && (
                        <div className="m-3">
                          <label className="d-flex align-items-center">
                            <span>
                              <h4
                                className="m-0"
                                style={{ width: "10rem", color: "white" }}
                              >
                                {" "}
                                Extra days:{" "}
                              </h4>
                            </span>{" "}
                            &nbsp; &nbsp;
                            <input
                              class="form-control form-control-lg"
                              type="number"
                              aria-label=""
                              name="extraDays"
                              value={values.extraDays}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </label>
                        </div>
                      )}

                      {values.status === "Action Required" && (
                        <div className="m-3">
                          <label className="d-flex align-items-center">
                            <span>
                              <h4
                                className="m-0"
                                style={{ width: "10rem", color: "white" }}
                              >
                                {" "}
                                Remarks:{" "}
                              </h4>
                            </span>{" "}
                            &nbsp; &nbsp;
                            <textarea
                              class="form-control form-control-lg"
                              type="text"
                              aria-label=""
                              name="remarks"
                              value={values.remarks}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </label>
                        </div>
                      )}
                      {values.status === "Completed" &&
                        asset.platform.map((i) => (
                          <div className="m-3">
                            <label className="d-flex align-items-center">
                              <span>
                                <h4
                                  className="m-0 text-white"
                                  style={{ width: "15rem" }}
                                >
                                  {" "}
                                  {i.toLocaleUpperCase()}:{" "}
                                </h4>
                              </span>{" "}
                              &nbsp; &nbsp;
                              <input
                                class="form-control form-control-lg"
                                required
                                id="formFileLg"
                                type="file"
                                value={files[i]}
                                onChange={(e) => (files[i] = e.target.files[0])}
                              />
                            </label>
                          </div>
                        ))}
                      <div className="m-3 d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn form-submit mt-4 text-center"
                          style={{
                            marginRight: "1rem",
                            background: "black",
                            color: "white",
                          }}
                        >
                          {!loading ? "Update" : "Loading"}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
