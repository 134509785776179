import React, { useEffect, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import "./OneTimeVideo.css";
import { Link } from "react-router-dom";

const OneTimeVideo = () => {
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [isSubmitting, setIsSubmitting] = useState(null);

  const [err, setErr] = useState("");

  const [videoURL, setVideoURL] = useState(false);
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    setIsSubmitting(true);

    axios
      .post(
        "https://api.xrcouture.com/brands/accessvideo",
        {
          email: email,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res)
        setIsSubmitting(false);
        setVideoURL(res.data.url);
        setIsAuth(true);
      })
      .catch((err) => {
        // console.log(err)
        setIsSubmitting(false);
        setIsAuth(false);

        if (err.response) {
          if (err.response.data.msg === "Invalid") {
            setErr(
              <>
                The email address is not registered. Please click{" "}
                <a
                  rel="noreferrer"
                  className="text-primary"
                  target={"_blank"}
                  href="https://xrcouture.com"
                >
                  here
                </a>{" "}
                to register.
              </>
            );
          } else if (err.response.data.msg === "Expired") {
            setErr(
              "The link is expired for this email address. Please contact us at hello@xrcouture.com"
            );
          } else {
            setErr(
              "Something went wrong, Please try again later. Or contact us at hello@xrcouture.com"
            );
          }
        } else {
          setErr(
            "Something went wrong, Please try again later. Or contact us at hello@xrcouture.com"
          );
        }
      });
  }, []);

  return (
    <div
      className="otv-app-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isSubmitting ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80%" }}
        >
          <PuffLoader color={"white"} size={80} />
        </div>
      ) : isSubmitting === false && isAuth ? (
        <div className="text-center">
          <ReactHlsPlayer
            src={videoURL}
            autoPlay={false}
            controls={true}
            width="70%"
            height="auto"
            style={{ border: "1px solid white", minWidth: "240px" }}
            className="mt-xxl-5"
          />
        </div>
      ) : (
        isSubmitting === false &&
        isAuth === false && (
          <div
            className="text-center d-flex justify-content-center align-items-center"
            style={{ height: "80%" }}
          >
            <div
              className="text-center p-3 m-4 p-sm-5 m-sm-5"
              style={{
                border: "1px solid white",
                borderRadius: "0.6em",
                fontFamily: "Clash Display Medium",
              }}
            >
              <span
                style={{ fontFamily: "Clash Display Medium" }}
                className="text-white fs-m"
              >
                You have reached the maximum number of times to view this video.{" "}
                <br /> If you wish to learn more-{" "}
              </span>
              <Link
                target="_blank"
                to={"https://calendly.com/xrcouture/wrbl"}
                className="fs-m"
              >
                Book a demo
              </Link>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default OneTimeVideo;
