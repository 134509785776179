import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Loading from "../Loading";

const RouteNotDefined = () => {
  const { brandx } = useParams();

  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [role, setRole] = useState("");
  const [brand, setBrand] = useState("");

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.post(
          "https://api.xrcouture.com/auth/authorize",
          {
            email: localStorage.getItem("email"),
            brand: localStorage.getItem("brand"),
          },
          { withCredentials: true }
        );
        console.log(response);
        setIsAuthenticated(response.data.isUserAuthorized);
        setRole(response.data.role);
        setRole(response.data.role);
        setBrand(response.data.brand);
      } catch (error) {
        console.error(error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    if (isAuthenticated && role === "brands") {
      navigate(`/brands/${brand}`);
    } else if (isAuthenticated && role === "admin") {
      navigate(`/admin/brands`);
    } else if (isAuthenticated === false) {
      navigate("/signup");
    }
  }, [isAuthenticated, role, brand, navigate, brandx]);

  console.log("Is user authenticated? ", isAuthenticated);

  return isAuthenticated === null && <Loading />;
};

export default RouteNotDefined;
