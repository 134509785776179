import styled, { css } from "styled-components";
import { media } from "./media";
export const CellBase = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  ${({ theme, headCell }) => theme[headCell ? "headCells" : "cells"].style};
  ${({ noPadding }) => noPadding && "padding: 0"};
`;
// Flex calculations
export const CellExtended = styled(CellBase)`
  flex-grow: ${({ button, grow }) => (grow === 0 || button ? 0 : grow || 1)};
  flex-shrink: 0;
  flex-basis: 0;
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
  min-width: ${({ minWidth }) => minWidth || "100px"};
  ${({ width }) =>
    width &&
    css`
      min-width: ${width};
      max-width: ${width};
    `};
  ${({ right }) => right && "justify-content: flex-end"};
  ${({ button, center }) => (center || button) && "justify-content: center"};
  ${({ compact, button }) => (compact || button) && "padding: 0"};

  /* handle hiding cells */
  ${({ hide }) =>
    hide &&
    hide === "sm" &&
    media.sm`
    display: none;
  `};
  ${({ hide }) =>
    hide &&
    hide === "md" &&
    media.md`
    display: none;
  `};
  ${({ hide }) =>
    hide &&
    hide === "lg" &&
    media.lg`
    display: none;
  `};
  ${({ hide }) =>
    hide &&
    Number.isInteger(hide) &&
    media.custom(hide)`
    display: none;
  `};
`;
