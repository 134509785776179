import React from "react";
import "./Waitlist.css";

const Waitlist = () => {
  return (
    <div className="waitlist-body d-flex flex-column">
      <div
        className="waitlist-title text-white mb-5"
        style={{ fontSize: "2.5rem", fontFamily: "Clash Display SemiBold" }}
      >
        You are on the waitlist!
      </div>
      <div
        className="waitlist-msg"
        style={{
          border: "1px solid white",
          borderRadius: "0.6rem",
          color: "white",
          padding: "3rem",
        }}
      >
        Thank you for signing up for our waitlist! We appreciate your interest
        and support. You will receive an email from us once you have access to
        WRBL. We can’t wait for you to join us!
      </div>
    </div>
  );
};

export default Waitlist;
