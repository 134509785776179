import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./Support.css";
import { useParams } from "react-router";
import { Context } from "../../Context";

import Swal from "sweetalert2";

const CreateTicketModal = (props) => {
  console.log(props);

  const { brandx } = useParams();

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState(props.assetNames);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    setOptions(props.assetNames);
  }, [props.assetNames]);

  const [creating, setCreating] = useState(false);

  const [imageArray, setImageArray] = useState([]);
  const [imageString, setImageString] = useState("");

  // update the list
  const { update, setUpdate } = useContext(Context);

  const [descToggle, setDescToggle] = useState(true);

  const [selectedfile, SetSelectedFile] = useState([]);

  function handleChange(event) {
    setSelectedOption(event.target.value);
  }

  const handleSubjectOnChange = (event) => {
    setSubject(event.target.value);
  };

  const handleDescriptionOnChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCreateTicket = async (event) => {
    event.preventDefault();

    setCreating(true);

    var formData = new FormData();

    formData.append("brandName", brandx);
    formData.append("title", subject);
    formData.append("message", description);

    var filesArray = [];
    filesArray = selectedfile.map((item) => item.fileData);

    for (let i = 0; i < filesArray.length; i++) {
      formData.append("image", filesArray[i]);
    }

    formData.append("assetName", selectedOption);

    console.log([...formData]);

    await axios
      .post("https://api.xrcouture.com/support/create", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        setCreating(false);
        props.closeModalFun();
        setUpdate((prev) => !prev);
        supportModal(
          "Support query received",
          "We will get back to you soon.",
          "success"
        );
      })
      .catch((err) => {
        console.log(err);
        setCreating(false);
        props.closeModalFun();
        supportModal(
          "Something went wrong",
          "Please try again. Or reach out to us at hello@xrcouture.com",
          "error"
        );
      });
  };

  const supportModal = (header, msg, icons) => {
    Swal.fire({
      title: header,
      text: msg,
      icon: icons,
      // showCancelButton: true,
      confirmButtonColor: "rgb(78, 75, 222)",
      // cancelButtonColor: 'rgb(40 39 106)',
      confirmButtonText: "Close",
      // cancelButtonText: "Discard changes",
      reverseButtons: true,
      allowOutsideClick: true,
      customClass: {
        title: "text-white",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setSubject("");
        setDescription("");
        setSelectedOption(options[0]);
      }
    });
  };

  const InputChange = (e) => {
    e.preventDefault();

    // check the file quant should be less than or equals to 10 *done
    // check that already how many files are uploaded *done

    // console.log(e.target.files.length)

    if (
      e.target.files.length > 10 ||
      selectedfile.length === 10 ||
      e.target.files.length + selectedfile.length > 10
    ) {
      alert("You can only upload upto 10 files!");
      return;
    }

    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        // check file type : e.target.files[i].type
        // check file size : e.target.files[i].size *done

        // if (!isValidFileUploadedAdmin(e.target.files[i])) {
        //   alert(file.name + "This file is not supported")
        //   return;
        // }
        // if (!isValidFileUploaded(e.target.files[i])) {
        //   alert(file.name + "This file is not supported")
        //   return;
        // }

        if (e.target.files[i].size > 20971520) {
          alert("Please not this file: " + file.name);
          return;
        }

        SetSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              // id: shortid.generate(),
              filename: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileimage: reader.result,
              datetime:
                e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
              // filesize: filesizes(e.target.files[i].size),
              fileData: e.target.files[i],
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    setImageString(imageArray.map((file) => file.file.name).join(", "));
  }, [imageArray, setImageString]);

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };

  return (
    <div className="create-ticket-modal d-flex flex-column">
      <div className="create-ticket-modal-cont">
        <div className="d-flex justify-content-between align-items-center mb-4 pb-2">
          <div
            className="text-white fs-lg"
            style={{ fontFamily: "Clash Display Bold" }}
          >
            New Ticket
          </div>
          <div
            className="text-white fs-sm"
            style={{ fontFamily: "Clash Display Bold", cursor: "pointer" }}
            onClick={props.closeModalFun}
          >
            X
          </div>
        </div>

        <form onSubmit={handleCreateTicket} className="d-flex flex-column">
          <label className="">
            <div className="uc-items-title fs-xs"> Subject* </div>
            <input
              required
              onChange={handleSubjectOnChange}
              className="uc-items-input support-subject-input fs-sm"
              type="text"
              name="name"
              value={subject}
            />
          </label>

          <label className="mt-4">
            <div className="uc-items-title fs-xs"> Link Asset </div>
            <select
              onChange={handleChange}
              value={selectedOption}
              className="uc-items-input select-input fs-sm"
            >
              {options.map((option) => (
                <option className="fs-sm" key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>

          <label className="mt-4">
            <div className="uc-items-title fs-xs"> Description* </div>
            <div>
              <div className="d-flex desc-options-cont">
                <div
                  className={
                    descToggle === true
                      ? "desc-options active fs-xs"
                      : "desc-options fs-xs"
                  }
                  onClick={() => setDescToggle(true)}
                >
                  Message
                </div>
                <div
                  className={
                    descToggle === true
                      ? "desc-options fs-xs"
                      : "desc-options active fs-xs"
                  }
                  style={{ marginLeft: "1em" }}
                  onClick={() => setDescToggle(false)}
                >
                  Upload Files
                </div>
              </div>

              {descToggle ? (
                <textarea
                  style={{ borderRadius: "0rem 0.6em 0.6em 0.6rem" }}
                  required
                  onChange={handleDescriptionOnChange}
                  rows="4"
                  className="uc-items-input fs-sm"
                  type="text"
                  name="name"
                  maxLength={100}
                  value={description}
                />
              ) : (
                <div
                  className="fileupload-view upload-contents"
                  style={{
                    marginTop: "0",
                    border: "0.1em solid #2E2C6A",
                    background: "#2E2C6A",
                    borderRadius: "0em 0.6em 0.6em",
                  }}
                >
                  <div className="row justify-content-center m-0">
                    <div className="col-md-12">
                      <div className="card" style={{ margin: "0" }}>
                        <div className="card-body">
                          <div className="kb-data-box">
                            <div
                              className="kb-modal-data-title"
                              style={{ margin: "0" }}
                            >
                              <div className="kb-data-title">
                                {/* <h6>Multiple File Upload With Preview</h6> */}
                              </div>
                            </div>
                            {/* <form onSubmit={FileUploadSubmit}> */}
                            <form>
                              <div
                                className="kb-file-upload"
                                style={{
                                  marginBottom: "0",
                                  borderBottom: "1px solid #c4c4c4",
                                }}
                              >
                                <div
                                  className="file-upload-box d-flex flex-column"
                                  style={{ border: "none" }}
                                >
                                  <input
                                    type="file"
                                    id="fileupload"
                                    className="file-upload-input"
                                    onChange={InputChange}
                                    multiple
                                  />
                                  <span className="uc-items-title text-white fs-xs text-center">
                                    Drag and drop or{" "}
                                    <span className="file-link uc-items-link">
                                      Choose your files
                                    </span>
                                  </span>

                                  <div className="uc-items-subtitle fs-xs">
                                    Max file size is 20 MB
                                  </div>
                                </div>
                              </div>
                              <div
                                className="kb-attach-box"
                                style={{
                                  padding: "2em",
                                  background: "#080808",
                                }}
                              >
                                {selectedfile.map((data, index) => {
                                  const {
                                    id,
                                    filename,
                                    filetype,
                                    fileimage,
                                    datetime,
                                    filesize,
                                  } = data;
                                  return (
                                    <div className="file-atc-box" key={id}>
                                      {filename.match(
                                        /.(jpg|jpeg|png|gif|svg)$/i
                                      ) ? (
                                        <div className="file-image">
                                          {" "}
                                          <img src={fileimage} alt="" />
                                        </div>
                                      ) : (
                                        <div className="file-image">
                                          <i className="far fa-file-alt"></i>
                                        </div>
                                      )}
                                      <div className="file-detail text-white">
                                        <h6>{filename}</h6>
                                        <p></p>
                                        <p>
                                          <span>Size : {filesize}</span>
                                          <span className="ml-2">
                                            Modified Time : {datetime}
                                          </span>
                                        </p>
                                        <div className="file-actions">
                                          <button
                                            type="button"
                                            className="file-action-btn"
                                            onClick={() => DeleteSelectFile(id)}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {/* <div className="kb-buttons-box">
                      <button type="submit" className="btn btn-primary form-submit">Submit</button>
                    </div> */}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </label>

          <div className="d-flex justify-content-end">
            <div className="kb-buttons-box mt-3 text-center d-flex mt-5">
              <button type="submit" className="form-submit uc-buttons fs-xs">
                {creating ? "Submitting..." : "Submit"}
              </button>
            </div>

            {/* upload button */}
            {/* <div className="kb-buttons-box mt-3 text-center d-flex mt-5" style={{ marginLeft: "2rem" }}>
            <button className="form-submit uc-buttons" onClick={() => document.getElementById("fileupload").click()}>
              Upload files
            </button>
            <div className='text-secondary' style={{ marginTop: "0.8rem", marginLeft: "0.5rem" }}>
              {
                imageString
              }
            </div>

            <input onChange={InputChange} type="file" id="fileupload" className="file-upload-input" multiple style={{ display: "none" }} />
          </div> */}
          </div>
        </form>
      </div>

      {/* <form onSubmit={handleCreateTicket} className='d-flex flex-column'>
        <label className=''>
          <div className='uc-items-title'> Subject* </div>
          <input required onChange={handleSubjectOnChange} className='uc-items-input' type="text" name="name" style={{ width: "15em" }} value={subject} />
        </label>

        <label className="mt-5">
          <div className="uc-items-title"> Description </div>
          <textarea
            required
            rows="4"
            className="uc-items-input"
            type="text"
            name="name"
            value={description}
            maxLength={100}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>

        <label className="mt-4">
          <div className="uc-items-title"> Link Asset </div>
          <select
            onChange={handleChange}
            value={selectedOption}
            className="uc-items-input select-input"
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>

        <div className="d-flex justify-content-end">
          <div className="kb-buttons-box mt-3 text-center d-flex mt-5">
            <button
              disabled={creating}
              type="submit"
              className="form-submit uc-buttons"
              style={{ background: "rgb(78, 75, 222)" }}
            >
              {creating ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </form> */}
    </div>
  );
};

export default CreateTicketModal;
