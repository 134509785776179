import React, { useContext } from 'react'
import { Context } from '../../Context'
import "./Upload.css"
import { BeatLoader } from 'react-spinners'

const NextButton = (props) => {
  const { submittingState, pager, savingState, terms } = useContext(Context);
  const [submitting, setSubmitting] = submittingState;
  const [saving, setSaving] = savingState;
  const [termsCheck, setTermsCheck] = terms;
  const [page, setPage] = pager;

  return (
    <div className="uc-buttons-container">
      <div className="d-flex uc-buttons-items align-items-start">
        <div className="kb-buttons-box mt-3 text-center d-flex flex-column justify-content-left align-items-center" data-tour='save-draft'>
          <button style={{background: "rgb(78, 75, 222)"}} disabled={!props.canSaveToDraftBtn || saving} type="submit" className={props.canSaveToDraftBtn ? "form-submit uc-buttons fs-xs" : "btn btn-secondary form-submit uc-buttons fs-xs"} onClick={props.saveToDrafts}>
            {saving ?
            <BeatLoader size={8} color="white" /> :
            "Save to drafts"}
          </button>
          <p className="text-danger fs-xs" style={{ marginBottom: "0.7em" }}>
            {props.errorSMsg}
          </p>
        </div>
        <div className="kb-buttons-box mt-3 text-center d-flex flex-column justify-content-left align-items-center" data-tour='next-step'>
          {page !== 3 && <button disabled={submitting} type="submit" className="form-submit uc-buttons fs-xs" onClick={props.handleSumbit} style={{ background: "#4E4BDE" }} id="submit-button">
            {
              submitting ?
              <BeatLoader size={8} color="white" />  :
                page === 3 ?
                  "Submit" :
                  "Next step"
            }
          </button>}
          {page === 3 &&<button disabled={submitting || !termsCheck} type="submit" data-tour='asset-submit' className={`form-submit uc-buttons fs-xs ${!termsCheck ? "btn btn-secondary fs-xs":""}`} onClick={props.handleSumbit} style={{ background: "#4E4BDE" }} id="submit-button">
            {
              submitting ?
              <BeatLoader size={8} color="white" /> :
                page === 3 ?
                  "Submit" :
                  "Next step"
            }
          </button>}
          
          <p className='text-danger fs-xs' style={{ marginBottom: "0.7em" }}>{props.errorMsg}</p>
        </div>
        {/* <div class="spinner-border text-light" role="status" style={{ alignSelf: "center", marginBottom: "-0.3em" }}>
          <span class="sr-only">Loading...</span>
        </div> */}
      </div>
    </div>
  );
};

export default NextButton;
