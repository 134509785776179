import * as React from "react";
import { handleFunctionProps, noop } from "./util";
const defaultComponentName = "input";
const calculateBaseStyle = (disabled) => ({
  fontSize: "18px",
  ...(!disabled && { cursor: "pointer" }),
  padding: 0,
  marginTop: "1px",
  verticalAlign: "middle",
  position: "relative",
  border: "1px solid red",
  width: "15px",
  height: "15px",
});
function Checkbox({
  name,
  component = defaultComponentName,
  componentOptions = { style: {} },
  indeterminate = false,
  checked = false,
  disabled = false,
  onClick = noop,
}) {
  const setCheckboxRef = (checkbox) => {
    if (checkbox) {
      // eslint-disable-next-line no-param-reassign
      checkbox.indeterminate = indeterminate;
    }
  };
  const TagName = component;
  const baseStyle =
    TagName !== defaultComponentName
      ? componentOptions.style
      : calculateBaseStyle(disabled);
  const resolvedComponentOptions = React.useMemo(
    () => handleFunctionProps(componentOptions, indeterminate),
    [componentOptions, indeterminate]
  );
  return (
    <>
      <TagName
        // allow this component to fully control these options
        type="checkbox"
        ref={setCheckboxRef}
        style={baseStyle}
        onClick={disabled ? noop : onClick}
        name={name}
        aria-label={name}
        checked={checked}
        disabled={disabled}
        {...resolvedComponentOptions}
        onChange={noop} // prevent uncontrolled checkbox warnings -  we don't need onChange
      />
    </>
  );
}
export default React.memo(Checkbox);
