import {
  SHOW_MODAL,
  HIDE_MODAL,
  START_CREATING_ASSET,
  STOP_CREATING_ASSET,
  UNSAVED_CHANGES,
  SAVED_CHANGES,
} from './actions';

// Define the initial state
const initialState = {
  showModal: false,
  isCreatingAsset: false,
  createAssetChanges: false,
};

// Define the reducer
export function reducer(state = initialState, action) {
  console.warn(action.type);
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, showModal: true };
    case HIDE_MODAL:
      return { ...state, showModal: false };
    case START_CREATING_ASSET:
      return { ...state, isCreatingAsset: true };
    case STOP_CREATING_ASSET:
      return { ...state, isCreatingAsset: false };
    case UNSAVED_CHANGES:
      return {...state, createAssetChanges: true };
    case SAVED_CHANGES:
      return {...state, createAssetChanges: false };
    default:
      return state;
  }
}
