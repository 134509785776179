import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import Upload from "./Upload";

const Drafts = (props) => {

  const {brandx, assetx} = useParams()
  const [draftInfo, setDraftInfo] = useState()

  useEffect(() => {
    axios
      .post(
        "https://api.xrcouture.com/brands/asset",
        {
          brand: brandx,
          name: assetx,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setDraftInfo(response.data.asset[0]);
      });
  }, []);

  return (
    <Upload draft={draftInfo} dragHighlightColor={props.dragHighlightColor} handleDrop={props.handleDrop}/>
  )
}

export default Drafts;
