import { useContext, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useParams } from "react-router";
import { Context1 } from "../../Context";

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { brandx } = useParams();

  const assetArray = props.assets.map((item) => item.assetName);

  const {credits,setCredits} = useContext(Context1)

  useEffect(() => {
    fetch("https://api.xrcouture.com/payments/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://api.xrcouture.com/payments/create-payment-intent",
        {
          amount: props.amount,
          currency: "INR",
          brandName: brandx,
          metadata: {
            assets: assetArray,
          },
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setClientSecret(response.data.clientSecret);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            brand={brandx}
            assets={assetArray}
            amount={props.amount}
          />
        </Elements>
      )}
    </>
  );
}

export default Payment;
