import React, { useState, useEffect, useContext } from "react";
import { Context, Context1 } from "../Context";

import AssetInfo from "../components/UploadPage/AssetInfo/AssetInfo";
import FileUpload from "../components/UploadPage/FilesUpload/FileUpload";
import PlatformSelect from "../components/UploadPage/PlatformSelection/PlatformSelect";

import NextButton from "../components/UploadPage/NextButton";

import { useSelector, useDispatch } from "react-redux";
import {
  showModal,
  hideModal,
  startCreatingAsset,
  stopCreatingAsset,
  unsavedChanges,
  savedChanges,
} from "../store/actions";

import { toast } from "react-toastify";

import "../components/UploadPage/Upload.css";

import axios from "axios";
import { useParams, useNavigate } from "react-router";

import Swal from "sweetalert2";

const Upload = (props) => {
  // console.log(props)

  const dispatch = useDispatch();

  dispatch(startCreatingAsset());
  const shouldShowModal = useSelector((state) => state.showModal);
  const areThereUnsavedChanges = useSelector(
    (state) => state.createAssetChanges
  );
  // const isCreatingAsset = useSelector(state => state.isCreatingAsset);

  const navigate = useNavigate();

  const { brandx } = useParams();

  // GLOBAL STATE
  const brandName = brandx;

  const [assetName, setAssetName] = useState({
    name: "",
    isUnique: true,
    errorMsg: "",
  });
  const [description, setDescription] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState([]);

  const [selectedfile, SetSelectedFile] = useState([]);
  const [notes,setNotes] = useState("")

  const [platforms, selectPlatforms] = useState({
    zepeto: false,
    sandbox: false,
    roblox_layered: false,
    roblox_classic: false,
    decentraland: false,
    clonex: false,
    minecraft: false,
    snapchat: false,
    hq_3d: false,
    ready_player_me:false,
  });
  const [cost, setCost] = useState(0);
  const [days, setDays] = useState(0);

  const {page, setPage} = useContext(Context1)
  // UTILITY STATES
  // const [page, setPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorSubmitMsg, setErrorSubmitMsg] = useState("");
  const [errorSaveMsg, setErrorSaveMsg] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [preview, setPreview] = useState(null);
  const [termsCheck, setTermsCheck] = useState(false);

  // SAVE TO DRAFT
  const [initialValues, setInitialValues] = useState({
    asset_name: "",
    asset_description: "",
    asset_thumbnailFile: [],
    asset_selectedfiles: [],
    asset_platforms: {
      zepeto: false,
      sandbox: false,
      roblox_layered: false,
      roblox_classic: false,
      decentraland: false,
      clonex: false,
      minecraft: false,
      snapchat: false,
      hq_3d: false,
    },
    notes:""
  });

  const areFilesEqual = (files1, files2) => {
    if (files1.length !== files2.length) {
      return false;
    }

    for (let i = 0; i < files1.length; i++) {
      if (
        files1[i].name !== files2[i].name ||
        files1[i].size !== files2[i].size ||
        files1[i].type !== files2[i].type ||
        files1[i].lastModified !== files2[i].lastModified
      ) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (
      assetName.name !== initialValues.asset_name ||
      description !== initialValues.asset_description ||
      !areFilesEqual(thumbnailFile, initialValues.asset_thumbnailFile) ||
      !areFilesEqual(selectedfile, initialValues.asset_selectedfiles) ||
      JSON.stringify(platforms) !==
        JSON.stringify(initialValues.asset_platforms)
    ) {
      dispatch(unsavedChanges());
      console.log("Changes", true);
    } else {
      dispatch(savedChanges());
      console.log("Changes", false);
    }
  }, [
    assetName,
    description,
    thumbnailFile,
    selectedfile,
    platforms,
    initialValues,
  ]);

  // DRAFT

  useEffect(() => {
    if (props.draft) {
      setIsDraft(true);

      var newValuesFromDraft = {
        asset_name: "",
        asset_description: "",
        asset_thumbnailFile: [],
        asset_selectedfiles: [],
        asset_platforms: {
          zepeto: false,
          sandbox: false,
          roblox_layered: false,
          roblox_classic: false,
          decentraland: false,
          clonex: false,
          minecraft: false,
          snapchat: false,
          hq_3d: false,
        },
        notes: ""
      };

      setIsDraft(true);

      const myDraft = props.draft;
      console.log(myDraft);
      setPage(myDraft.draftPage);

      setAssetName({
        name: myDraft.assetName,
        isUnique: true,
        errorMsg: "",
      });

      newValuesFromDraft.asset_name = myDraft.assetName;

      if (myDraft.description) {
        setDescription(myDraft.description);
        newValuesFromDraft.asset_description = myDraft.description;
      }

      if (myDraft.thumbnail && myDraft.thumbnail.length) {
        const s3Link = myDraft.thumbnail[0];
        console.log(s3Link);

        const createFileFromS3Link = async (s3Link) => {
          console.warn(s3Link);
          // Extract file name from S3 link
          let fileName = s3Link.split("/").pop();
          fileName = fileName.split("_").pop();

          // Fetch image data from S3 link
          const response = await fetch(s3Link);
          const imageData = await response.blob();

          // Determine MIME type based on file extension
          const fileExtension = fileName.split(".").pop().toLowerCase();
          let mimeType;
          switch (fileExtension) {
            case "jpg":
            case "jpeg":
              mimeType = "image/jpeg";
              break;
            case "png":
              mimeType = "image/png";
              break;
            case "gif":
              mimeType = "image/gif";
              break;
            case "webp":
              mimeType = "image/webp";
              break;
            default:
              mimeType = imageData.type;
              break;
          }

          // Create a new File object with the extracted file name
          const newFile = new File([imageData], fileName, { type: mimeType });

          console.log(newFile);
          setThumbnailFile([newFile]);
          newValuesFromDraft.asset_thumbnailFile = [newFile];

          if (newFile) {
            const reader = new FileReader();

            reader.onload = function (e) {
              setThumbnailFile([newFile]);
              setPreview(e.target.result);
            };

            reader.readAsDataURL(newFile);
          }
        };

        createFileFromS3Link(s3Link);
      }

      // FILES
      const s3Links = myDraft.brandAssetFiles;
      console.log(s3Links);

      const createFileFromS3Links = async (s3Links) => {
        console.warn(s3Links);

        s3Links.map(async (s3Link) => {
          // Extract file name from S3 link
          const fileName = s3Link.split("/").pop();

          // Fetch image data from S3 link
          const response = await fetch(s3Link);
          const imageData = await response.blob();

          // Determine MIME type based on file extension
          const fileExtension = fileName.split(".").pop().toLowerCase();
          let mimeType;
          switch (fileExtension) {
            case "jpg":
            case "jpeg":
              mimeType = "image/jpeg";
              break;
            case "png":
              mimeType = "image/png";
              break;
            case "gif":
              mimeType = "image/gif";
              break;
            case "webp":
              mimeType = "image/webp";
              break;
            default:
              mimeType = imageData.type;
              break;
          }

          // Create a new File object with the extracted file name
          const newFile = new File([imageData], fileName, { type: mimeType });

          console.log(newFile);

          if (newFile) {
            const reader = new FileReader();

            reader.onload = function (e) {
              SetSelectedFile((preValue) => {
                return [
                  ...preValue,
                  {
                    fileData: newFile,
                    filename: newFile.name,
                    filetype: newFile.type,
                    fileimage: reader.result,
                    datetime: newFile.lastModifiedDate.toLocaleString("en-IN"),
                  },
                ];
              });

              newValuesFromDraft.asset_selectedfiles.push({
                fileData: newFile,
                filename: newFile.name,
                filetype: newFile.type,
                fileimage: reader.result,
                datetime: newFile.lastModifiedDate.toLocaleString("en-IN"),
              });
            };

            reader.readAsDataURL(newFile);
          }
        });
      };

      createFileFromS3Links(s3Links);

      // PLATFORMS
      const newPlatforms = platforms;

      myDraft.platform.map((item) => {
        console.log(item);
        return (newPlatforms[item] = true);
      });

      selectPlatforms(newPlatforms);
      newValuesFromDraft.asset_platforms = newPlatforms;

      console.log(newValuesFromDraft);
      setInitialValues(newValuesFromDraft);
    }
  }, [props.draft]);

  const timer = useEffect(() => {
    setTimeout(() => {
      setErrorSubmitMsg("");
    }, 5000);

    return () => clearTimeout(timer);
  }, [errorSubmitMsg]);

  const timers = useEffect(() => {
    setTimeout(() => {
      setErrorSaveMsg("");
    }, 5000);

    return () => clearTimeout(timers);
  }, [errorSaveMsg]);

  useEffect(() => {
    console.log(page);
    if (page === 1) {
      document.getElementById("page1").classList.add("active");
      document.getElementById("page2").classList.add("active");
      document.getElementById("page3").classList.add("active");
      document.getElementById("page1").classList.remove("completed");
      document.getElementById("page2").classList.remove("completed");
      document.getElementById("page3").classList.remove("completed");
    } else if (page === 2) {
      document.getElementById("page1").classList.add("completed");
      document.getElementById("page2").classList.add("active");
      document.getElementById("page2").classList.remove("completed");
      document.getElementById("page3").classList.add("active");
    } else {
      document.getElementById("page1").classList.add("completed");
      document.getElementById("page2").classList.add("completed");
      document.getElementById("page3").classList.add("active");
      document.getElementById("page3").classList.remove("completed");
      document.getElementById("submit-button").innerHTML = "Submit";
    }
  }, [page]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (areThereUnsavedChanges) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [areThereUnsavedChanges]);

  const saveDraft = async (showConfirmation = false) => {
    var formData = new FormData();

    for (let pageNumber = 1; pageNumber <= page; pageNumber++) {
      if (pageNumber === 1) {
        console.log("gathered page 1 data");
        if (!assetName.name.length) {
          setErrorSaveMsg("Asset name cannot be empty");
          return;
        } else if (!assetName.isUnique) {
          setErrorSaveMsg("Asset name must be unique");
          return;
        }
      }

      if (pageNumber === 2) {
        console.log("gathered page 2 data");

        var filesArray = [];

        filesArray = selectedfile.map((item) => item.fileData);

        if (filesArray.length) {
        }
      }

      if (pageNumber === 3) {
        console.log("gathered page 3 data");

        var platformsArray = [];

        for (const [key, value] of Object.entries(platforms)) {
          value === true && platformsArray.push(key);
        }

        if (platformsArray.length) {
        }
      }
    }

    // SAVING
    formData.append("brand", brandx);
    formData.append("name", assetName.name);
    formData.append('notes',notes)

    if (description.length) {
      // for (let i = 0; i < thumbnailFile.length; i++) {
      formData.append("description", description);
      // }
    }

    if (thumbnailFile.length) {
      for (let i = 0; i < thumbnailFile.length; i++) {
        formData.append("thumbnail", thumbnailFile[i]);
      }
    }

    if (filesArray && filesArray.length) {
      for (let i = 0; i < filesArray.length; i++) {
        formData.append("assets", filesArray[i]);
      }
    }

    if (platformsArray && platformsArray.length) {
      // formData.append('platform', "")
      for (let i = 0; i < platformsArray.length; i++) {
        formData.append("platform", platformsArray[i]);
      }
    }

    formData.append("draftPage", page);

    console.log([...formData]);

    await axios
      .post("https://api.xrcouture.com/brands/saveasdraft", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        console.log([...formData]);
        if (showConfirmation) toast.success("Saved to drafts.");
        setInitialValues({
          asset_name: assetName.name,
          asset_description: description,
          asset_thumbnailFile: thumbnailFile,
          asset_selectedfiles: selectedfile,
          asset_platforms: platforms,

        });
      })
      .catch((err) => {
        console.log(err);
        if (showConfirmation) toast.error("Saving to drafts failed.");
      });
  };

  const handleSumbit = async (event) => {
    event.preventDefault();

    if (saving) return false;

    if (page === 1) {
      if (!assetName.name.length) {
        setErrorSubmitMsg("Asset name cannot be empty");
        return;
      } else if (!assetName.isUnique) {
        setErrorSubmitMsg("Asset name must be unique");
        return;
      }

      setSubmitting(true);

      if (areThereUnsavedChanges) await saveDraft(false);

      setPage((prev) => prev + 1);
      setErrorSubmitMsg("");
      setSubmitting(false);

      console.log(
        "NAME: " + assetName.name,
        "DESCRIPTION: " + description,
        "THMUBNAIL: " + thumbnailFile[0]
      );

      console.log(thumbnailFile);

      return;
    }

    if (page === 2) {
      var filesArray = [];

      filesArray = selectedfile.map((item) => item.fileData);

      if (!filesArray.length) {
        setErrorSubmitMsg("Upload atleast 1 file to continue");
        return;
      }

      setSubmitting(true);

      if (areThereUnsavedChanges) await saveDraft(false);

      setPage((prev) => prev + 1);
      setSubmitting(false);

      console.log("FILES: ", filesArray);

      return;
    }

    if (page === 3) {
      var platformsArray = [];

      for (const [key, value] of Object.entries(platforms)) {
        value === true && platformsArray.push(key);
      }

      if (!platformsArray.length) {
        setErrorSubmitMsg("Please select atleast one platform");
        return;
      }

      console.log("PLATFORMS", platformsArray);
    }

    setSubmitting(true);

    var formData = new FormData();

    formData.append("brand", brandName);
    formData.append("name", assetName.name);

    if (description.length) {
      // for (let i = 0; i < thumbnailFile.length; i++) {
      formData.append("description", description);
      // }
    }

    if (thumbnailFile.length) {
      for (let i = 0; i < thumbnailFile.length; i++) {
        formData.append("thumbnail", thumbnailFile[i]);
      }
    }

    var filesArray = [];
    filesArray = selectedfile.map((item) => item.fileData);

    for (let i = 0; i < filesArray.length; i++) {
      formData.append("assets", filesArray[i]);
    }
    formData.append('notes',notes)

    // formData.append('platform', "")
    for (let i = 0; i < platformsArray.length; i++) {
      formData.append("platform", platformsArray[i]);
    }

    formData.append("approximatePrice", cost);
    formData.append("approximateTime", days);

    formData.append("progress", 10);

    console.log(
      brandName,
      assetName.name,
      description,
      thumbnailFile,
      filesArray,
      platformsArray,
      cost,
      days,
      formData,
      notes
    );

    axios
      .post("https://api.xrcouture.com/brands/create", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        setSubmitting(false);
        dispatch(stopCreatingAsset());
        toast.success("Asset created successfully.");
        navigate(`/brands/${brandName}`);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        toast.error("Asset creation failed.");
      });

    console.log("Submitted");
  };

  const saveToDrafts = async (event) => {
    if (event) event.preventDefault();

    if (event) event.preventDefault();

    if (submitting) return false;

    setSaving(true);

    await saveDraft(true);

    setSaving(false);
  };

  const closeCreateAsset = () => {
    // if (props.draft) {
    //   // saveToDraftsModal()
    //   dispatch(showModal());
    // }

    // else {
    //   if (page === 1) {
    //     if (assetName.name.length && assetName.isUnique) {
    //       // saveToDraftsModal()
    //       dispatch(showModal());
    //     }
    //     else {
    //       navigate(`/brands/${brandx}`)
    //       dispatch(hideModal())
    //       dispatch(stopCreatingAsset())
    //     }
    //   }

    //   else {
    //     // saveToDraftsModal()
    //     dispatch(showModal())
    //   }
    // }

    if (areThereUnsavedChanges) {
      dispatch(showModal());
    } else {
      navigate(`/brands/${brandx}`);
      dispatch(hideModal());
      dispatch(stopCreatingAsset());
    }
  };

  const goBackToPage = (p) => {
    setPage((prev) => prev - p);
  };

  const saveToDraftsModal = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You have unsaved changes`,
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      willClose: () => {
        dispatch(hideModal());
      },
      confirmButtonColor: "rgb(78, 75, 222)",
      cancelButtonColor: "rgb(40 39 106)",
      confirmButtonText: "Save to draft",
      cancelButtonText: "Discard changes",
      reverseButtons: true,
      allowOutsideClick: false,
      customClass: {
        title: "text-white",
        closeButton: "swal-close",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        saveToDrafts();
        dispatch(hideModal());
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        navigate(`/brands/${brandx}`);
        dispatch(hideModal());
        dispatch(stopCreatingAsset());
      }
    });
  };

  if (shouldShowModal) {
    saveToDraftsModal();
  }

  return (
    <Context.Provider
      value={{
        assetData: [assetName, setAssetName],
        thumbnailData: [thumbnailFile, setThumbnailFile],
        descriptionData: [description, setDescription],

        filesData: [selectedfile, SetSelectedFile],

        platformsData: [platforms, selectPlatforms],
        costData: [cost, setCost],
        daysData: [days, setDays],

        pager: [page, setPage],
        submittingState: [submitting, setSubmitting],
        savingState: [saving, setSaving],
        errorData: [errorSubmitMsg, setErrorSubmitMsg],
        saveErrorData: [errorSaveMsg, setErrorSaveMsg],
        previewData: [preview, setPreview],
        terms: [termsCheck, setTermsCheck],
        notes,setNotes
      }}
    >
      
      {props.draft === undefined && (
        <div className="p-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      )}
      

      <div
        className="upload-container row p-0 m-0"
        style={{
          visibility:
            props.draft !== undefined || props.draft === false
              ? "visible"
              : "hidden",
        }}
      >
        
        <div className="col-11 upload-container-first">
          {page === 1 && (
            <AssetInfo
              setStep={props.setStep}
              isDraft={
                isDraft ? true : initialValues.asset_name !== "" ? true : false
              }
              dragHighlightColor={props.dragHighlightColor}
              handleDropFun={props.handleDrop}
            />
          )}

          {page === 2 && (
            <FileUpload
              backButton={goBackToPage}
              dragHighlightColor={props.dragHighlightColor}
              handleDropFun={props.handleDrop}
            />
          )}

          {page === 3 && <PlatformSelect backButton={goBackToPage} />}

          <NextButton
            handleSumbit={handleSumbit}
            saveToDrafts={saveToDrafts}
            errorMsg={errorSubmitMsg}
            errorSMsg={errorSaveMsg}
            canSaveToDraftBtn={areThereUnsavedChanges}
          />
        </div>

        <div className="page-indicater col-1">
          <div
            onClick={closeCreateAsset}
            id="close-button-id"
            className="upload-title fs-lg float-right d-flex align-items-center"
            style={{ fontFamily: "Clash Display SemiBold", cursor: "pointer" }}
          >
            x
          </div>
          <div
            className="d-none d-flex align-items-center flex-column"
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <div className="page-indicater-item fs-m" id="page1">
              1
            </div>
            <div className="page-indicater-line"></div>
            <div className="page-indicater-item fs-m" id="page2">
              2
            </div>
            <div className="page-indicater-line"></div>
            <div className="page-indicater-item fs-m" id="page3">
              3
            </div>
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};

export default Upload;
