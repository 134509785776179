import React, { useState, useEffect } from "react";
import SupportTickets from "../components/Support/SupportTickets";
import { IoAddCircleOutline } from "react-icons/io5";
import CreateTicketModal from "../components/Support/CreateTicketModal";
import axios from "axios";
import { useParams } from "react-router";
import { Context } from "../Context";
import AdminSupportTickets from "../components/Support/AdminSupportTickets";
import { Accordion, Panel, Panels, panels } from "../components/Faq/Faq";
import Modal from "react-bootstrap/Modal";
import { FaQuestion, FaQuestionCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const Support = (props) => {
  useEffect(() => {
    document.getElementById("home").classList.remove("active");
  }, []);
  const [modalShow, setModalShow] = React.useState(false);

  const { brandx } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assetNames, setAssetNames] = useState([]);

  const [update, setUpdate] = useState(false);

  const createTicket = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    axios
      .post("https://api.xrcouture.com/brands/assets", {
        brand: brandx,
      })
      .then((res) => {
        // console.log(res.data.assets)

        var assetNamesArray = res.data.assets.map((item) => {
          return item.assetName;
        });

        assetNamesArray.unshift("-");

        setAssetNames(() => assetNamesArray);
      });
  }, []);

  return (
    <Context.Provider value={{ update, setUpdate }}>
      <>
        {/* <div className="container-dashboard">
          <div className="d-flex flex-row align-items-start">
            <div
              className="mr-auto d-flex flex-column  p-0 m-0 mb-0 text-header-top"
              style={{ marginRight: "auto !importaant" }}
            >
              <h3
                style={{
                  color: "white",
                  fontFamily: "Clash Display SemiBold",
                  width: "fit-content",
                }}
                className="header-text-overall fs-lg"
              >
                Support
              </h3>
              <p
                className="fs-xs mt-1 text-white col-md-9 col-sm-9"
                style={{ fontFamily: "Clash Display Medium", opacity: 0.5 }}
              >
                If you are an artist and want to be a successfull artist then
                learn to convert your art into NFT
              </p>
              <div
                className="icons-dashboard btn-create box-shadow fs-sm"
                style={{
                  width: "unset",
                  color: "white",
                  fontFamily: "Clash Display Medium",
                  background: "#2a0fd3",
                  border: "1px solid transparent",
                  padding: "1rem 1.5rem",
                  cursor: "pointer",
                }}
                onClick={() => setModalShow(!modalShow)}
              >
                FAQ <FaQuestionCircle color="white" />
              </div>
            </div>
          </div>
          </div> */}
                <div className="container-dashboard">
      <div className="d-flex flex-row align-items-start">
        <div
          className="mr-auto d-flex flex-column  p-0 m-0 mb-0 text-header-top"
          style={{ marginRight: "auto !importaant" }}
        >
          <h3
            style={{
              color: "white",
              fontFamily: "Clash Display SemiBold",
              width: "fit-content",
            }}
            className="header-text-overall fs-lg"
          >
            Support
          </h3>
        <p className="fs-xs mt-1 text-white col-md-9 col-sm-9" style={{fontFamily:"Clash Display Medium",opacity:.5}}>
          {/* If you need any help or have any questions about WRBL, you can contact our support team anytime. We are always ready to assist you and solve any issues you may encounter. You can also access our FAQ section and our community forum for more information and tips. WRBL’s support is here to make your experience smooth and enjoyable. */}
          Need help with WRBL? Contact our support team anytime or check out our FAQ and community forum for a smooth and enjoyable experience.
          </p>
        </div>
        <div
          className=" p-0 d-md-none  h-auto m-0 d-flex justify-content-center"
          style={{ cursor: "pointer" }}
          // onClick={handleCreate}
        >
          <div
            className={`icons-dashboard  btn-create box-shadow`}
            style={{
              width: "unset",
              color: "white",
              fontFamily: "Clash Display Medium",
              background: "#2a0fd3",
              border: "1px solid transparent",
            }}
            onClick={createTicket}
          >
            <IoAddCircleOutline size={25} color="white" />
          </div>
        </div>
        <div
          className=" d-none d-md-block  p-0 h-100 m-0 d-flex justify-content-center"
          style={{ cursor: "pointer" }}
        >
          <div
            className={`icons-dashboard  btn-create box-shadow fs-xs`}
            style={{
              width: "max-content",
              color: "white",
              fontFamily: "Clash Display Medium",
              background: "#2a0fd3",
              border: "1px solid transparent",
            }}
            onClick={createTicket}
          >
            Create Ticket
            <IoAddCircleOutline  size={20} color="white" />
          </div>
        </div>
        <div
          className=" p-0 d-md-none  h-auto  d-flex justify-content-center"
          style={{ cursor: "pointer",marginLeft:"10px" }}
          // onClick={handleCreate}
        >
          <div
            className={`icons-dashboard  btn-create box-shadow`}
            style={{
              width: "unset",
              color: "white",
              fontFamily: "Clash Display Medium",
              background: "#2a0fd3",
              border: "1px solid transparent",
              
            }}
            onClick={() => setModalShow(!modalShow)}
          >
            <FaQuestion size={25} color="white" />
          </div>
        </div>
        <div
          className=" d-none d-md-block  h-100  d-flex justify-content-center"
          style={{ cursor: "pointer",marginLeft:"10px" }}
        >
          <div
            className={`icons-dashboard  btn-create box-shadow fs-xs`}
            style={{
              width: "unset",
              color: "white",
              fontFamily: "Clash Display Medium",
              background: "#2a0fd3",
              border: "1px solid transparent",
              
            }}
            onClick={() => setModalShow(!modalShow)}
          >
            FAQ
            <FaQuestion size={18}  color="white" />
          </div>
        </div>
      </div>
      </div>
          {isModalOpen ? (
            <CreateTicketModal
              closeModalFun={closeModal}
              assetNames={assetNames}
            />
          ) : (
            ""
          )}
        

          {/* <CreateTicketModal closeModalFun={closeModal} assetNames={assetNames} /> */}

          {props.role === "admin" ? (
            <AdminSupportTickets />
          ) : (
            <SupportTickets />
          )}
       
      </>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        style={{
          zIndex: 98765432087,
        }}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "Clash Display Bold" }}
          >
            FAQ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion panels={panels} />
        </Modal.Body>
      </Modal>
    </Context.Provider>
  );
};

export default Support;
{/* <div className='col-6 p-0 row d-flex align-items-center' style={{ justifyContent: "right" }}>
            <div className='col-5 p-0 h-100 m-0 d-flex justify-content-center' style={{ cursor: "pointer" }}>
              <div onClick={createTicket} className='icons-dashboard btn-create' style={{  width: "unset", color: "white", fontFamily: "Clash Display Medium", background: "#2a0fd3", border: "1px solid transparent", padding: "1rem 1.5rem" }}>
                Create ticket <IoAddCircleOutline size={25} color="white" /></div>
            </div>
          </div> */}