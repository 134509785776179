import * as React from "react";
import styled from "styled-components";
import Select from "./Select";
import { getNumberOfPages } from "./util";
import useWindowSize from "../hooks/useWindowSize";
import useRTL from "../hooks/useRTL";
import { media, SMALL } from "./media";
import { AiOutlineDownload } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { defaultProps } from "./defaultProps";
import { Context1 } from "../../Context";
import { useContext } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import { MdDeleteOutline, MdCancel } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";

import Payment from "../../components/Payment/Payment";

import "./paymentModal.css";
import PaypalCheckoutButton from "../../pages/PaypalCheckoutButton";
import { BeatLoader } from "react-spinners";
import axios from "axios";

const defaultComponentOptions = {
  rowsPerPageText: "Rows :",
  rangeSeparatorText: "/",
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: "All",
};

const PaginationWrapper = styled.nav`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  ${({ theme }) => theme.pagination.style};
`;

const Button = styled.button`
  position: relative;
  display: block;
  user-select: none;
  border: none;
  ${({ theme }) => theme.pagination.pageButtonsStyle};
  ${({ isRTL }) => isRTL && "transform: scale(-1, -1)"};
`;

const PageList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  white-space: nowrap;
  width: 100%;
  ${media.sm`
    width: 100%;
    justify-content: space-around;
  `};
`;

const Span = styled.span`
  flex-shrink: 1;
  user-select: none;
`;

const Range = styled(Span)`
  margin: 0 !important;
`;

const RowLabel = styled(Span)`
  margin: 0 4px;
`;

function Pagination({
  rowsPerPage,
  rowCount,
  currentPage,
  direction = defaultProps.direction,
  paginationRowsPerPageOptions = defaultProps.paginationRowsPerPageOptions,
  paginationIconLastPage = defaultProps.paginationIconLastPage,
  paginationIconFirstPage = defaultProps.paginationIconFirstPage,
  paginationIconNext = defaultProps.paginationIconNext,
  paginationIconPrevious = defaultProps.paginationIconPrevious,
  paginationComponentOptions = defaultProps.paginationComponentOptions,
  onChangeRowsPerPage = defaultProps.onChangeRowsPerPage,
  onChangePage = defaultProps.onChangePage,
}) {
  const windowSize = useWindowSize();
  const isRTL = useRTL(direction);
  const shouldShow = windowSize.width && windowSize.width > SMALL;
  const { selectedRows, setSelectedRows, payForAssets, setPayForAssets } =
    useContext(Context1);
  console.log(selectedRows);
  // const isRTL = detectRTL(direction);
  const [mobile, setMobile] = useState(false);
  const numPages = getNumberOfPages(rowCount, rowsPerPage);
  const lastIndex = currentPage * rowsPerPage;
  const firstIndex = lastIndex - rowsPerPage + 1;
  const disabledLesser = currentPage === 1;
  const disabledGreater = currentPage === numPages;
  const options = { ...defaultComponentOptions, ...paginationComponentOptions };
  const range =
    currentPage === numPages
      ? `${currentPage} ${options.rangeSeparatorText} ${numPages}`
      : // `${firstIndex}-${rowCount} ${options.rangeSeparatorText} ${rowCount}`
        `${currentPage} ${options.rangeSeparatorText} ${numPages}`;
  // `${firstIndex}-${lastIndex} ${options.rangeSeparatorText} ${rowCount}`;
  const handlePrevious = React.useCallback(
    () => onChangePage(currentPage - 1),
    [currentPage, onChangePage]
  );
  const handleNext = React.useCallback(
    () => onChangePage(currentPage + 1),
    [currentPage, onChangePage]
  );
  const handleFirst = React.useCallback(() => onChangePage(1), [onChangePage]);
  const handleLast = React.useCallback(
    () => onChangePage(getNumberOfPages(rowCount, rowsPerPage)),
    [onChangePage, rowCount, rowsPerPage]
  );
  const handleRowsPerPage = React.useCallback(
    (e) => onChangeRowsPerPage(Number(e.target.value), currentPage),
    [currentPage, onChangeRowsPerPage]
  );
  const selectOptions = paginationRowsPerPageOptions.map((num) => (
    <option key={num} value={num}>
      {num}
    </option>
  ));
  if (options.selectAllRowsItem) {
    selectOptions.push(
      <option key={-1} value={rowCount}>
        {options.selectAllRowsItemText}
      </option>
    );
  }
  const select = (
    <Select
      onChange={handleRowsPerPage}
      defaultValue={rowsPerPage}
      aria-label={options.rowsPerPageText}
    >
      {selectOptions}
    </Select>
  );
  const [asset, setAsset] = useState({
    download: 0,
    payable: 0,
  });
  const [showTotalCost, setShowTotalCost] = useState(0);
  useEffect(() => {
    console.log(selectedRows);
    let download = 0;
    let payable = 0;
    let payableTotalAmount = 0;
    selectedRows.forEach((item) => {
      if (item.status === "Completed") {
        download += 1;
      }
      if (item.status === "Pending payment") {
        payable += 1;
        payableTotalAmount += item.actualPrice;
      }
    });
    setAsset({
      download: download,
      payable: payable,
    });
    setShowTotalCost(payableTotalAmount);
  }, [selectedRows]);

  useEffect(() => {
    if (window.screen.width <= 850) {
      setMobile(true);
    }
  }, [window.screen.width]);
  console.log(asset);

  const downloadImages = async () => {
    const temp = [];
    const zip = new JSZip();
    for (let i = 0; i < selectedRows.length; i++) {
      if (selectedRows[i].digitalWearables) {
        const folder = zip.folder(`${selectedRows[i].assetName}`);
        const s3LinksArrays = Object.values(selectedRows[i].digitalWearables);
        for (let j = 0; j < s3LinksArrays.length; j++) {
          const response = await fetch(s3LinksArrays[j]);
          const blob = await response.arrayBuffer();
          const fileName = s3LinksArrays[j].split("/").pop();
          folder.file(fileName, blob);
        }
      } else {
        temp.push(selectedRows[i].assetName);
      }
    }
    if (temp.length > 0) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      const successalt = (a, x) => {
        Swal.fire({
          position: "center",
          icon: a,
          title: x,
          showConfirmButton: false,
          timer: 1500,
        });
      };
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `Only ${asset["download"]} / ${selectedRows.length} are downloadable !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continue Downloading",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const content = await zip.generateAsync({ type: "blob" });
            saveAs(content, "Digital_Wearables.zip");
            swalWithBootstrapButtons.fire(
              "Downloaded!",
              "Your files has been downloaded.",
              "success"
            );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            successalt("error", "Cancelled");
          }
        });
    } else {
      toast.success(`Digital Wearables downloaded successfully`);
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "Digital_Wearables.zip");
    }
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [amount, setAmount] = useState(0);
  const [rowsAllowed, setRowsAllowed] = useState([]);
  const {credits,setCredits} = useContext(Context1)
  const pay = () => {
    setModalShow(true);
    var payable = [];
    var payableAmount = 0;
    var payableRows = [];
    selectedRows.forEach((item) => {
      if (item.status === "Pending payment") {
        payable.push(item.assetName);
        payableAmount += item.actualPrice;
        payableRows.push(item);
      }
    });
    console.log(payableRows, payableAmount);
    setAmount(payableAmount);
    setRowsAllowed(payableRows);
  };

  const [appliedDiscount, setAppliedDiscount] = useState({
    discount: null,
    code: null,
  });

  useEffect(() => {
    setPayForAssets(rowsAllowed);
  }, [rowsAllowed, setPayForAssets]);

  const deleteItemFromCart = (name) => {
    console.log(name);
    setPayForAssets((prev) => prev.filter((item) => item.assetName !== name));
  };

  function calculateTotalCost() {
    let totalPrice = 0;
    for (let i = 0; i < payForAssets.length; i++) {
      totalPrice += payForAssets[i].actualPrice;
    }
    return promoCodeApplied !== null && promoCodeApplied === true
      ? totalPrice - appliedDiscount.discount
      : totalPrice;
  }

  function calculateTotalCostWithoutDiscount() {
    let totalPrice = 0;
    for (let i = 0; i < payForAssets.length; i++) {
      totalPrice += payForAssets[i].actualPrice;
    }
    return totalPrice-credits;
  }

  const closePaymentModal = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you really want to exit the payment process?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(78, 75, 222)",
      cancelButtonColor: "rgb(40 39 106)",
      confirmButtonText: "Proceed with payment",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      allowOutsideClick: false,
      customClass: {
        title: "text-white",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else {
        Swal.close();
        setModalShow(false);
      }
    });
  };

  const [promoInput, setPromoInput] = useState(false);

  const [paymentModal, setPaymentModal] = useState(false);

  const [promoCodeText, setPromoCodeText] = useState("");

  const [validatingPromoCode, setValidatingPromoCode] = useState(false);

  const [promoCodeApplied, setPromoCodeApplied] = useState(null);

  const [promoCodeError, setPromoCodeError] = useState("");

  useEffect(() => {
    calculateTotalCost();
  }, [appliedDiscount]);

  const applyPromoCode = async (e) => {
    e.preventDefault();

    setPromoCodeApplied(null);
    setPromoCodeError("");

    if (promoCodeApplied === true) {
      setPromoCodeText("");
      setPromoCodeApplied(null);
      setAppliedDiscount({
        discount: null,
        code: null,
      });
      return;
    }

    setValidatingPromoCode(true);

    await axios
      .post(
        "https://api.xrcouture.com/promocode/validatepromocode",
        {
          code: promoCodeText,
          email: localStorage.getItem("email"),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setPromoCodeApplied(true);
        setAppliedDiscount({
          discount: response.data.updatedPromoCode.discount,
          code: response.data.updatedPromoCode.code,
        });
        setValidatingPromoCode(false);
      })
      .catch((err) => {
        console.log(err);
        setPromoCodeApplied(false);
        setPromoCodeError(
          err.response.data.msg === "Invalid user"
            ? "Invalid promo code"
            : err.response.data.msg
        );
        setValidatingPromoCode(false);
      });
  };

  return (
    <PaginationWrapper className="rdt_Pagination">
      <PageList>
        <div
          className="position-relative button-download-div"
          style={{
            visibility: `${
              window.location.href.includes("/admin/") === true
                ? "hidden"
                : "visible"
            }`,
          }}
        >
          <button
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
            title="Button is disabled"
            className="btn btn-success fifth-step box-shadow"
            data-tour='download-assets'
            onClick={() => {
              downloadImages();
            }}
            disabled={asset["download"] > 0 ? false : true}
          >
            {mobile ? (
              <>
                <AiOutlineDownload />{" "}
              </>
            ) : (
              <>
                {" "}
                Download <AiOutlineDownload
                  style={{ marginLeft: "10px" }}
                />{" "}
              </>
            )}
          </button>
          <div
            className="hover-messaage"
            style={{
              visibility: `${asset["download"] > 0 ? "hidden" : "visible"}`,
            }}
          >
            Please select atleast one completed asset <br /> to download 3D
            files
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            id="pagination-first-page"
            type="button"
            aria-label="First Page"
            aria-disabled={disabledLesser}
            onClick={handleFirst}
            disabled={disabledLesser}
            isRTL={isRTL}
          >
            {paginationIconFirstPage}
          </Button>

          <Button
            id="pagination-previous-page"
            type="button"
            aria-label="Previous Page"
            aria-disabled={disabledLesser}
            onClick={handlePrevious}
            disabled={disabledLesser}
            isRTL={isRTL}
          >
            {paginationIconPrevious}
          </Button>
          <Range>{range}</Range>

          <Button
            id="pagination-next-page"
            type="button"
            aria-label="Next Page"
            aria-disabled={disabledGreater}
            onClick={handleNext}
            disabled={disabledGreater}
            isRTL={isRTL}
          >
            {paginationIconNext}
          </Button>

          <Button
            id="pagination-last-page"
            type="button"
            aria-label="Last Page"
            aria-disabled={disabledGreater}
            onClick={handleLast}
            disabled={disabledGreater}
            isRTL={isRTL}
          >
            {paginationIconLastPage}
          </Button>
          {!options.noRowsPerPage && shouldShow && !mobile && (
            <>
              <RowLabel>Rows :</RowLabel>
            </>
          )}
          {!options.noRowsPerPage && !shouldShow && select && !mobile}
          {select}
        </div>
        <div
          className="position-relative button-download-div d-flex align-items-start"
          style={{
            marginLeft: "25px",
            visibility: `${
              window.location.href.includes("/admin/") === true
                ? "hidden"
                : "visible"
            }`,
          }}
        >
          <button
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
            className="btn btn-primary sixth-step box-shadow"
            onClick={() => pay()}
            disabled={asset["payable"] > 0 ? false : true}
            data-tour='pay-assets'
          >
            {mobile ? (
              <>
                <FaWallet />{" "}
              </>
            ) : (
              <>
                <FaWallet style={{ marginRight: "10px" }} /> Pay &nbsp;
                {showTotalCost > 0 ? (
                  <div style={{ fontFamily: "Clash Display SemiBold" }}>
                    {"$ " + showTotalCost}
                  </div>
                ) : (
                  ""
                )}{" "}
              </>
            )}
          </button>
          <div
            className="hover-messaage"
            style={{
              visibility: `${asset["payable"] > 0 ? "hidden" : "visible"}`,
              justifySelf: "center",
              left: "0%",
            }}
          >
            Please select atleast one payment pending asset <br /> to proceed
            payment
          </div>
        </div>
      </PageList>
      {/* <ToastContainer /> */}

      {/* PAYMENT */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          payForAssets.length ? closePaymentModal() : setModalShow(false);
          setPromoCodeApplied(null);
          setValidatingPromoCode(false);
          setPromoCodeText("");
          setPromoInput(false);
        }}
      >
        <Modal.Header
          className="text-white"
          closeButton
          style={{ padding: "1rem 2rem" }}
          closeVariant="white"
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "Clash Display Bold" }}
          >
            Review Selection
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="cart-body text-white d-flex align-items-start"
            style={{ width: "100%", padding: "1.5rem 1rem" }}
          >
            {payForAssets.length ? (
              <>
                {/* Items */}
                <div
                  className="cart-item"
                  style={{
                    border: "1px solid rgb(196 196 196 / 36%)",
                    borderRadius: "0.6rem",
                    width: "60%",
                    height: "fit-content",
                    background: "rgb(30 29 71 / 64%)",
                  }}
                >
                  {payForAssets.map((row, i) => (
                    <>
                      {i !== 0 && <hr style={{ margin: "0", padding: "0" }} />}
                      <div
                        className="cart-item-data d-flex justify-content-between align-items-center m-2"
                        style={{ padding: "1rem 0.5rem" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="cart-item-data-thumbnail">
                            <img
                              alt=""
                              src={row.thumbnail}
                              style={{ width: "40px", height: "40px" }}
                            />
                          </div>
                          <div
                            className="cart-item-data-info d-flex flex-column"
                            style={{ marginLeft: "1.5rem" }}
                          >
                            <div
                              className="cart-item-title"
                              style={{
                                fontFamily: "Clash Display SemiBold",
                                fontSize: "1rem",
                              }}
                            >
                              {row.assetName}
                            </div>
                            <div
                              className="cart-item-id"
                              style={{
                                fontFamily: "Clash Display Medium",
                                fontSize: "0.85rem",
                                opacity: "0.8",
                              }}
                            >
                              #{row.assetId}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="cart-item-cost"
                            style={{
                              fontFamily: "Clash Display SemiBold",
                              fontSize: "1.2rem",
                            }}
                          >
                            $ {row.actualPrice}
                          </div>
                          <div
                            className="cart-item-delete"
                            onClick={() => deleteItemFromCart(row.assetName)}
                            style={{
                              fontFamily: "Clash Display SemiBold",
                              fontSize: "1rem",
                              cursor: "pointer",
                              marginTop: "-0.25rem",
                              marginLeft: "4rem",
                            }}
                          >
                            <MdDeleteOutline />
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>

                {/* Summary */}
                <div
                  className="d-flex flex-column justify-content-end align-items-end"
                  style={{ width: "40%" }}
                >
                  <div
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      fontFamily: "Clash Display SemiBold",
                      fontSize: "1.1rem",
                      textAlign: "right",
                      padding: "1rem 1.5rem 1rem 1.5rem",
                      background: "#1e1d47",
                      borderRadius: "0.6rem",
                      width: "90%",
                    }}
                  >
                    <div
                      className={promoInput ? "mb-2" : "mb-4"}
                      onClick={() => setPromoInput(true)}
                      style={{
                        fontFamily: "Clash Display Light",
                        fontSize: "0.75rem",
                        textAlign: "right",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Have a promo code ?
                    </div>
                    <form
                      onSubmit={applyPromoCode}
                      className={
                        promoCodeApplied !== null
                          ? "promo-code-input"
                          : "mb-4 promo-code-input"
                      }
                      style={{
                        position: "relative",
                        display: promoInput ? "block" : "none",
                        width: "100%",
                      }}
                    >
                      <input
                        required
                        disabled={promoCodeApplied}
                        type="text"
                        value={promoCodeText}
                        onChange={(event) => {
                          setPromoCodeText(event.target.value);
                          setPromoCodeApplied(null);
                        }}
                        placeholder="Enter promo code"
                        style={{
                          background: "transparent",
                          border: "2px dotted #6a6a6a",
                          borderRadius: "0.6rem",
                          color: "white",
                          fontFamily: "Clash Display Bold",
                          outline: "none",
                          width: "100%",
                          fontSize: "0.9rem",
                          padding: "0.5rem 4rem 0.5rem 0.5rem",
                        }}
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          height: "100%",
                          background: "transparent",
                          border: "none",
                          color: "white",
                          fontFamily: "Clash Display SemiBold",
                          fontSize: "0.8rem",
                          padding: "0.5rem 0.8rem",
                        }}
                      >
                        {validatingPromoCode ? (
                          <BeatLoader size={5} color="white" />
                        ) : promoCodeApplied !== null && promoCodeApplied ? (
                          "x"
                        ) : (
                          ((promoCodeApplied !== null &&
                            promoCodeApplied === false) ||
                            promoCodeApplied === null) &&
                          "Apply"
                        )}
                      </button>
                    </form>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Clash Display Medium",
                        textAlign: "left",
                        marginLeft: "0.3rem",
                        marginTop: "0.3rem",
                      }}
                      className={promoCodeApplied !== null ? "mb-3" : ""}
                    >
                      {promoCodeApplied === true ? (
                        <div className="d-flex align-items-center text-success">
                          <BsCheckCircleFill
                            style={{ marginRight: "0.3rem" }}
                          />{" "}
                          Promo code applied
                        </div>
                      ) : promoCodeApplied === false ? (
                        <div className="d-flex align-items-center text-danger">
                          <MdCancel style={{ marginRight: "0.3rem" }} />{" "}
                          {promoCodeError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <span
                        style={{
                          fontFamily: "Clash Display Medium",
                          opacity: "0.9",
                          fontSize: "1rem",
                        }}
                      >
                        Order amount
                      </span>{" "}
                      &nbsp;$ {calculateTotalCostWithoutDiscount()}
                    </div>
                    <div className="d-flex justify-content-between">
                      <span
                        style={{
                          fontFamily: "Clash Display Medium",
                          opacity: "0.9",
                          fontSize: "1rem",
                        }}
                      >
                        Credits
                      </span>{" "}
                      &nbsp;$ {credits}
                    </div>
                    {promoCodeApplied !== null && promoCodeApplied === true && (
                      <div className="d-flex justify-content-between">
                        <span
                          style={{
                            fontFamily: "Clash Display Medium",
                            opacity: "0.9",
                            fontSize: "1rem",
                          }}
                        >
                          Promo discount
                        </span>{" "}
                        &nbsp;(-) $ {appliedDiscount.discount}
                      </div>
                    )}
                    <hr />
                    <div className="d-flex justify-content-between">
                      <span
                        style={{
                          fontFamily: "Clash Display Medium",
                          opacity: "0.9",
                          fontSize: "1rem",
                        }}
                      >
                        Total
                      </span>{" "}
                      &nbsp;$ {calculateTotalCost()}
                    </div>
                  </div>
                  <div
                    style={{
                      float: "right",
                      marginTop: "1.5rem",
                      width: "90%",
                    }}
                  >
                    <PaypalCheckoutButton
                      amount={calculateTotalCost()}
                      products={payForAssets}
                      closeModal={setModalShow}
                      promoCode={appliedDiscount}
                      style={{ margin: "1rem" }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  whiteSpace: "nowrap",
                  fontFamily: "Clash Display SemiBold",
                  fontSize: "1.2rem",
                  textAlign: "center",
                  padding: "1.5rem 3rem 1.5rem 3rem",
                  margin: "1rem",
                  width: "100%",
                  minHeight: "10rem",
                }}
              >
                No assets selected
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={paymentModal}
        onHide={() => {
          setModalShow(false);
          setPaymentModal(false);
        }}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "Clash Display Bold" }}
          >
            XRC Payment
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Payment amount={amount} assets={rowsAllowed} />
        </Modal.Body>
      </Modal>
    </PaginationWrapper>
  );
}
export default React.memo(Pagination);
