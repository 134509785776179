import React, { useState, useEffect, useContext } from "react";

import { Context, Context1 } from "../../../Context";
import axios from "axios";
import { useLocation } from "react-router-dom";

import thumbnail from "../../../assets/thumbnail.png";

import "./AssetInfo.css";
import { useParams } from "react-router";
import { useTour } from "@reactour/tour";

import { IoArrowBackOutline } from "react-icons/io5";

const AssetInfo = (props) => {
  // STATES
  const { assetData, descriptionData, thumbnailData, errorData, previewData } =
    useContext(Context);

  const [assetName, setAssetName] = assetData;
  const [description, setDescription] = descriptionData;
  const [thumbnailFile, setThumbnailFile] = thumbnailData;
  const [preview, setPreview] = previewData;

  const [assetTitle, setAssetTitle] = useState(assetName.name);
  const [value, setValue] = useState("");
  const [, setErrorSubmitMsg] = errorData;

  const [prevAssets, setPrevAssets] = useState([]);
  const [assetNameError, setAssetNameError] = useState("");
  let location = useLocation();
  // IMAGES
  const uploadIMG =
    "https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/upload.webp";
  const deleteIMG =
    "https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/delete.webp";

  const { brandx } = useParams();
  const text = "Sample Asset";
  const {setIsOpen} = useTour()
  const {setStep,op} = useContext(Context1)
  //default steps
  const steps = [
    {
      selector: ".first-step",
      content: "Create your custom virtual clothing",
    },
    {
      selector: ".second-step",
      content: "Never miss an update with our notifications",
    },
    {
      selector: ".third-step",
      content: "Find your assets with our search",
    },
    {
      selector: ".fourth-step",
      content: "Find exactly what you’re looking for with our filters",
    },
    {
      selector: ".fifth-step",
      content: "Download your completed assets",
    },
    {
      selector: ".sixth-step",
      content: "Pay for your pending assets with ease",
    },
    {
      selector: ".seventh-step",
      content: "Discover how interoperability works with our video",
    },
    {
      selector: ".eighth-step",
      content: "Contact us for help with your assets",
    },
    // ...
  ];
  useEffect(()=>{
    // setStep(1)
    setIsOpen(op)
    console.log("asset info page is called", op)
  },[])

  //destructuring from useTour

  // SETTING STEPS FOR TOUR
  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      if (i < text.length) {
        setValue((value) => value + text[i]);
        i++;
      } else {
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [text]);
  // FETCHING PREVS ASSETS
  useEffect(() => {
    axios
      .post(
        "https://api.xrcouture.com/brands/assetNames",
        {
          brand: brandx,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log("Fetched", response.data);
        setPrevAssets(response.data.assets);
      });
  }, []);

  // UPDATING GLOBAL STATE FOR ASSET NAME
  useEffect(() => {
    setErrorSubmitMsg("");
    if (!props.isDraft) {
      setAssetName((prev) => {
        return { ...prev, name: assetTitle };
      });
    }
  }, [assetTitle, setAssetName, setErrorSubmitMsg]);

  // DYNAMIC CHECK FOR ASSET NAME
  useEffect(() => {
    if (!props.isDraft) {
      const match = prevAssets.filter((item) => item === assetName.name);

      if (match.length) {
        setAssetNameError("Asset name already in use");
        setAssetName((prev) => {
          return { ...prev, isUnique: false };
        });
      } else {
        setAssetNameError("");
        setAssetName((prev) => {
          return { ...prev, isUnique: true };
        });
      }
    } else {
      setAssetNameError("");
      setAssetName((prev) => {
        return { ...prev, isUnique: true };
      });
    }
  }, [assetName.name, prevAssets, setAssetName]);

  // THUMBNAIL PREVIEW
  const previewFile = () => {
    console.log("HERE in preivewFile");

    var file = document.getElementById("profile-image-upload").files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setThumbnailFile([file]);
        setPreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
    props.handleDropFun();
  };

  const thumbnailFilesize = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center text-white justify-content-between">
        {/* <IoArrowBackOutline size={"2rem"} style={{marginRight: "1rem", cursor: "pointer"}} /> */}
        <div className="upload-title fs-xl" id="upload-title-height1">
          Create Asset
        </div>
      </div>
      <div className="d-flex align-items-center mt-4">
          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #282760",
                background: "#fff",
                color: "#000",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
              }}
            >
              <div>1</div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold", marginRight:"7px" }}
            >
              Define
            </div>
          </div>

          <hr
            style={{
              color: "white",
              background: "white",
              width: "3em",
              height: "0.1em",
            }}
          />

          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #c4c4c4",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
              }}
            >
              <div>2</div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold",marginRight:"7px" }}
            >
              Design
            </div>
          </div>

          <hr
            style={{
              color: "white",
              background: "white",
              width: "3em",
              height: "0.1em",
            }}
          />

          <div className="d-flex text-white justify-content-center align-items-center">
            <div
              className="fs-xs d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #c4c4c4",
                borderRadius: "5em",
                height: "3em",
                width: "3em",
                marginRight: "0.65em",
                fontFamily: "Clash Display Medium",
              }}
            >
              <div>3</div>
            </div>
            <div
              className="fs-sm"
              style={{ fontFamily: "Clash Display SemiBold" }}
            >
              Publish
            </div>
          </div>
      </div>
      <p className="fs-xs text-white mt-2" style={{fontFamily:"Clash Display Light",opacity:"0.7"}}> Begin creating your 3D asset by giving it a name, a thumbnail image and a brief description for your own reference.</p>

      <div className="d-flex upload-contents row gx-0">
        <div className="d-flex flex-column col-12 col-lg-6">
          <div
            className="thumbnail-container"
            style={{ width: "100%", position: "relative" }}
          >
            <div className="uc-items-title fs-xs create-1">Thumbnail</div>
            <div
              className="uc-thumbnail-bg"
              style={{
                position: "relative",
                border: `0.1em dotted ${props.dragHighlightColor}`,
              }}
              data-tour='heading'
            >
              <div className="items-img-m mb-4">
                <img src={uploadIMG} alt=""></img>
              </div>
              <div className="uc-items-title fs-xs text-white">
                Drag and drop here or
                <span className="uc-items-link fs-xs">
                  <label
                    style={{ cursor: "pointer" }}
                    for="profile-image-upload"
                    className="uc-items-link fs-xs"
                  >
                    {" "}
                    &nbsp; Browse files
                  </label>
                  <input
                    id="profile-image-upload"
                    accept="image/png, image/jpeg, image/webp, image/jpg"
                    type="file"
                    onChange={() => previewFile()}
                  ></input>
                </span>
              </div>
              <div className="uc-items-subtitle fs-xs">
                Max file size is 20 MB
              </div>
            </div>

            {thumbnailFile.length ? (
              <div className="uc-thumbnail-bg-sm mt-4 d-flex">
                <div className="d-flex align-items-center">
                  <img
                    className="thumbnail-preview-img"
                    style={{
                      objectFit: "cover",
                      objectPosition: "top",
                      marginLeft: "0.5em",
                      borderRadius: "0.6em",
                    }}
                    src={preview}
                    alt=""
                    id="thumbnail"
                  />
                  <div style={{ textAlign: "left", marginLeft: "1.2em" }}>
                    <div className="uc-items-title-thin fs-xs text-white">
                      {thumbnailFile[0].name}
                    </div>
                    <div className="uc-items-subtitle-thin fs-xs">
                      {thumbnailFilesize(thumbnailFile[0].size)}
                    </div>
                  </div>
                  <div className="d-none">
                    <img style={{ width: "1rem" }} src={deleteIMG} alt=""></img>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="d-flex flex-column col-12 col-lg-6 mt-4 mt-lg-0" data-tour='asset-info'>
          <label className=""   >
            <div className="uc-items-title fs-xs"> Asset name* </div>
            <input
              className="uc-items-input fs-sm"
              type="text"
              name="name"
              value={props.isDraft ? assetName.name : assetTitle}
              disabled={props.isDraft}
              style={{
                width: "15em",
                cursor: props.isDraft ? "not-allowed" : "auto",
              }}
              onChange={(e) => setAssetTitle(e.target.value)}
            
            />
            <p
              className="text-danger fs-xs mb-0"
              style={{ marginLeft: "0.5em" }}
            >
              {assetNameError}
            </p>
          </label>

          <label className="mt-3 mt-lg-5 step-3">
            <div className="uc-items-title fs-xs"> Description </div>
            <textarea
              rows="4"
              className="uc-items-input fs-sm"
              type="text"
              name="name"
              value={description}
              maxLength={100}
              onChange={(e) => setDescription(e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default AssetInfo;
