import React from "react";
import styled from "styled-components";
const Icon = styled.span`
  padding: 2px;
  color: inherit;
  flex-grow: 0;
  flex-shrink: 0;
  ${({ sortActive }) => (sortActive ? "opacity: 1" : "opacity: 0")};
  ${({ sortDirection }) =>
    sortDirection === "desc" && "transform: rotate(180deg)"};
`;
const NativeSortIcon = ({ sortActive, sortDirection }) => (
  <Icon sortActive={sortActive} sortDirection={sortDirection}>
    &#9650;
  </Icon>
);
export default NativeSortIcon;
