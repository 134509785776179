import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Row, Col } from "reactstrap";
import { Label } from "reactstrap";
import * as Yup from "yup";
import "./register.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, Navigate, redirect } from "react-router-dom";
import "./signup.css";
import { GoogleLogin } from "@react-oauth/google";
import { loadingProgressBar } from "loading-progress-bar";
import { elementToReact } from "@web-companions/react-adapter";
import { FcGoogle } from "react-icons/fc";
import { MdError } from "react-icons/md";
import LoadingBar from "react-top-loading-bar";
import { useGoogleLogin } from "@react-oauth/google";
import { BeatLoader, PuffLoader } from "react-spinners";
function Signin() {
  const [loading, setLoading] = useState(false);
  const CLIENT_ID =
    "369046900295-j3ntqemlshdnmpqaa8mveo8qimd8vh6f.apps.googleusercontent.com";
  const [show, setShow] = useState("");
  const loadingBarRef = useRef(null);

  const myRef = useRef(null);

  useEffect(() => {});

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      setLoading(true);
      console.log(credentialResponse);
      const { access_token } = credentialResponse;
      axios
        .post(
          "https://api.xrcouture.com/auth/googleauth",
          { token: access_token },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.msg === "Email already exists") {
            setMsg(
              "Email id for this user is already exists, please login using gmail"
            );
          }
          console.log(res);
          localStorage.setItem("email", res.data.mail);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("brand", res.data.brand);
          localStorage.setItem("logintype", "google");
          setSignup(res.data.signUpCompleted);
          console.log(res.data.brand);
          if (res.data.role === "admin") {
            setTimeout(function () {
              window.location.replace("/admin/brands");
            }, 1000);
          }

          if (res.data.signUpCompleted === true) {
            if (res.data.role === "admin") {
              setTimeout(function () {
                window.location.replace("/admin/brands");
              }, 500);
            } else {
              setTimeout(function () {
                window.location.replace(`/brands/${res.data.brand}`);
              }, 500);
            }
          } else {
            setTimeout(function () {
              window.location.replace("/brand-data");
            }, 500);
          }
          setLoading(false);
        })
        .catch((e) =>
          setMsg("Oops!, something went wrong, please try again later")
        );
      setTimeout(() => {
        setMsg("");
      }, 3000);
    },
    onError: () => {
      console.log("Login Failed");
    },
    // onSuccess: codeResponse => console.log(codeResponse),
    // flow: 'auth-code',
  });

  const onSuccess = (res) => {
    console.log("Register successfulyyy", res);
    setShow(res.profileObj.imageUrl);
    window.location.replace("/");
    toast.success("Login Successful");
  };
  const onFailure = (res) => {
    console.log("Register failed", res);
  };

  const [signup, setSignup] = useState(false);

  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Please Enter Your Valid Email"),
    password: Yup.string().required("Please Enter your password"),
  });
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      console.log(loaded, total);
      let percent = Math.floor((loaded * 100) / total);
      if (percent < 100) {
        setUploadPercentage(percent);
      }
    },
  };
  const [msg, setMsg] = useState("");

  return (
    <div className="main-signup">
      <img
        src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Dashboard/Website_Contents/wrbl.png"
        alt=""
        className="logo"
      />
      <Row className="-hv cont-row">
        <Col className="col-md-12 cont -hv">
          <div className="box">
            <div className="box-header">
              <h1 className="box-header-text mb-5 mt-2">Sign in to Continue</h1>
              {msg && (
                <p className="d-flex align-items-center justify-content-center text-danger">
                  <MdError style={{ marginRight: "5px" }} /> {msg}
                </p>
              )}
              <hr style={{ color: "white !important" }}></hr>
            </div>
            <LoadingBar color="#f11946" ref={loadingBarRef} />
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={SigninSchema}
              onSubmit={(values) => {
                setLoading(true);
                // setInterval(() => {
                //   if (myRef.current?.generateProgress != null) {
                //     myRef.current.generateProgress.next();
                //   }
                // }, 1000);
                loadingBarRef.current.continuousStart();
                axios
                  .post("https://api.xrcouture.com/auth/signin", values, {
                    withCredentials: true,
                  })
                  .then((res) => {
                    console.log(res);
                    localStorage.setItem("email", res.data.mail);
                    localStorage.setItem("role", res.data.role);
                    localStorage.setItem("brand", res.data.brand);
                    localStorage.setItem("logintype", "email");
                    setSignup(res.data.signUpCompleted);
                    console.log(res.data.brand);
                    if (res.data.role === "admin") {
                      setTimeout(function () {
                        window.location.replace("/admin/brands");
                      }, 1000);
                    }

                    if (res.data.signUpCompleted === true) {
                      if (res.data.role === "admin") {
                        setTimeout(function () {
                          window.location.replace("/admin/brands");
                        }, 500);
                      } else {
                        setTimeout(function () {
                          window.location.replace(`/brands/${res.data.brand}`);
                        }, 500);
                      }
                    } else {
                      setTimeout(function () {
                        window.location.replace("/brand-data");
                      }, 500);
                    }
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);

                    console.log(err);
                    setMsg(`Oops, ${err.response.data.msg}`);
                    setTimeout(() => {
                      setMsg("");
                    }, 3000);
                  });
                loadingBarRef.current.complete();
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  {/* <Label for="email">Email</Label> */}
                  <Field type="email" name="email" placeholder=" Email" />
                  <ErrorMessage
                    name="email"
                    className="error"
                    component="div"
                  />
                  {/* <Label for="password">Password</Label> */}

                  <Field
                    type="password"
                    name="password"
                    placeholder=" Password"
                  />
                  <ErrorMessage
                    name="password"
                    className="error"
                    component="div"
                  />
                  <p className="forgot-pass">
                    <Link to="/forgot-password" className="forgot-pass">
                      {" "}
                      Forgot Password?
                    </Link>
                  </p>
                  <div className="button-submit">
                    <button type="submit" className="button" disabled={loading}>
                      {loading ? (
                        <BeatLoader size={10} color="white" />
                      ) : (
                        "Sign In"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <ToastContainer />

            <div className="google-signup mt-3">
              {/* <button onClick={login}><FcGoogle /></button> */}
              <GoogleLogin
                // type="icon"
                size="large"
                theme="filled_blue"
                text="signin_with"
                logo_alignment="left"
                width="230px"
                onSuccess={async (credentialResponse) => {
                  setLoading(true);
                  const { credential } = credentialResponse;
                  await axios
                    .post(
                      "https://api.xrcouture.com/auth/googleauth",
                      { token: credential },
                      { withCredentials: true }
                    )
                    .then((res) => {
                      if (res.data.msg === "Email already exists") {
                        setMsg(
                          "Email id for this user is already exists, please login using gmail"
                        );
                      }
                      setLoading(false);
                      console.log(res);
                      localStorage.setItem("email", res.data.mail);
                      localStorage.setItem("role", res.data.role);
                      localStorage.setItem("brand", res.data.brand);
                      localStorage.setItem("logintype", "google");
                      setSignup(res.data.signUpCompleted);
                      console.log(res.data.brand);
                      if (res.data.role === "admin") {
                        setTimeout(function () {
                          window.location.replace("/admin/brands");
                        }, 1000);
                      }

                      if (res.data.signUpCompleted === true) {
                        if (res.data.role === "admin") {
                          setTimeout(function () {
                            window.location.replace("/admin/brands");
                          }, 500);
                        } else {
                          setTimeout(function () {
                            window.location.replace(
                              `/brands/${res.data.brand}`
                            );
                          }, 500);
                        }
                      } else {
                        setTimeout(function () {
                          window.location.replace("/brand-data");
                        }, 500);
                      }
                    })
                    .catch((e) =>
                      setMsg(
                        "Oops!, something went wrong, please try again later"
                      )
                    );
                  setTimeout(() => {
                    setMsg("");
                  }, 3000);
                  setLoading(false);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
            <p className="signup mt-2">
              Join the waitlist? <Link to="/signup"> Sign Up</Link> here{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Signin;
