import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.modules = {
      toolbar: [
        ["bold", "italic", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
      ],
    };

    this.formats = [
      "bold",
      "italic",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
    ];
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  render() {
    console.log(this.state.text);
    return (
      <div>
        <ReactQuill
          value={this.state.text}
          modules={this.modules}
          formats={this.formats}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default Test;
