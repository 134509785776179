import * as React from "react";
import styled from "styled-components";
import { CellBase } from "./Cell";
import ExpanderButton from "./ExpanderButton";
const CellExpanderStyle = styled(CellBase)`
  white-space: nowrap;
  font-weight: 400;
  min-width: 48px;
  ${({ theme }) => theme.expanderCell.style};
`;
function CellExpander({
  row,
  expanded = false,
  expandableIcon,
  id,
  onToggled,
  disabled = false,
}) {
  return (
    <CellExpanderStyle onClick={(e) => e.stopPropagation()} noPadding>
      <ExpanderButton
        id={id}
        row={row}
        expanded={expanded}
        expandableIcon={expandableIcon}
        disabled={disabled}
        onToggled={onToggled}
      />
    </CellExpanderStyle>
  );
}
export default CellExpander;
