import React, { useEffect, useState } from "react";
import DataTable from "../../src/DataTable/DataTable";
import Swal from "sweetalert2";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from "@novu/notification-center";

import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";

import "./UserList.css";

const tableCustomStyles = {
  headCells: {
    style: {
      fontFamily: "Clash Display Medium",
      fontSize: "14px",
      // textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  },
  headRow: {
    style: {
      minHeight: "52px",
      borderBottomWidth: "1px",
      borderBottomColor: "rgba(137, 137, 137, 0.3)",
      borderBottomStyle: "solid",
      opacity: 0.7,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    denseStyle: {
      minHeight: "32px",
    },
  },
  pagination: {
    style: {
      fontFamily: "Clash Display Medium",
      fontSize: "14px",
      textAlign: "center",
      color: "white",
      paddingRight: "30px",
      paddingLeft: "30px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Clash Display Medium",
      justifyContent: "center",
      color: "white",
      backgroundColor: "#1E1D46",
      minHeight: "100px",
    },
  },
  rows: {
    style: {
      fontSize: "13px",
      fontWeight: 400,
      color: "white",
      minHeight: "60px !important",
      display: "flex",
      alignItems: "center !important",
      justifyContent: "center !important",
      borderBottomWidth: ".5px",
      borderBottomColor: "rgba(137, 137, 137, 0.3)",
      borderBottomStyle: "solid",
      paddingLeft: "10px",
      paddingRight: "10px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "gray",
      },
      "&:hover": {
        boxShadow: "0px 1px 10px 5px rgba(255,255,255,0.2) !important",
        cursor: "pointer",
      },
    },
    progress: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        backgroundColor: "#1E1D46",
      },
    },
  },
};

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
const CustomLoader = () => (
  <div style={{ padding: "24px" }}>
    <Spinner />
  </div>
);
export default function UserList(props) {
  const [update, setUpdate] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  let rowCount = 1;

  const waitlistCheckChange = async (brandName, whitelist) => {
    console.log(brandName, whitelist);

    await axios
      .post(
        "https://api.xrcouture.com/admin/whitelistuser",
        {
          brandName: brandName,
          whitelist: whitelist,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setUpdate((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        setUpdate((prev) => !prev);
      });
  };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.status;
    const b = rowB.status;
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
  const columns = [
    {
      name: "S/N",
      selector: "",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.1,
      cell: (row) => (
        <div
          className="text-center"
          style={{ fontFamily: "Clash Display Medium" }}
        >
          {rowCount++}
        </div>
      ),
    },
    {
      name: "Logo",
      selector: "thumbnail",
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.2,
      cell: (row) => (
        <>
          {row.logo[0] && row.logo[0] != "" ? (
            <img src={row.logo[0]} style={{ width: "30px", height: "30px" }} />
          ) : (
            <div
              style={{
                height: "30px",
                width: "30px",
                backgroundColor: "black",
                alignSelf: "center",
                justifySelf: "center",
                fontSize: "20px",
                fontWeight: "bolder",
                color: "gray",
                textAlign: "center",
              }}
            >
              {row.firstName
                .split(" ")
                .slice(0, 1)
                .map((n) => n[0])
                .join("")
                .toUpperCase()}
            </div>
          )}
        </>
      ),
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.3,
      sortable: true,
      cell: (row) => (
        <h6 onClick={() => setSelectedUserData(row)}>{row.firstName}</h6>
      ),
    },
    {
      name: "Brand Name",
      selector: (row) => row.brandName,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.4,
      sortable: true,
      cell: (row) => <h6>{row.brandName}</h6>,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.7,
      sortable: true,
      cell: (row) => <h6>{row.email}</h6>,
    },
    {
      name: "Waitlist",
      selector: (row) => row.whitelisted,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.3,
      sortable: true,
      cell: (row) => (
        <h6>{row.whitelisted === true ? "Has access" : "Waitlisted"}</h6>
      ),
    },
    {
      name: "Video Status",
      selector: (row) => row.whitelisted,
      style: {
        fontFamily: "Clash Display Medium",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      grow: 0.3,
      sortable: true,
      cell: (row) => (
        <h6>
          {row.videoAccessToken && row.videoAccessToken.length
            ? "Watched"
            : "Pending"}
        </h6>
      ),
    },
    {
      name: "",
      style: {
        // padding: 0, fontFamily: "Clash Display Medium", display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        // display: "block"
      },
      grow: 0.1,
      cell: (row) => (
        <>
          <Switch
            checked={row.whitelisted}
            onChange={() =>
              waitlistCheckChange(row.brandName, !row.whitelisted)
            }
            onColor="#9796F2"
            offColor="#c4c4c4"
            onHandleColor="#4E4BDE"
            offHandleColor="#ffffff"
            handleDiameter={22}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={40}
            className="react-switch"
            id={row.brandName}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    console.log(selectedUserData);
    setModalShow(true);
  }, [selectedUserData]);

  const [pending, setPending] = useState(true);
  const [data, setData] = React.useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const [assetList, setAssetList] = useState([]);
  const fetchAsset = async () => {
    // await axios.get("https://localhost:5000/admin/getUsers").then(res=>console.log(res)).catch(e => console.log(e))
    await axios
      .get("https://api.xrcouture.com/admin/getUsers")
      .then((res) => {
        setData(res.data.user);
      })
      .catch((e) => console.log(e));
    setAssetList(filteredItems);
    setPending(false);
  };

  useEffect(() => {
    setPending(true);
    fetchAsset();
  }, [update]);

  const filteredItems = data.filter(
    (item) =>
      item.brandName &&
      item.brandName.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    setAssetList(filteredItems);
  }, [data, filterText]);

  return (
    <div className="p-2 mb-4">
      <div className="row container-dashboard m-0">
        <div className="col-6">
          <h1 style={{ color: "white", fontFamily: "Clash Display Medium" }}>
            Users Overview
          </h1>
        </div>
        <div className="col-6 row d-flex align-items-center justify-content-between">
          <div className="col-10 h-100 m-0 p-0">
            <div class="input-icons">
              <i class="fa fa-search input-icon"></i>
              <input
                class="input-field manage-asset-input"
                type="text"
                placeholder="Search User"
                value={filterText}
                style={{ fontFamily: "Clash Display Medium" }}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1 p-1 d-flex justify-content-center">
            <div className="icons-dashboard">
              <NovuProvider
                subscriberId={"admin"}
                applicationIdentifier={"fFvjvHFwBD3F"}
              >
                <PopoverNotificationCenter>
                  {({ unseenCount, unseenBadgeColor }) => (
                    <>
                      {console.log(unseenCount)}
                      <NotificationBell unseenCount={unseenCount} />
                      <div class="number">{unseenCount}</div>
                    </>
                  )}
                </PopoverNotificationCenter>
              </NovuProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="tables mt-4" style={{ position: "relative" }}>
        <DataTable
          columns={columns}
          className="data-table"
          data={assetList}
          progressPending={pending}
          paginationPerPage={50}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginationResetDefaultPage={resetPaginationToggle}
          pagination
          progressComponent={<CustomLoader />}
          customStyles={tableCustomStyles}
        />
      </div>

      {selectedUserData && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontFamily: "Clash Display Bold" }}
            >
              User Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-white">
              <div className="d-flex flex-wrap">
                <div className="user-data-group">
                  <div className="user-data-field">First name</div>
                  <div className="user-data-value">
                    {selectedUserData.firstName &&
                    selectedUserData.firstName.length
                      ? selectedUserData.firstName
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Last name</div>
                  <div className="user-data-value">
                    {selectedUserData.lastName &&
                    selectedUserData.lastName.length
                      ? selectedUserData.lastName
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Brand name</div>
                  <div className="user-data-value">
                    {selectedUserData.brandName &&
                    selectedUserData.brandName.length
                      ? selectedUserData.brandName
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Phone</div>
                  <div className="user-data-value">
                    {selectedUserData.phoneNumber &&
                    selectedUserData.phoneNumber.length
                      ? selectedUserData.phoneNumber
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Email</div>
                  <div className="user-data-value">
                    {selectedUserData.email && selectedUserData.email.length
                      ? selectedUserData.email
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Type</div>
                  <div className="user-data-value">
                    {selectedUserData.type && selectedUserData.type.length
                      ? selectedUserData.type.charAt(0).toUpperCase() +
                        selectedUserData.type.slice(1)
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">User id</div>
                  <div className="user-data-value">
                    {selectedUserData.userId && selectedUserData.userId.length
                      ? selectedUserData.userId
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Country</div>
                  <div className="user-data-value">
                    {selectedUserData.country && selectedUserData.country.length
                      ? selectedUserData.country
                      : "-"}
                  </div>
                </div>
              </div>

              {selectedUserData.type !== "Individual" && (
                <>
                  <hr />

                  <div className="d-flex flex-wrap">
                    <div className="user-data-group">
                      <div className="user-data-field">Business name</div>
                      <div className="user-data-value">
                        {selectedUserData.businessName &&
                        selectedUserData.businessName.length
                          ? selectedUserData.businessName
                          : "-"}
                      </div>
                    </div>
                    <div className="user-data-group">
                      <div className="user-data-field">Company size</div>
                      <div className="user-data-value">
                        {selectedUserData.companySize &&
                        selectedUserData.companySize.length
                          ? selectedUserData.companySize
                          : "-"}
                      </div>
                    </div>
                    <div className="user-data-group">
                      <div className="user-data-field">Address line 1</div>
                      <div className="user-data-value">
                        {selectedUserData.addressLine1 &&
                        selectedUserData.addressLine1.length
                          ? selectedUserData.addressLine1
                          : "-"}
                      </div>
                    </div>
                    <div className="user-data-group">
                      <div className="user-data-field">Address line 2</div>
                      <div className="user-data-value">
                        {selectedUserData.addressLine2 &&
                        selectedUserData.addressLine2.length
                          ? selectedUserData.addressLine2
                          : "-"}
                      </div>
                    </div>
                    <div className="user-data-group">
                      <div className="user-data-field">City</div>
                      <div className="user-data-value">
                        {selectedUserData.city && selectedUserData.city.length
                          ? selectedUserData.city
                          : "-"}
                      </div>
                    </div>
                    <div className="user-data-group">
                      <div className="user-data-field">Country</div>
                      <div className="user-data-value">
                        {selectedUserData.country &&
                        selectedUserData.country.length
                          ? selectedUserData.country
                          : "-"}
                      </div>
                    </div>
                    <div className="user-data-group">
                      <div className="user-data-field">Pincode</div>
                      <div className="user-data-value">
                        {selectedUserData.pincode &&
                        selectedUserData.pincode.length
                          ? selectedUserData.pincode
                          : "-"}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <hr />

              <div className="d-flex flex-wrap">
                <div className="user-data-group">
                  <div className="user-data-field">Instagram</div>
                  <div className="user-data-value">
                    {selectedUserData.instagram &&
                    selectedUserData.instagram.length
                      ? selectedUserData.instagram
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Linkedin</div>
                  <div className="user-data-value">
                    {selectedUserData.linkedin &&
                    selectedUserData.linkedin.length
                      ? selectedUserData.linkedin
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Twitter</div>
                  <div className="user-data-value">
                    {selectedUserData.twitter && selectedUserData.twitter.length
                      ? selectedUserData.twitter
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Website</div>
                  <div className="user-data-value">
                    {selectedUserData.website && selectedUserData.website.length
                      ? selectedUserData.website
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Portfolio</div>
                  <div className="user-data-value">
                    {selectedUserData.portfolio &&
                    selectedUserData.portfolio.length
                      ? selectedUserData.portfolio
                      : "-"}
                  </div>
                </div>
              </div>

              <hr />

              <div className="d-flex flex-wrap">
                <div className="user-data-group">
                  <div className="user-data-field">Created at</div>
                  <div className="user-data-value">
                    {selectedUserData.createdAt &&
                    selectedUserData.createdAt.length
                      ? new Date(selectedUserData.createdAt).toString()
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Is verified</div>
                  <div className="user-data-value">
                    {selectedUserData.isVerified.toString() &&
                    selectedUserData.isVerified.toString().length
                      ? selectedUserData.isVerified
                        ? "True"
                        : "False"
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Verified at</div>
                  <div className="user-data-value">
                    {selectedUserData.verified &&
                    selectedUserData.verified.length
                      ? new Date(selectedUserData.verified).toString()
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Is signup completed</div>
                  <div className="user-data-value">
                    {selectedUserData.isSignUpCompleted.toString() &&
                    selectedUserData.isSignUpCompleted.toString().length
                      ? selectedUserData.isSignUpCompleted
                        ? "True"
                        : "False  "
                      : "-"}
                  </div>
                </div>
                <div className="user-data-group">
                  <div className="user-data-field">Waitlisted</div>
                  <div className="user-data-value">
                    {selectedUserData.whitelisted.toString() &&
                    selectedUserData.whitelisted.toString().length
                      ? selectedUserData.whitelisted
                        ? "False"
                        : "True"
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
